<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="680px"
        :close-on-click-modal="false"
        :close="handleClose">
        <el-scrollbar class="chat-list-items" ref="scrollbarRef" @scroll="handleScroll">
          <ul>
            <li v-for="(msgInfo, idx) in msglist" :key="msgInfo.id" :id="'chat-item-dig-'+idx">
              <chat-message-item 
                v-if="idx >= showMinIdx" 
                :mine="msgInfo.sendId == mine.id"
                :headImage="headImage(msgInfo)"
                :showName="showName(msgInfo)"
                :msgInfo="msgInfo"
                :lastmsgInfo="msglist[idx+1]" 
                :groupMembers="groupMembers"
                @onShowMenu ="onShowMenu"
                :online="headOnline(msgInfo)"
                >
              </chat-message-item>
            </li>
          </ul>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">{{$t('comm.cancel')}}</el-button>
            <el-button class="clearbt" @click="cancelAlltop">{{chattype != 'GROUP'||group.groupTop||isAdmin?$t('comm.clearAllTopMsg'):$t('comm.cancelAllTopMsg')}}</el-button>
        </span>
    </el-dialog>
</template>


<script>
  import ChatMessageItem from "./ChatMessageItem.vue";
  export default {
    name:"charmsg-dialog",
    components: {
      ChatMessageItem
    },
    data() {
      return {
        dialogVisible: false,
        msglist:[],
        showMinIdx :0
      }
    },
    props: {
      title: {
        type: String
      },
      groupMembers:{
				type:Array,
				default(){
          return []
        }
			},
			group:{
				type: Object,
				default(){
          return {}
        }
			},
			friend:{
				type: Object,
				default(){
          return {}
        }
			},
			chattype:{
				type: String,
			},
      isAdmin:{
        type:Boolean,
        default:false
      }
    },
    computed: {
			mine() {
				return this.$store.state.userStore.userInfo;
			},
		},
    methods: {
      open(messages){
        if(messages&&messages.length==0 ){
          this.handleClose()
          return
        }
        let msglist = []
        for(let i=0;i<messages.length;i++){
          let msg = messages[i]
          if(i==0||new Date(messages[i-1].sendTime).getDate()!=new Date(msg.sendTime).getDate()){
            msglist.push({
              sendTime: msg.sendTime,
              type: this.$enums.MESSAGE_TYPE.TIP_TIME,
            });
          }
          msglist.push(msg)
        }
        this.msglist = msglist
        this.showMinIdx = this.msglist.length >20?this.msglist.length-20:0
        this.dialogVisible =true
        this.$nextTick(() => {
          const scrollbar = this.$refs.scrollbarRef.$refs.wrap; // 获取滚动区域的DOM元素
          scrollbar.scrollTop = scrollbar.scrollHeight; // 设置滚动位置到底部
        });
      },
      handleClose() {
        this.msglist = []
        this.showMinIdx = 0
        this.dialogVisible = false
      },
      cancelAlltop(){
        this.$emit("cancelAlltop")
        this.handleClose()
      },
      onShowMenu(param){
        this.$emit("onShowMenu",param)
      },
      headImage(msgInfo) {
				if (this.chattype == 'GROUP') {
					let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
					return member ? member.headImage : "";
				} else {
					return msgInfo.selfSend ? this.mine.headImage : this.friend.headImage
				}
			},
			headOnline(msgInfo){
				if (this.chattype == 'GROUP') {
					let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
					return member ? member.online : false;
				} else {
					return this.friend.online || msgInfo.selfSend?true:false
				}
			},
			showName(msgInfo) {
				if (this.chattype == 'GROUP') {
					let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
					return member ? member.aliasName : "";
				} else {
					return msgInfo.selfSend ? this.mine.nickName : (this.friend.friendRemark || this.friend.nickName)
				}
			},
			onScrollToTop(){
        this.showMinIdx = this.showMinIdx > 10 ? this.showMinIdx - 10 : 0;
			},
			handleScroll(){
        const scrollbar = this.$refs.scrollbar;
        // 通过计算判断是否滚动到顶部
        if (scrollbar.wrap.scrollTop < 20 &&this.showMinIdx!=0) {
          this.onScrollToTop()
        }
			}
    }
  };
</script>


<style scoped lang="scss">
::v-deep{
  .el-dialog{
    border-radius: 12px;
  }
  .el-dialog__header{
    border-bottom:1px solid #D9D9D9;;
  }
  .el-dialog__body{
    padding: 10px 15px !important;
  }
}
.clearbt{
  background-color: #7678ED;
  color: white;
  &:hover{
    background-color: #6164e7;
  }
}
.chat-list-items{
  height: 500px;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
    }
  }
  ::v-deep{
    .online{
      width: 8px;
      height: 8px;
    }
    .Offline{
      width: 8px;
      height: 8px;
    }
  }
}
</style>
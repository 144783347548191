import { render, staticRenderFns } from "./ChatVoice.vue?vue&type=template&id=ce9717ba&scoped=true"
import script from "./ChatVoice.vue?vue&type=script&lang=js"
export * from "./ChatVoice.vue?vue&type=script&lang=js"
import style0 from "./ChatVoice.vue?vue&type=style&index=0&id=ce9717ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce9717ba",
  null
  
)

export default component.exports
<template>
	<div v-if="msgInfo.cite_id" @click="cilckyymsg" class="quote-message" :class="{'isselfmsg':!msgInfo.selfSend}" >
		<div  class="msg-info">
			<template v-if="istransmit">
				<div class="tranmsg-name">{{$t('comm.transmitmsg') }}</div>
				<div class="tranmsg-username">{{msgInfo.cite_name}}</div>
			</template>
			<template v-else>
				<div class="msg-name">{{msgInfo.cite_name}}</div>
				<div class="msg-content richtext" v-if="msgInfo.cite_type==$enums.MESSAGE_TYPE.TEXT">
					<span v-html="$emo.transform(msgInfo.cite_content)"></span>
				</div>
				<div class="chat-msg-image" v-if="msgInfo.cite_type==$enums.MESSAGE_TYPE.IMAGE">
					<el-image fit="cover" class="send-image" :src="cite_imageUrl+IMG_PEOXESS" :preview-src-list="[cite_imageUrl]" lazy></el-image>
				</div>
				<div class="chat-file-box" v-if="msgInfo.cite_type==$enums.MESSAGE_TYPE.FILE">
					<chat-msg-file :fileinfo="{url:cite_data.url,name:cite_data.name,size:cite_fileSize}"></chat-msg-file>
				</div>
				<div class="chat-img-text" v-if="msgInfo.cite_type==$enums.MESSAGE_TYPE.IMG_TEXT">
					<msg-imgtext :content="cite_data" :imgsize="50"></msg-imgtext>
				</div>
				<div class="chat-msg-audio" v-if="msgInfo.cite_type == $enums.MESSAGE_TYPE.AUDIO"  @click.stop="onPlayVoice()">
					<div class="chat-msg-voice">
						<span v-show="audio_state"  class="iconfont icon-zanting"></span>
						<span v-show="!audio_state" class="iconfont icon-kaishi"></span>
						<div class="voice-pause" :class="{'voice-playing':audio_state?true:false}"></div>
						<span>{{cite_data.duration||0}}''</span>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import {audio_PlayVoice} from "@/utils/im-utils.js";
	import ChatMsgFile from './ChatMsgFile.vue';
	import msgImgtext from  './msg-imgtext.vue';
	import {IMG_PEOXESS} from '@/api/oss.js'
	export default {
		name: "quote-message",
		data(){
			return{
				audio_state:0,//语言播放状态 
				IMG_PEOXESS:IMG_PEOXESS
			}
		},
		components: {
			ChatMsgFile,
			msgImgtext
		},
		props: {
			msgInfo: {
				type: Object,
				required: true
			}
		},
		methods:{
			onPlayVoice() {
				this.audio_state = 1
				audio_PlayVoice(this.cite_data.url,()=>{
					this.audio_state = 0
				})
			},
			cilckyymsg(){
				this.$emit("cilckyymsg",this.msgInfo)
			},
		},
		computed: {
			istransmit(){
				return this.msgInfo.cite_mes_type==1
			},
			cite_data() {
				return JSON.parse(this.msgInfo.cite_content||"{}")
			},
			cite_imageUrl(){
				return this.cite_data?.url
			},
			cite_fileSize() {
				let size = this.cite_data.size;
				if (size > 1024 * 1024) {
					return Math.round(size / 1024 / 1024) + "M";
				}
				if (size > 1024) {
					return Math.round(size / 1024) + "KB";
				}
				return size + "B";
			}
		}
	}
</script>

<style scoped lang="scss">
	.quote-message{
		background: white;
		border-radius: 12px; 
		display: flex;
		overflow: hidden;
		.bg{
			background-color: #1485EE;
			width: 8px;
		}
		.msg-info{
			flex: 1;
			display: flex;
			text-align: left;
			flex-direction: column;
			.msg-name{
				height: 16px;
				font-weight: 400;
				font-size: 12px;
				color: #7678ED;
				line-height: 14px;
				margin-top: 4px;
				margin-left: 8px;
				margin-right: 8px;
			}
			.tranmsg-name{
				font-weight: 400;
				font-size: 14px;
				color: #7678ED;
				line-height: 18px;
				margin-top: 4px;
				margin-left: 8px;
				margin-right: 8px;
			}
			.tranmsg-username{
				font-weight: 500;
				font-size: 12px;
				color: #7678ED;
				line-height: 18px;
				margin-left: 8px;
				margin-right: 16px;
				margin-bottom: 3px;
			}
			.msg-content,.chat-img-text{
				margin: 6px 8px;
				font-size: 12px;
				color: #333333;
				line-height:12px;
			}
			.chat-msg-image {
				display: flex;
				flex-direction: column;
				margin: 5px 10px;
				.send-image{
					flex: 1;
					min-width: 50px;
					min-height: 50px;
					max-width: 150px;
					max-height: 100px;
					cursor: pointer;
					box-sizing: border-box;
					border-radius: 5px;
				}
			}
			.chat-file-box {
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
				align-items: center;
				.chat-msg-file{
					background-color:rgba(51,51,51,0.1);
				}
				margin: 0px 10px 10px 10px;
			}
			.chat-msg-audio{
				.chat-msg-voice {
					cursor: pointer;
					display: flex;
					padding: 4px 35px 10px 8px;
					font-size: 14px;
					text-align: left;
					color: #666666;
					line-height: 18px;
					.iconfont{
						color:#666666;
						font-size: 18px;
					}
					span{
						margin: 0 4px;
					}
					.voice-pause{
						margin:0 10px 0 5px;
						width: 18px;
						aspect-ratio: 1;
						--c: no-repeat radial-gradient(circle closest-side,#666 90% 0);
						background: var(--c) 0%  50%,var(--c) 20% 50%,var(--c) 40% 50%,var(--c) 60% 50%,var(--c) 80% 50%,var(--c) 100% 50%;
						background-size: 10% 45%,10% 80%,10% 60%;
					}
					.voice-playing{
						animation: l1 1s infinite linear;
						@keyframes l1 {
							0%  {background-size: 10% 45%,10% 80%,10% 60%}
							25% {background-size: 10% 10%,10% 45%,10% 80%}
							50% {background-size: 10% 60%,10% 10%,10% 45%} 
							75% {background-size: 10% 80%,10% 60%,10% 10%}
							100%{background-size: 10% 40%,10% 80%,10% 60%}
						}
					}
				}
			}
		}
	}
	

</style>
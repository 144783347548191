<template>
	<el-dialog
		:visible.sync="visible"
		width="660px"
		:close-on-click-modal="true"
		:before-close="onClose"
		:show-close="false">
		  <div class="agm-container">
			  <div class="agm-l-box">
				  <div class="input-with-select">
					  <el-input  :placeholder="$t('comm.search')"  prefix-icon="el-icon-search" v-model="searchText"></el-input>
				  </div>
				  <div class="datepicker">
					  <el-date-picker
						  v-model="dates"
						  type="daterange"
						  unlink-panels
						  value-format="yyyy-MM-dd"
						  range-separator="-"
						  :start-placeholder="$t('comm.startdate')"
						  :end-placeholder="$t('comm.enddate')"
						  :editable="false"
						  :picker-options="pickerOptions">
					  </el-date-picker>
				  </div>
				  <el-scrollbar style="height:550px;">
					  <div v-for="(friend,index) in queryFriends" :key="friend.id">
						  <friend-item v-show="filterfriend(friend)"
							  @click.native="onSwitchCheck(friend)" :menu="false" :friend="friend" :index="index" :imgsize="40"
							  :active="false">
							  <div  class="friend-item-check"  :class ="{'item-active':friend.isCheck,'disabled':friend.disabled}">
								  <svg class="check-icon" viewBox="0 0 32 32"><path d="M1.952 18.080q-0.32-0.352-0.416-0.88t0.128-0.976l0.16-0.352q0.224-0.416 0.64-0.528t0.8 0.176l6.496 4.704q0.384 0.288 0.912 0.272t0.88-0.336l17.312-14.272q0.352-0.288 0.848-0.256t0.848 0.352l-0.416-0.416q0.32 0.352 0.32 0.816t-0.32 0.816l-18.656 18.912q-0.32 0.352-0.8 0.352t-0.8-0.32l-7.936-8.064z" fill="#FFF"></path></svg>
							  </div>
						  </friend-item>
					  </div>
				  </el-scrollbar>
			  </div>
			  <div class="agm-arrow"></div>
			  <div class="agm-r-box">
				  <div class="agm-select-tip"> 
					  <span>{{$t('comm.selectfriend')}}</span>
					  <span>{{$t('comm.checkNumfriend',[checkCount])}}</span>
				  </div>
				  <el-scrollbar style="height:550px;">
					  <div v-for="(friend,index) in queryFriends" :key="friend.id">
						  <friend-item v-if="friend.isCheck && !friend.disabled" :friend="friend" :index="index" :imgsize="40"
							  :active="false" @del="onRemoveFriend(friend,index)" :menu="false">
							  <i class="iconcleat el-icon-error" @click="friend.isCheck =false"></i>
						  </friend-item>
					  </div>
				  </el-scrollbar>
				  <span class="button-footer">
					  <el-button @click="onClose()">{{$t('comm.cancel')}}</el-button>
					  <el-button class="theme-bule" @click="onOk()">{{$t('comm.confirm')}}</el-button>
				  </span>
			  </div>
		  </div>
	  </el-dialog>
  </template>
  
  <script>
	  import FriendItem from '../friend/FriendItem.vue';
  
	  export default {
		  name: "addGroupMember",
		  components: {
			  FriendItem
		  },
		  data() {
			  return {
				  searchText: "",
				  friends: [],
				  queryFriends: [],
				  dates:"",
			  }
		  },
		  methods: {
			  //根据时间和名字过滤
			  filterfriend(friend){
				  return (friend.friendRemark || friend.nickName).includes(this.searchText)&&(!this.dates||new Date(friend.createdTime) >= new Date(this.dates[0]+' 00:00:00') && new Date(friend.createdTime) <= new Date(this.dates[1]+' 23:59:59'))
			  },
			  onClose() {
				  this.$emit("close");
			  },
			  onOk() {
				  let inviteVO = {
					  groupId: this.groupId,
					  friendIds: []
				  }
				  this.friends.forEach((f) => {
					  if (f.isCheck && !f.disabled) {
						  inviteVO.friendIds.push(f.id);
					  }
				  })
				  if (inviteVO.friendIds.length > 0) {
					  this.$http({
						  url: "im/groupMember/invite",
						  method: 'POST',
						  data: inviteVO
					  }).then(() => {
						  this.$message.success(this.$t('comm.invitesuc'));
						  this.$emit("reload");
						  this.$emit("close");
					  })
				  }
			  },
			  onRemoveFriend(friend, index) {
				  friend.isCheck = false;
			  },
			  onSwitchCheck(friend) {
				  if (!friend.disabled) {
					  friend.isCheck = !friend.isCheck
				  }
			  }
		  },
		  props: {
			  visible: {
				  type: Boolean
			  },
			  groupId: {
				  type: Number
			  },
			  members: {
				  type: Array
			  }
		  },
		  computed: {
			  checkCount() {
				  return this.friends.filter((f) => f.isCheck && !f.disabled).length;
			  },
			  pickerOptions(){
				return {
					shortcuts: [
					{
						text: this.$t('comm.now'),
						onClick(picker) {
							const start = new Date();
							const end = start;
							start.setTime(start.getTime());
							picker.$emit('pick', [start, end]);
						}
					},{
						text: this.$t('comm.yesterday'),
						onClick(picker) {
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
							const end = start;
							picker.$emit('pick', [start, end]);
						}
					},{
						text: this.$t('comm.LastWeek'),
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: this.$t('comm.Lastmonth'),
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: this.$t('comm.Lastthreemonths'),
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}],
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				}
			}
		  },
		  watch: {
			  visible: function(newData, oldData) {
				  if (newData) {
					  this.friends = [];
					  this.$store.state.friendStore.friends.forEach((f) => {
						  let friend = JSON.parse(JSON.stringify(f));
						  let m = this.members.filter((m) => !m.quit).find((m) => m.userId == f.id);
						  if (m) {
							  // 好友已经在群里
							  friend.disabled = true;
							  friend.isCheck = true;
						  } else {
							  friend.disabled = false;
							  friend.isCheck = false;
						  }
						  this.friends.push(friend);
					  })
					  this.dates = "";
						this.queryFriends = this.friends;
				  }
			  }
		  }
  
	  }
  </script>
  
  <style scoped lang="scss">
  ::v-deep{
	div .el-dialog__body{
	  padding: 0px !important;
	}
	.el-dialog__header{
	  display: none;
	}
	.el-dialog{
	  border-radius: 12px;
	}
	.el-dialog__header{
	  border-bottom:1px solid #D9D9D9;;
	}
  }
  .agm-container {
	  display: flex;
	  ::v-deep{
		  .friend-item{
			  padding: 4px 15px;
			  .friend-signature{
				  display: none;
			  }
		  }
		  .el-scrollbar__wrap{
			  margin: 0px !important;
		  }
	  }
	  .agm-l-box {
		  flex: 1;
		  width: 0px;
		  display: flex;
		  flex-direction: column;
		  padding: 10px 0px;
		  .input-with-select,.datepicker{
			  padding:6px 20px;
			  .el-date-editor{
				  width: 100%;
			  }
		  }
  
		  .left-scrollbar{
			  flex: 1;
		  }
		  .agm-friend-checkbox {
			  margin-right: 20px;
		  }
		  .friend-item-check{
			  width: 16px;
			  height: 16px;
			  border-radius: 8px;
			  border: 1px solid #D9D9D9;
			  margin-right: 10px;
			  .check-icon{
				height: 15px;
				width: 15px;
				display: none;
			  }
		  }
		  .item-active{
			  border:none;
			  background-color:#7678EC;
			  .check-icon{
				display: inline;
			  }
		  }
		  .disabled{
			  background-color:#BABBF6;
		  }
	  }
  
	  .agm-arrow {	
		  width: 1px;
		  background-color: #EEEEEE;
	  }
  
	  .agm-r-box {
		  flex: 1;
		  width: 0px;
		  padding: 10px 0;
		  display: flex;
		  flex-direction: column;
		  .agm-select-tip {
			  margin: 0 20px;
			  display: flex;
			  justify-content: space-between;
			  text-align: left;
			  height: 40px;
			  line-height: 40px;
			  font-size: 14px;
		  }
		  .button-footer{
			  padding: 12px 20px;
			  height: 56px;
			  text-align: right;
		  }
		  .iconcleat{
			  font-size: 14px;
			  color: #5B5C95;
		  }
	  }
  }
  </style>
  
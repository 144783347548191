<template>
	<div class="friend-item" :class="active ? 'active' : ''">
		<div class="friend-avatar">
			<head-image :size="imgsize" :name="friend.nickName" :url="friend.headImage" :online="friend.online" :showunline="true"></head-image>
		</div>
		<div class="friend-info">
			<div class="friend-name">{{friend.friendRemark||friend.nickName}}</div>
			<div class="friend-signature">{{friend.signature||$t('comm.signtis')}}</div>
		</div>
		<slot></slot>
	</div>
</template>

<script>
	import HeadImage from '../common/HeadImage.vue';

	export default {
		name: "frinedItem",
		components: {
			HeadImage,
		},
		data() {
			return {
				
			}
		},
		methods: {
			onSelectMenu(item) {
				this.$emit(item.key.toLowerCase(), this.msgInfo);
			}
		},
		computed:{
			friend(){
				return this.$store.state.friendStore.friends[this.index];
			},
			chatStore() {
				return this.$store.state.chatStore;
			},
			chat(){
				let tchart = this.chatStore.chats.find((chat)=>{
					return chat.targetId === this.friend.id;
				})
				return tchart || {}
			},
			showTime() {
				return this.chat.lastSendTime?this.$date.toTimeText(this.chat.lastSendTime, true):""
			},
		},
		props: {
			active: {
				type: Boolean
			},
			index: {
				type: Number
			},
			menu: {
				type: Boolean,
				default: true
			},
			imgsize:{
				type: Number,
				default: 48
			}
		}
	}
</script>

<style scope lang="scss">
	.friend-item {
		display: flex;
		margin-bottom: 1px;
		position: relative;
		padding:14px 8px;

		align-items: center;
		white-space: nowrap;
		cursor: pointer;
		border-radius: 8px;
		&:hover {
			background: rgba(246, 246, 252, 0.8);
		}

		&.active {
			background: #EEEEF8;
		}

		.friend-info {
			flex: 1;
			width: 0;
			display: flex;
			flex-direction: column;
			padding-left: 10px;
			text-align: left;
			position: relative;
			.friend-name{
				line-height: 16px;
				font-size: 16px;
				color: #333333;
			}
			.friend-signature{
				text-align: left;
				margin-top: 4px;
				width: 80%;
				height: 13px;
				line-height: 12px;
				font-size: 12px;
				color: #888888;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			.friend-online {
				.online {
					width: 15px;
					height: 15px;
					position: absolute;
					bottom:-5px
				}
				.imageleft{
					left:30px
				}
			}
		}
	}
</style>
<template>
	<div class="chat-voice">
		<div class="icon-buttom" @click="clickvoice" :class="{'running':state!='STOP'}">
			<span  class="iconfont icon-yuyin"></span>
		</div>
		<span v-if="state!='STOP'" class="voicetiem">
			{{parseInt(rc.duration)}}s
		</span>
	</div>
</template>

<script>
	import Recorder from 'js-audio-recorder';
	import {uploadFile} from '@/api/oss.js'
	export default {
		name: 'chatVoice',
		props: {
		},
		data() {
			return {
				rc:null,
				state: 'STOP', // STOP、RUNNING、COMPLETE
				stateTip: "未开始",
				starttime:null,
			}
		},
		mounted() {
			this.rc= new Recorder(),
			window.addEventListener('keydown', this.handleKeyDown);
			window.addEventListener('keyup', this.handleKeyUp);
		},
		destroyed() {
			window.removeEventListener('keydown', this.handleKeyDown);
			window.removeEventListener('keyup', this.handleKeyUp);
			this.onCompleteRecord()
			this.onDestroyRecord()
		},
		methods: {
			handleKeyDown(event) {
				if(event.code=='Space'){
					if(this.state =='STOP'){
						if(!this.starttime){
							this.onStartRecord()
						}else{
							clearTimeout(this.starttime);
						}
						this.starttime = setTimeout(() => {
							this.starttime =null
						}, 500);
					}
				}
				if(event.code=='Escape'){ 
					if(this.state =='RUNNING'){
						this.onCompleteRecord()
						this.onDestroyRecord()
					}
				}
			},
			handleKeyUp(event){
				if(event.code=='Space'){
					if(this.state !='STOP'){
						this.onCompleteRecord()
						this.onSendRecord()
					}
				}
			},
			clickvoice(){
				if(this.state =='STOP'){
					if(!this.starttime){
						this.onStartRecord()
					}else{
						clearTimeout(this.starttime);
					}
					this.starttime = setTimeout(() => {
						this.starttime =null
					}, 1000);
				}else{
					if(this.iscancel){
						this.iscancel = false
					}else{
						this.onCompleteRecord()
						this.onSendRecord()
					}
				}
			},
			
			onStartRecord() {
				if(this.state =='RUNNING'){
					return
				}
				this.rc.start().then((stream) => {
					this.state = 'RUNNING';
					this.stateTip = "正在录音...";
				}).catch(error => {
					this.rc = new Recorder();
					if(error?.name=='NotFoundError'){
						this.$message.error('录音设备未找到');
					}else{
						this.$message.error(error);
					}
				});
			},
			onCompleteRecord() {
				this.rc.pause();
				this.state = 'COMPLETE';
				this.stateTip = "已结束录音";
			},
			onDestroyRecord(){
				this.rc.destroy();
				this.rc = new Recorder();
				this.state = 'STOP';
				this.stateTip = '未开始';
			},

			onSendRecord() {
				if(this.state =='STOP'){
					return
				}
				if(this.rc.duration<1){
					this.$message.error(this.$t('comm.voiceshort'));
					this.onDestroyRecord();
					return 
				}
				let wav = this.rc.getWAVBlob();
				wav.name = '.wav'
				uploadFile(wav).then((res) => {
					if(res.res.status == 200){
						let data = {duration: parseInt(this.rc.duration),url: res.url}
						this.$emit("send", data);
						this.onDestroyRecord();
					}
				})
			}
		}

	}
</script>

<style scoped lang="scss">
	.chat-voice {
		display: flex;
		align-items: center;
		.icon-buttom{
			width: 40px;
			height: 40px;
			background: #FFF;
			border-radius: 50%;
			display: flex;
			.icon-yuyin{
				margin: auto;
				font-size: 20px;
				color: #7678ED;
			}
			&.running{
				background: #DBDCFF;
			}
		}
		.voicetiem{
			margin-left: 8px;
			color: #7678ED;
			font-size: 14px;
		}
	}
</style>

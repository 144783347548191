<template>
	<div class="chat-msg-item" :class="{'highlight':highlight,'multisel':ischeck&&isshowcheck&&msgtypecheck}" @click="checkboxclick()">
		<div v-if="isshowcheck&&msgtypecheck"  class="chat-item-check"  :class ="{'item-active':ischeck}">
			<svg v-if="ischeck" class="check-icon" viewBox="0 0 32 32"><path d="M1.952 18.080q-0.32-0.352-0.416-0.88t0.128-0.976l0.16-0.352q0.224-0.416 0.64-0.528t0.8 0.176l6.496 4.704q0.384 0.288 0.912 0.272t0.88-0.336l17.312-14.272q0.352-0.288 0.848-0.256t0.848 0.352l-0.416-0.416q0.32 0.352 0.32 0.816t-0.32 0.816l-18.656 18.912q-0.32 0.352-0.8 0.352t-0.8-0.32l-7.936-8.064z" fill="#FFF"></path></svg>
		</div>
		<div class="chat-msg-tip" v-if=" msgInfo.type == $enums.MESSAGE_TYPE.TIP_TEXT">
			{{tiptext(msgInfo.content)}}
		</div>
		<div class="chat-msg-tip" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TIP_TIME">
			{{showdate(msgInfo.sendTime) }}
		</div>
		<div class="chat-msg-tip" v-if="msgInfo.type == $enums.MESSAGE_TYPE.RECALL ||msgInfo.type == $enums.MESSAGE_TYPE.MSGTIP">
			{{msgInfo.content}}
		</div>
		<div class="chat-msg-topmsg" v-if="msgInfo.type==$enums.MESSAGE_TYPE.MSG_TOP" @click="clicktopmsg(msgInfo.msgid)"
			v-html="showsendidName(msgInfo.sendId)+ $t('comm.topped')+':'+$emo.transform(msgInfo.content)">
		</div>
		<div class="chat-msg-livenss" v-if="msgInfo.type==$enums.MESSAGE_TYPE.GROUP_LIVENESS">
			<div class="livensstop">
				<img class="activation-img" src="@/assets/im-icons/activation.svg"/>
				<span>{{$t('comm.activelevelph',[data.day])}}</span>
			</div>
			<div class="line-row"></div>
			<div class="livenactive" v-for="(row,index) in data.list" :key="index">
				<img v-if="row.rank==1" class="activation-img" src="@/assets/im-icons/goldmedal.svg" ></img>
				<img v-else-if="row.rank==2" class="activation-img" src="@/assets/im-icons/silvermedal.svg" ></img>
				<img v-else-if="row.rank==3" class="activation-img" src="@/assets/im-icons/bronzemedal.svg" ></img>
				<div v-else class="activation-img">{{row.rank}}.</div>
				<img class="activation-img headImage" :src="row.headImage" ></img>
				<div class="liven-username">{{row.userName}}</div>
				<div>{{row.total}}{{$t('comm.tiao')}}</div>
			</div>
			<div v-if="data.listMe" class="livenactive mine-livenactive" >
				<img v-if="data.listMe.rank==1" class="activation-img" src="@/assets/im-icons/goldmedal.svg" ></img>
				<img v-else-if="data.listMe.rank==2" class="activation-img" src="@/assets/im-icons/silvermedal.svg" ></img>
				<img v-else-if="data.listMe.rank==3" class="activation-img" src="@/assets/im-icons/bronzemedal.svg" ></img>
				<div v-else class="activation-img">{{data.listMe.rank}}.</div>
				<img class="activation-img headImage" :src="data.listMe.headImage" ></img>
				<div class="liven-username">{{data.listMe.userName}}</div>
				<div>{{data.listMe.total}}{{$t('comm.tiao')}}</div>
			</div>
		</div>
		<div class="chat-msg-normal" v-show="msgInfo.type >= 0 && msgInfo.type < 10" :class="{ 'chat-msg-mine': mine&&!ischannel }">
			<div class="head-image-box" @contextmenu.prevent="showimageMenu($event)">
				<head-image v-if="!islastmsgslef" :name="showName" :canFriend="canFriend" :size="48" :url="headImage"  :id="msgInfo.sendId" :showunline="!ischannel&&isAdmin" :online="online&&isAdmin"></head-image>
			</div>
			<div class="chat-msg-content">
				<!-- <div v-show="mode == 1 && msgInfo.groupId && !mine" class="chat-msg-top">
					<span>{{ showName }}</span>
				</div>
				<div v-show="mode == 2" class="chat-msg-top">
					<span>{{ showName }}</span>
					<span>{{ $date.toTimeText(msgInfo.sendTime) }}</span>
				</div> -->
				<div class="chat-msg-bottom" >
					<div ref="chatMsgBox"  class="chatMsgBox"  @contextmenu.prevent="showMenu($event)">
						<!-- 图文 -->
						<div class="chat-msg-img" v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMG_TEXT">
							<quote-message class="chat-msg-quote" :msgInfo="msgInfo" @cilckyymsg="cilckyymsg"></quote-message>
							<msg-imgtext  :content="data" :align="mine&&!ischannel?'right':'left'" ></msg-imgtext>
						</div>
						<!-- 文本 -->
						<div class="chat-msg-text" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TEXT">
							<div class="user-name" v-show="mode == 1 && msgInfo.groupId && !mine">
								<span >{{ showName }}</span>
								<span class="msgleader" v-if="MsgLeader">{{MsgLeader}}</span>
							</div>
							<quote-message class="chat-msg-quote" :msgInfo="msgInfo" @cilckyymsg="cilckyymsg"></quote-message>
							<span class="chat-msg-showtext" v-html="(msgInfo.receipt?'【'+$t('comm.Receipt')+'】':'')+$emo.transform(msgInfo.content)"></span>
						</div>
						<!-- 语言消息 -->
						<div class="chat-msg-audio" v-if="msgInfo.type == $enums.MESSAGE_TYPE.AUDIO"  @click="onPlayVoice()">
							<div class="chat-msg-voice">
								<span v-show="audio_state" style="font-size: 20px;" :style="{color:mine?'#FFF':'#666666'}" class="iconfont icon-zanting"></span>
								<span v-show="!audio_state" style="font-size: 20px;" :style="{color:mine?'#FFF':'#666666'}" class="iconfont icon-kaishi"></span>
								<div class="voice-pause" :class="{'voice-playing':audio_state?true:false}"></div>
								<span>{{data.duration||0}}''</span>
							</div>
							<span v-if="!mine&&!msgInfo.audio_played"  class="audio-played"></span>
						</div>
						<!-- 单张图片 -->
						<div class="chat-msg-image" v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMAGE">
							<div class="img-load-box" v-loading="loading" :element-loading-text="$t('comm.uploading')" element-loading-background="rgba(0, 0, 0, 0.4)">
								<quote-message style="margin-bottom: 5px;" :msgInfo="msgInfo" @cilckyymsg="cilckyymsg"></quote-message>
								<el-image fit="cover" class="send-image" :src="data.url?data.url+IMG_PEOXESS:''" :preview-src-list="[data.url]" lazy></el-image>
							</div>
							<span :title="$t('comm.sendfail')" v-show="loadFail" @click="onSendFail" class="send-fail el-icon-warning"></span>
						</div>
						<!-- 文件 -->
						<div class="chat-file-msg" v-if="msgInfo.type == $enums.MESSAGE_TYPE.FILE">
							<div class="chat-file-box" v-loading="loading">
								<quote-message :msgInfo="msgInfo" @cilckyymsg="cilckyymsg"></quote-message>
								<chat-msg-file :fileinfo="{url:data.url,name:data.name,size:fileSize}"></chat-msg-file>
							</div>
							<span :title="$t('comm.sendfail')" v-show="loadFail" @click="onSendFail" class="send-fail el-icon-warning"></span>
						</div>
						<!-- 礼盒 -->
						<div class="chat-bx-msg" :class="{'openbox':msgInfo.state&&msgInfo.state!='opening'?true:false}" v-if="msgInfo.type == $enums.MESSAGE_TYPE.RED_WRAP">
							<div class="box"  @click="opengift">
								<div class="gift-text">{{data.title}}</div>
								<div v-if="msgInfo.state=='open_suc'" class="info-text">{{$t('comm.robed')}}</div>
								<div v-else-if="msgInfo.state=='open_not'" class="info-text">{{$t('comm.robNomore')}}</div>
								<div v-else-if="msgInfo.state=='open_expire'" class="info-text">{{$t('comm.Expired')}}</div>
								<div v-else class="gift-sendname">{{$t('comm.blindsentout',[showsendidName(msgInfo.sendId)])}}</div>
								<div class="gift-type" v-if="data.type=='1'">{{showsendidName(data.userIds[0])}}{{$t('comm.ExclusiveGiftBox')}}</div>
								<div class="gift-type" v-else>{{$t('comm.DiamondGiftBox')}}</div>
							</div>
							<img v-if="msgInfo.state&&msgInfo.state!='opening'" class="touchicon" style="transform:scale(1.15) translateX(-5px);" src="@/assets/im-icons/gift-box-open.svg"  @click="opengift" loading="lazy"/>
							<img v-else class="touchicon" src="@/assets/im-icons/gift-box-all.svg"  @click="opengift" loading="lazy"/>
						</div>
						<!-- 底部 已读/时间 -->
						<div v-if="msgInfo.type!=$enums.MESSAGE_TYPE.RED_WRAP" class="msg-bottom-info" :class="{'msg-bottom-info-self':mine&&!ischannel,'isonlyimg-bg':isonlyimg}">
							<div v-if="msgInfo.receipt&&(msgInfo.readedCount||msgInfo.readedCount==0)" class="msg-receipt">
								<span >{{$t('comm.personread',msgInfo.readedCount)}}</span>
							</div>
							<div class="msg-time">{{showtime(msgInfo.sendTime)}}</div>
							<svg v-if="mine&&!msgInfo.groupId" class="msg-readed" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<g stroke="none" stroke-width="1"  fill="none" fill-rule="evenodd" >
									<path :fill="msgInfo.status==$enums.MESSAGE_STATUS.READED?'#ACFF65':'#FFFFFF'" d="M10,4.4934958 L9.50749927,4 L4.67043677,9.26513455 L1.81956555,7.47080807 L1,8.29203211 L4.67043677,12 L10,4.4934958 Z M15,4.49349573 L14.4739304,4 L9.30750558,9.2651349 L7.97719267,8.51903403 L7,9.80849471 L9.30750558,12 L15,4.49349573 Z" id="形状" ></path>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <chat-group-readed ref="chatGroupReadedBox" :msgInfo="msgInfo" :groupMembers="groupMembers"></chat-group-readed> -->
	</div>
</template>

<script>
	import HeadImage from "../common/HeadImage.vue";
	import quoteMessage from './quote-message.vue';
	import msgImgtext from  './msg-imgtext.vue';
	import ChatMsgFile from './ChatMsgFile.vue';
	import {audio_PlayVoice} from "@/utils/im-utils.js";
	import {IMG_PEOXESS} from '@/api/oss.js'
	// import ChatGroupReaded from './ChatGroupReaded.vue';
	export default {
		name: "messageItem",
		components: {
			HeadImage,
			quoteMessage,
			ChatMsgFile,
			msgImgtext
		},
		props: {
			mode: {
				type: Number,
				default: 1
			},
			mine: {
				type: Boolean,
				required: true
			},
			headImage: {
				type: String,
				required: true
			},
			showName: {
				type: String,
				required: true
			},
			msgInfo: {
				type: Object,
				required: true
			},
			lastmsgInfo:{
				type: Object
			},
			groupMembers: {
				type: Array
			},
			online:{
				type: Boolean
			},
			ischannel:{//是否频道，频道不显示在线状态和名称
				type: Boolean
			},
			isshowcheck:{//是否显示选中按钮
				type: Boolean
			},
			defcheck:{//是否默认选中
				type: Boolean,
				default:false
			},
			highlight:{//亮高行
				type: Boolean,
			},
			canFriend:{//允许用户点击头像添加添加好友
				type: Boolean,
				default:true
			},
			isAdmin:{
				type: Boolean,
				default:false
			}
		},
		data() {
			return {
				ischeck:false,
				audio_state:0,//语言播放状态 
				IMG_PEOXESS:IMG_PEOXESS
			}

		},
		watch:{
			isshowcheck(newval,oldval){ 
				if(newval&&!oldval){
					this.ischeck = this.defcheck
				}
			}	
		},
		methods: {
			tiptext(content){
				try{
					let contenobj = JSON.parse(content)
					return this.$t('tiptext.'+contenobj.key,contenobj.body)
				}catch{
					return content
				}
			},
			opengift(){
				this.$emit("robAction",this.msgInfo)
			},
			cilckyymsg(msgInfo){
				this.$emit("cilckyymsg",msgInfo)
			},
			showdate(ts){
			  const date = new Date(ts);
			   const year = date.getFullYear();
			   const month = (date.getMonth() + 1).toString().padStart(2, '0');
			   const day = date.getDate().toString().padStart(2, '0');
			   // const second = date.getSeconds().toString().padStart(2, '0');
			   return `${year}-${month}-${day}`;
			},
			checkboxclick(){
				if(this.isshowcheck&&this.msgtypecheck){
					this.ischeck = !this.ischeck
					this.$emit("checkclick",{check:this.ischeck,msgInfo:this.msgInfo})
				}
			},
			clicktopmsg(msgid){
				this.$emit("clicktopmsg",msgid)
			},
			showtime(ts){
			   const date = new Date(ts);
			   const hour = date.getHours().toString().padStart(2, '0');
			   const minute = date.getMinutes().toString().padStart(2, '0');
			   // const second = date.getSeconds().toString().padStart(2, '0');
			   return `${hour}:${minute}`;
			},
			onSendFail() {
				this.$message.error(this.$t('comm.onSendFail'))
			},
			onPlayVoice() {
				if(!this.msgInfo.audio_played){
					this.$store.commit("updateMessage", {...this.msgInfo,audio_played: true})
				}
				this.audio_state = 1
				audio_PlayVoice(this.data.url,()=>{
					this.audio_state = 0
				})
			},
			showMenu(e) {
				this.$emit("onShowMenu",{e:e,msgInfo:this.msgInfo})
			},
			showimageMenu(e){
				this.$emit("showimageMenu",{e:e,msgInfo:this.msgInfo})
			},
			onShowReadedBox() {
				// let rect = this.$refs.chatMsgBox.getBoundingClientRect();
				// this.$refs.chatGroupReadedBox.open(rect);
			},
			clickhref(hurl,filename){
				fetch(hurl).then(res=>res.blob().then(blob=>{
					var a = document.createElement('a');
					var url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = filename;
					a.click();
					window.URL.revokeObjectURL(url);
				}))
			},
			showsendidName(sendId) {
				if(this.msgInfo.groupId){
					let member = this.groupMembers.find((m) => m.userId == sendId);
					return member ? member.aliasName : "";
				}else{
					return this.showName
				}
			},
		},
		computed: {
			MsgLeader(){
				if(this.msgInfo.groupId){
					let member = this.groupMembers.find((m) => m.userId == this.msgInfo.sendId );
					if(member){
						return member.memberType==1&&this.$t('comm.GroupLeader')||member.memberType==2&&this.$t('comm.administrators')
					}
					return null;
				}
				return null
			},
			msgtypecheck(){
				 return this.msgInfo.type!=this.$enums.MESSAGE_TYPE.RECALL
						&&this.msgInfo.type != this.$enums.MESSAGE_TYPE.TIP_TEXT
						&&this.msgInfo.type != this.$enums.MESSAGE_TYPE.TIP_TIME
						&&this.msgInfo.type != this.$enums.MESSAGE_TYPE.MSGTIP
						&&this.msgInfo.type != this.$enums.MESSAGE_TYPE.MSG_TOP
						&&this.msgInfo.type != this.$enums.MESSAGE_TYPE.GROUP_LIVENESS
						&&this.msgInfo.type != this.$enums.MESSAGE_TYPE.RED_WRAP
			},
			isonlyimg(){
				if(this.msgInfo.type == this.$enums.MESSAGE_TYPE.IMG_TEXT){
					return this.data.findIndex(item=>item.type == 0)<0
				}else if(this.msgInfo.type == this.$enums.MESSAGE_TYPE.IMAGE){
					return true
				}
				return false
			},
			islastmsgslef(){
				if(this.lastmsgInfo&&this.msgInfo.sendId&&(this.lastmsgInfo.type<10)){
					return this.lastmsgInfo.sendId == this.msgInfo.sendId 
				}
				return false
			},
			loading() {
				return this.msgInfo.loadStatus && this.msgInfo.loadStatus === "loading";
			},
			loadFail() {
				return this.msgInfo.loadStatus && this.msgInfo.loadStatus === "fail";
			},
			data() {
				return JSON.parse(this.msgInfo.content||'{}')
			},
			fileSize() {
				let size = this.data.size;
				if (size > 1024 * 1024) {
					return Math.round(size / 1024 / 1024) + "M";
				}
				if (size > 1024) {
					return Math.round(size / 1024) + "KB";
				}
				return size + "B";
			},
			isRealtime() {
				return this.msgInfo.type == this.$enums.MESSAGE_TYPE.RT_VOICE ||
					this.msgInfo.type == this.$enums.MESSAGE_TYPE.RT_VIDEO
			},
		}
	}
</script>

<style scoped lang="scss">
	.multisel{
		background-color: #f0f0f0;
	}
	.highlight{
		background-color: #dcdcdc;
	}
	.chat-msg-item {
		display: flex;
		padding: 0px 16px;
		.chat-item-check{
			margin-right: 14px;
			border-radius: 50%;
			border: 1px solid #D9D9D9;
			width: 16px;
			height: 16px;
			display: flex;
			align-self: center;
			justify-content: center;
			&.item-active{
				background-color: #7678ED;
			}
			.check-icon{
				border:none;
				width: 13px;
				height: 13px;
			}
		}
		.chat-msg-tip {
			flex: 1;
			line-height: 32px;
			font-weight: 400;
			font-size: 14px;
			color: #666666;
		}
		.chat-msg-livenss{
			margin: 5px auto;
			width: 256px;
			background: #FFFFFF;
			box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			font-size: 12px;
			color: #626569;
			.livensstop{
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #626569;
				height: 32px;
			}
			.line-row{
				height: 1px;
				background-color: #EEEEEE;
				margin: 0 16px;
			}
			.livenactive{
				height: 32px;
				padding: 0 16px;
				display: flex;
				align-items: center;
			}
			.mine-livenactive{
				box-sizing: content-box;
				padding-top:8px ;
			}
			.headImage{
				width: 16px;
				height: 16px;
				border-radius: 6px;
				margin: 4px;
				background-color: #f5f5f5;
			}
			.liven-username{
				margin-right: auto;
			}
			.activation-img{
				text-align: center;
				width: 16px;
				height: 16px;
			}
		}
		.chat-msg-topmsg {
			flex: 1;
			height: 32px;
			margin: 6px auto;
			line-height: 20px;
			font-weight: 400;
			font-size: 14px;
			color: #666666;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 65%;
			width: auto;
			display: inline-block;
		}
		.chat-msg-normal {
			--friend-msg-bgcolor:#EEEEF8;
			flex: 1;
			position: relative;
			padding-left: 50px;
			min-height: 50px;
			padding-top: 2px;
			padding-bottom: 2px;
			.head-image-box {
				position: absolute;
				width: 48px;
				height: 48px;
				left: 0;
				bottom: 5px;
			}
			.chat-msg-content {
				text-align: left;
				.send-fail {
					color: #e60c0c;
					font-size: 25px;
					cursor: pointer;
					margin: 0 20px;
				}

				.chat-msg-top {
					display: flex;
					flex-wrap: nowrap;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					height:20px;
					span {
						margin-right: 12px;
					}
				}

				.chat-msg-bottom {
					display: flex;
					padding-right: 150px;
					padding-left: 8px;
					.chat-msg-icon-left{
						min-width: 10px;
						width: 10px;
						margin-top: 11px;
						transform: scaleX(-1) translateX(-1px)
					}
					.chat-msg-icon-right{
						min-width: 10px;
						width: 10px;
						margin-top: 11px;
						transform:translateX(-1px)
					}
					.chatMsgBox{
						min-width: 110px;
						position: relative;
						.msg-bottom-info{
							position: absolute;
							height: 14px;
							width: 100%;
							bottom: 4px;
							display: flex;
							line-height: 16px;
							font-size: 10px;
							font-weight: 400;
							color: #999999;;
							padding-right: 8px;
							padding-left: 8px;
							box-sizing: border-box;
							.msg-receipt{
								text-align: left;
								margin-right: auto;
								padding: 0 10px;
							}
							.msg-readed{
								margin-left: 2px;
								height: 14px;
								width: 20px;
							}
							.showtrans{
								cursor: pointer;
								font-size: 14px;
							}
							.msg-time{
								margin-left: auto;
								height: 15px;
								text-align: center;
								width: 35px;
								font-size:12px;
							}
						}
						.isonlyimg-bg{
							bottom: 6px;
							.msg-receipt,.msg-readed,.msg-time{
								background-color: #33323294;
								border-radius: 7px;
								color: #FFF;
							}
						}
						.msg-bottom-info.msg-bottom-info-self{
							color: #fff;
						}
						.msg-imgtext{
							color: #333333;
							--bg-color:var(--friend-msg-bgcolor)
						}
						.chat-msg-text {
							min-height: 16px;
							position: relative;
							background-color: var(--friend-msg-bgcolor);
							border-radius: 10px;
							display: block;
							text-align: left;
							white-space: pre-wrap;
							word-break: break-all;
							font-weight: 400;
							font-size: 14px;
							color: #333333;
							line-height: 20px;
							padding: 8px 12px 14px 12px;
							min-width: 80px;
							.user-name{
								min-height: 20px;
								font-size: 14px;
								color: #7678ED;
								line-height: 20px;
								display: flex;
								align-items: center;
								:first-child{
									display: inline-block;
									max-width: 200px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
								}
								.msgleader{
									background: #67C23A;
									border-radius: 4px;
									font-size: 12px;
									color: #FFFFFF;
									line-height: 16px;
									padding: 0 8px;
									margin: 0 3px
								}
							}
							.chat-msg-quote{
								margin-bottom: 2px;
							}
							.chat-msg-showtext ::v-deep{
								img{
									height: 25px !important;
									width: 25px !important;
								}
							}
							.trans_content{
								padding-top: 6px;
								border-top: 1px solid #d3d3d3;
								font-size: 12px;
							}
							.trans_loading{
								color:#6d6b6b;
							}
						}
		
						.chat-msg-audio{
							position: relative;
							.audio-played{
							    width: 8px;
								height: 8px;
								border-radius: 50%;
								background-color: #FF4D4F;
								position: absolute;
								top: 20px;
								right: -30px;
							}
							.chat-msg-voice {
								cursor: pointer;
								display: flex;
								padding: 14px 35px 14px 8px;
								border-radius: 10px;
								background-color: var(--friend-msg-bgcolor);
								font-size: 14px;
								text-align: left;
								color: #333333;
								line-height: 20px;
								span{
									margin: 0 4px;
								}
								.voice-pause{
									margin:0 10px 0 5px;
									width: 20px;
									aspect-ratio: 1;
									--c: no-repeat radial-gradient(circle closest-side,#666 90% 0);
									background: var(--c) 0%  50%,var(--c) 20% 50%,var(--c) 40% 50%,var(--c) 60% 50%,var(--c) 80% 50%,var(--c) 100% 50%;
									background-size: 10% 45%,10% 80%,10% 60%;
								}
								.voice-playing{
									animation: l1 1s infinite linear;
									@keyframes l1 {
										0%  {background-size: 10% 45%,10% 80%,10% 60%}
										25% {background-size: 10% 10%,10% 45%,10% 80%}
										50% {background-size: 10% 60%,10% 10%,10% 45%} 
										75% {background-size: 10% 80%,10% 60%,10% 10%}
										100%{background-size: 10% 40%,10% 80%,10% 60%}
									}
								}
							}
						}
						.chat-msg-image {
							display: flex;
							flex-wrap: nowrap;
							flex-direction: row;
							align-items: center;
							.img-load-box{
								// background-color: var(--friend-msg-bgcolor);
								// padding: 14px 14px 20px 14px;
								border-radius: 10px;
							}
							.send-image {
								border-radius: 8px;
								display: flex;
								flex-direction: column;
								min-width: 100px;
							
								max-width: 250px;
								height: 150px;
								cursor: pointer;
								::v-deep .el-image__error{
									flex: 1;
								}
							}

						}
						.chat-bx-msg{
							display: flex;
							align-items: center;
							padding: 5px 14px;
							flex-direction: row-reverse;
							&.openbox{
								opacity: 0.7;
							}
							.touchicon{
								height: 96px;
								width: 86px;
								z-index: 1;
							}
							.box{
								padding-right: 24px;
								padding-left: 50px;
								min-width: 200px;
								height: 72px;
								background: linear-gradient( 90deg, #D18BD3 0%, #F7D5E4 100%);
								border-radius: 8px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								font-size: 12px;
								color: #FFF;
								line-height: 20px;
								transform: translateX(-45px);
								.gift-text{
									max-width: 160px;
									font-size: 14px;
									margin-top: 6px;
									line-height: 22px;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
								}
							}
						}	
						.chat-file-msg {
							display: flex;
							flex-wrap: nowrap;
							flex-direction: row;
							align-items: center;
							.chat-file-box {
								display: flex;
								flex-wrap: nowrap;
								align-items: center;
								min-width: 256px;
								background-color: var(--friend-msg-bgcolor);
								border-radius: 10px;
								text-align: left;
								padding: 14px 14px 20px 5px;
								.chat-msg-file{
									height: 46px;
									background-color: transparent
								}
								.chat-file-icon {
									font-size: 50px;
									color: #d42e07;
								}
							}

							.send-fail {
								color: #e60c0c;
								font-size: 25px;
								cursor: pointer;
								margin: 0 20px;
							}

						}

						.chat-realtime {
							display: flex;
							align-items: center;

							.iconfont {
								cursor: pointer;
								font-size: 22px;
								padding-right: 8px;
							}
						}

						.chat-msg-status {
							display: block;

							.chat-readed {
								font-size: 12px;
								color: #888;
								font-weight: 600;
							}

							.chat-unread {
								font-size: 12px;
								color: #f23c0f;
								font-weight: 600;
							}
						}

						.chat-receipt {
							font-size: 13px;
							color: blue;
							cursor: pointer;

							.icon-ok {
								font-size: 20px;
								color: #329432;
							}
						}
				
					}
					
				}
			}


			&.chat-msg-mine {
				--mine-msg-bgcolor:#7678ED;
				text-align: right;
				padding-left: 0;
				padding-right: 50px;

				.head-image-box {
					left: auto;
					right: 0;
				}

				.chat-msg-content {
					text-align: right;

					.chat-msg-top {
						flex-direction: row-reverse;

						span {
							margin-left: 12px;
							margin-right: 0;
						}
					}

					.chat-msg-bottom {
						padding-left: 180px;
						padding-right: 8px;
						justify-content: end;
						.msg-imgtext{
							color: #fff;
							--bg-color:var(--mine-msg-bgcolor)
						}
						.chat-bx-msg{
							flex-direction: row;
							.box{
								padding-left: 20px;
								padding-right: 60px;
								transform: translateX(45px);
								text-align: left;
							}
						
						}
						.chat-msg-text {
							background-color: var(--mine-msg-bgcolor);
							color: #fff;
							vertical-align: top;
							&:after {
								left: auto;
								right: -10px;
								border-top-color: var(--mine-msg-bgcolor);
							}
							.trans_loading{
								color: #eee;
							}
						}
						.chat-msg-audio{
							display: flex;
							flex-direction: row-reverse;
							.chat-msg-voice {
								color: #FFF;
								background-color: var(--mine-msg-bgcolor);
								.voice-pause{
									--c: no-repeat radial-gradient(circle closest-side,#FFF 90% 0);
								}
							}
						}

						.chat-msg-image {
							flex-direction: row-reverse;
							// .img-load-box{
							// 	background-color: var(--mine-msg-bgcolor);
							// }
						}

						.chat-file-msg {
							flex-direction: row-reverse;
							.chat-file-box {
								background-color: var(--mine-msg-bgcolor);
								.chat-msg-file{
									::v-deep{
										.file-name{
											color: #FFF;
										}
										.file-size{
											color:#d9d9d9;
										}
									}
								}
							}
						}

						.chat-realtime {
							flex-direction: row-reverse;

							.iconfont {
								transform: rotateY(180deg);
							}
						}
					}
				}
			}

		}
	}
</style>
<template>
  <el-dialog
      :visible.sync="visible"
      width="640px"
      :close-on-click-modal="true"
	  :before-close="onClose"
      :show-close="false">
		<div class="agm-container">
			<div class="agm-l-box">
				<div class="input-with-select">
					<el-input :placeholder="$t('comm.searchfriends')"  prefix-icon="el-icon-search" v-model="searchText"></el-input>
				</div>
				<div class="select-tip"> 
					<span>{{$t('comm.groupuser')}}({{members.length}})</span>
				</div>
				<el-scrollbar style="height:550px;">
					<div v-for="gm in members" :key="gm.userId">
						<div class="member-item" v-show="filterMembers(gm)"  @click="onSwitchCheck(gm)">
							<head-image :size="40" :url="gm.headImage"></head-image>
							<div class="gm-name">
								<span>{{gm.aliasName}}</span>
								<span class="user-id">({{gm.userId}})</span>
							</div>
							<span v-show="gm.memberType==1" class="gm-admin">{{$t('comm.GroupLeader')}}</span>
							<span v-show="gm.memberType==2" class="gm-admin">{{$t('comm.administrators')}}</span>
							<div  class="gm-item-check"  :class ="{'item-active':gm.isCheck,'disabled':gm.disabled}">
								<svg class="check-icon" viewBox="0 0 32 32"><path d="M1.952 18.080q-0.32-0.352-0.416-0.88t0.128-0.976l0.16-0.352q0.224-0.416 0.64-0.528t0.8 0.176l6.496 4.704q0.384 0.288 0.912 0.272t0.88-0.336l17.312-14.272q0.352-0.288 0.848-0.256t0.848 0.352l-0.416-0.416q0.32 0.352 0.32 0.816t-0.32 0.816l-18.656 18.912q-0.32 0.352-0.8 0.352t-0.8-0.32l-7.936-8.064z" fill="#FFF"></path></svg>
							</div>
						</div>
					</div>	
				</el-scrollbar>
			</div>
			<div class="agm-arrow"></div>
			<div class="agm-r-box">
				<div class="agm-select-tip"> 
					<span>{{$t('comm.removemembers')}}</span>
					<span>{{$t('comm.membersselected',[checkCount])}}</span>
				</div>
				<el-scrollbar style="height:550px;">
					<div v-for="gm in members" :key="gm.userId">
						<div class="member-item" v-if="gm.isCheck">
							<head-image :size="40" :url="gm.headImage"></head-image>
							<div class="gm-name">
								<span>{{gm.aliasName}}</span>
								<span class="user-id">({{gm.userId}})</span>
							</div>
							<i class="iconcleat el-icon-error" @click="gm.isCheck =false"></i>
						</div>
					</div>
				</el-scrollbar>
				<span class="button-footer">
					<el-button @click="onClose()">{{$t('comm.cancel')}}</el-button>
					<el-button class="theme-red" :disabled="!existcheck" @click="confirm()">{{$t('comm.remove')}}</el-button>
				</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import HeadImage from '../common/HeadImage.vue'
	export default {
		name: "DeleteMember",
		components: {
			HeadImage
		},
		data() {
			return {
				searchText: "",
				members:[],
				dates:"",
			}
		},
		methods: {
			onSwitchCheck(gm) {
				if (!gm.disabled) {
					gm.isCheck = !gm.isCheck
				}
			},
			//根据时间
			filterMembers(gm){
				return gm.aliasName.includes(this.searchText)||(gm.userId+'').includes(this.searchText)
			},
			onClose() {
				this.$emit("close");
			},
			confirm(){
				this.$confirm(this.$t('comm.removememberstips'), this.$t('comm.tips'), {
					confirmButtonText: this.$t('comm.confirm'),
					cancelButtonText: this.$t('comm.cancel'),
					type: 'warning'
				}).then(()=>{
					this.removemembers()
				})
			},
			removemembers(){
				let checks =this.members.filter((m)=>{
					return m.isCheck
				})
				let seluserid = checks.filter((m)=>{
					return m.type!=1
				}).map((m)=>{
					return m.id
				})
				if(seluserid.length>0){
					let ids = seluserid.join(',')
					const loading = this.$loading({
						lock: true,
						text: '',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					this.$http({
						url: '/im/groupMember/1/'+ids,
						method: 'put'
					}).then(() => {
						this.$message.success(this.$t('comm.delsuccess'));
						this.$emit("reload");
						this.$emit("close");
					}).finally(()=>{
						loading.close();
					})
				}else{
					this.$emit("close");
				}
			},
		},
		props: {
			visible: {
				type: Boolean
			},
			groupId: {
				type: Number
			},
			groupMembers: {
				type: Array
			},
			isAdmintype:{//1群主 2管理员
				type: Number
			}
		},
		computed: {
			existcheck(){
				return this.members.some((f)=>f.isCheck)
			},
			checkCount() {
				return this.members.filter((f) => f.isCheck).length;
			},
			mine() {
				return this.$store.state.userStore.userInfo;
			},
		},
		watch: {
			visible: function(newData, oldData) {
				if (newData) {
					this.members = [];
					this.searchText =""
					this.groupMembers.forEach((g) => {
						if(!g.quit){
							let gm = JSON.parse(JSON.stringify(g));
							gm.isCheck = false;//勾选标识
							if(this.isAdmintype==1&&gm.userId == this.mine.id){
								gm.disabled = true;//不能移除标识
							}else if(this.isAdmintype==2&&(gm.memberType==1||gm.memberType==2)){
								gm.disabled = true;
							}
							this.members.push(gm)
						}
					})
				}
			}
		}

	}
</script>

<style scoped lang="scss">
::v-deep{
  div .el-dialog__body{
    padding: 0px !important;
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog{
    border-radius: 12px;
  }
  .el-dialog__header{
    border-bottom:1px solid #D9D9D9;;
  }
}
.agm-container {
	display: flex;
	::v-deep{
		.friend-item{
			padding: 4px 15px;
			.friend-signature{
				display: none;
			}
		}
		.el-scrollbar__wrap{
			margin: 0px !important;
		}
	}
	.agm-l-box {
		flex: 1;
		width: 0px;
		display: flex;
		flex-direction: column;
		padding: 10px 0px;
		.select-tip{
			line-height: 40px;
			text-align: left;
			margin-left: 16px;
		}
		.input-with-select{
			padding:6px 20px;
		}
		.gm-item-check{
			width: 16px;
            height: 16px;
			border-radius: 8px;
			border: 1px solid #D9D9D9;
			margin-left: 4px;
			margin-right: 16px;
			.check-icon{
              height: 15px;
              width: 15px;
              display: none;
            }
		}
		.item-active{
            border:none;
            background-color:#7678EC;
            .check-icon{
              display: inline;
            }
        }
		.disabled{
			visibility: hidden;
		}
	}
	.member-item{
		display: flex;
		align-items: center;
		cursor: pointer;
		.head-image{
			margin: 4px 8px 4px 16px;
		}
		.gm-name{
			flex: 1;
			text-align: left;
			width: 0;
			font-size: 14px;
			color: #333333;
			display: flex;
			align-items: center;
			span:first-child {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			.user-id{
				min-width: 70px;
				margin-left: 4px;
				font-size: 12px;
				color: #626569;
			}
		}
		.gm-admin{
			font-size: 12px;
			margin-left: 5px;
			padding: 2px 10px;
			background-color: #67C23A;
			color: #FFFF;
			border-radius: 4px;
		}
		&:hover{
			background-color: #f7f7f7;
		}
	}
	.agm-arrow {	
		width: 1px;
		background-color: #EEEEEE;
	}

	.agm-r-box {
		flex: 1;
		width: 0px;
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		.agm-select-tip {
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			text-align: left;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
		}
		.button-footer{
			padding: 12px 20px;
			height: 56px;
			text-align: right;
			.theme-red{
				background: #F56C6C;
				color: #FFF;
				border: none;
			}
			.is-disabled{
				background: #e7bfbf;
			}
		}
		.iconcleat{
			margin-left: auto;
			margin-right: 17px;
			font-size: 14px;
			color: #6C757D;
		}
	}
}
</style>

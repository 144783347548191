<template>
    <el-drawer class="chat-sign-in" size="360px" :visible.sync="drawer"  :withHeader="false"  direction="rtl" :modal="false">
        <div class="sign-in-conent">
            <div class="title">
                <span>{{$t('comm.groupsignin')}}</span>
                <span @click="close" class="iconfont icon-guanbi"></span>
            </div>
            <div class="sign-mine">
                <img  :src="mine.headImage" class="avatar">
                <span class="sign-month">{{$t('comm.signinday',[nowMothCount])}}</span>
                <span class="sign-lx">{{$t('comm.jxsigninday',[sing_info.continuousSign])}}</span>
            </div>
            <div v-loading="qd_loading"  class="sign-date">
                <zdycalendar  @monthchange="queryqiandao">
                    <template v-slot:default="{day}">
                        <!-- <div v-for="day in week" :key="day.day" class="item-day" @click="clickday(day)" :class="{'selectday':isselectdate(day.date),'today':day.current,'activeMonth':!day.isbeforeMonth&&!day.islastMonth }"> -->
                            <div class="day-item" v-if="!day.isbeforeMonth&&!day.islastMonth " :class="{'isbefore':isbeforeday(day.date)&&!day.current||day.current&&isqd_today}">
                                <template v-if ="isqiandao(day.date)">
                                    <span>{{$t('comm.signedin')||day.day}}</span>
                                    <div  class="iconfont icon-duigou"></div>
                                </template>
                                <template v-else >
                                    <span>{{day.day}}</span>
                                    <div class="iconfont icon-rili"></div>
                                </template> 
                            
                            </div>
                            <div class="day-black" v-else></div>
                        <!-- </div> -->
                    </template>
                </zdycalendar>
            </div>
            <div class="sign-num">{{$t('comm.peoplesigned',[SignCouture])}}</div>
            <el-scrollbar class="sign-list">
                <div class="user-info" v-for="(item,index) in userList" :key="index">
                    <span>{{index+1}}.</span>
                    <img :src="item.avatar" class="user-headimg">
                    <span>{{item.userName}}</span>
                    <span class="sign-time">{{$date.formatDate(item.createdTime*1000,'hh:mm:ss')}}</span>
                </div>
            </el-scrollbar>
        </div>
    </el-drawer>
</template>


<script>
import zdycalendar from "../common/zdy-calendar.vue";
export default {
    name:"ChatSignIn",
    components: {
        zdycalendar
    },
    data(){
        return {
            drawer:false,
            qd_monthInfo:{},//查询月份签到信息
            qd_loading:false,
            userList:[],
            SignCouture:0,
            sing_info:{
                propNumber: 0,
                continuousSign: 0
            }
        }
    },
    props:["group"],
    computed: {
        mine() {
			return this.$store.state.userStore.userInfo;
		},
        nowMothCount(){
            let date = new Date()
            let yearMonth = date.getFullYear()+'-'+(date.getMonth()+1)
            return  this.qd_monthInfo[yearMonth]?this.qd_monthInfo[yearMonth].length:0
        },
        //今日是否签到
		isqd_today(){
            let nowdate= new Date()
            return  this.isqiandao(nowdate)
		},
    },
    methods:{
        open(){
            this.drawer = true
            this.qd_loading=false
            this.qd_monthInfo = {}
            this.userList =[]
            this.sing_info.propNumber=0
            this.sing_info.continuousSign=0
            this.SignCouture = 0
            // 查询月签到
            this.queryqiandao(new Date())
             //连续签到和钻石
            this.querylxSign()
            //查询连续签到列表
            this.querSigndlist()
        },
        close(){
            this.drawer = false
        },
        isbeforeday(date){
            return date.getTime() < Date.now()
        },
        isqiandao(time){
			let yearMonth = time.getFullYear()+'-'+(time.getMonth()+1)
			if(!this.qd_monthInfo[yearMonth]){
				return false
			}
			let index = this.qd_monthInfo[yearMonth].findIndex((minfo)=>{
				return this.issameday(new Date(minfo.signTime*1000),time)
			})
			return  index>=0
		},
        issameday(date1,date2){
			let datenow = date1.getFullYear()+'-'+(date1.getMonth()+1)+'-'+date1.getDate()
			let date = date2.getFullYear()+'-'+(date2.getMonth()+1)+'-'+date2.getDate()
			return datenow == date
		},
        queryqiandao(date){
            let yearMonth = date.getFullYear()+'-'+(date.getMonth()+1)
			if(this.qd_monthInfo[yearMonth]){
				return
			}
            this.qd_loading= true
			this.$http({
				url: '/im/groupMember/listGroupSign',
				method: 'POST',
				data: {
					signTime:Math.floor(date.getTime()/1000),
                    groupId:this.group.id,
					userId:this.mine.id,
					signType:0,
				}
			}).then((res)=>{
                this.$set(this.qd_monthInfo,yearMonth,res)
			}).finally(()=>{
				this.qd_loading= false
			})
		},
        querylxSign(){
            this.$http({
				url: '/im/groupMember/qryConSignAcc',
				method: 'POST',
				data: {
                    groupId:this.group.id,
					userId:this.mine.id,
				}
			}).then((res)=>{
				this.sing_info.propNumber =res.propNumber
                this.sing_info.continuousSign =res.continuousSign
			})
        },
        querSigndlist(){
            this.$http({
                url: '/im/groupMember/listGroupSignDetails',
                method: 'POST',
                data: {
                    groupId:this.group.id,
                    signTime:Math.floor(Date.now()/1000),
                }
            }).then((res)=>{
                this.SignCouture = res.list.length
                this.userList = res.list
            })
        },
    }
}
</script>


<style scoped lang="scss">
.chat-sign-in{
    position: absolute;
}
::v-deep{
    .el-drawer{
        box-shadow: -1px 0px 12px 0px rgba(0,0,0,0.1);
    }
}
.sign-in-conent{
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    .title{
        padding: 0 16px;
        line-height: 64px;
        display: flex;
        color: #333333;
        .icon-guanbi{
            color: #4E5969;
            margin-left: auto;
            font-size: 16px;
        } 
       
    }
    .sign-mine{
        padding: 8px 16px;
        background-color: #EEEEF8;
        display: flex;
        align-items: center;
        color: #626569;
        .avatar{
            width: 48px;
            height: 48px;
            border-radius: 8px;
        }
        .sign-month{
            margin-left: 12px;
            font-size: 16px;
        }
        .sign-lx{
            margin-left: auto;
            font-size: 12px;
        }
    }
    .sign-list{
        flex: 1;
        margin-bottom: 10px;
        font-size: 14px;
        color: #333333;
        padding: 0px 16px;
        .user-info{
            display: flex;
            align-items: center;
            .user-headimg{
                width: 40px;
                height: 40px;
                border-radius: 16px;
                background-color: #F5F5F5;
                margin:8px;
            }
            .sign-time{
                margin-left: auto;
                color: #999999;
            }
        }
    }
    .sign-date{
        .day-black{
            width: 32px;
            height: 40px;
            margin: 4px;
        }
        .day-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 32px;
            height: 40px;
            background-color: #F9B03F;
            border-radius: 4px;
            margin: 4px;
            font-size: 10px;
            color: #FFFFFF;
            line-height: 13px;
            .iconfont{
                width: 30px;
                flex: 1;
                margin-bottom: 1px;
                background-color: #FFFFFF;
                color: #F9B03F;
                font-size: 16px;
                line-height: 26px;
                border-radius: 0 0  4px 4px;
            }
            &.isbefore{
                opacity: 0.6;
            }
        }
    }
    .sign-num{
        padding: 0 16px;
        font-size: 14px;
        color: #333333;
        line-height: 40px;
    }
}
</style>
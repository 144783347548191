<template>
	<div class="msg-imgtext" :style="{'align-items':align=='right'?'end': 'start'}">
		<div class="msg-img" :style="{'width':boxwidth+'px','height':boxheight+'px'}">
			<div class="msg-img-item" v-for="(obj,index) in imglist" :key="index" :style="setItemstyle(index)"  @click.stop>
				<el-image class="send-image"  :src="obj.url+IMG_PEOXESS" :preview-src-list="[obj.url]" lazy fit="cover"></el-image>
			</div>   
		</div> 
		<div v-if="textmsg.trim()" class="text-content" :style="{'min-width':boxwidth+'px'}">
			<span v-html="textmsg"></span>
		</div>
		
	</div>
</template>

<script>
	import {IMG_PEOXESS} from '@/api/oss.js'
	export default {
		name:"msg-imgtext",
		data(){
			return{
				IMG_PEOXESS:IMG_PEOXESS
			}
		},
		props:{
			content:{
				type:Array
			},
			imgsize:{
				type:Number,
				default:120
			},
			align:{
				type:String,
				default:'left'
			}
		},
		computed:{
			imglist(){
				return  this.content.filter(item=>{
					return  item.type == 1
				})
			},
			textmsg(){
				let textobj =  this.content.find(item=>item.type == 0)
				if(textobj){
					return this.$emo.transform(textobj.text)||''
				}
				return ""
			},
			boxwidth(){
				if(this.imglist.length==1){
					return this.imgsize*2
				}
				return  this.imgsize*3
			},
			boxheight(){
				let imglength = this.imglist.length
				if(imglength==1){
					return  this.imgsize/4*6
				}else if(imglength==2){
					return  this.imgsize*9/8
				}
				let baseheight =  this.imgsize/4*3
				return Math.ceil(imglength/3)*baseheight
			}
		},
		methods:{
			setItemstyle(index){
				let baseheight =  this.imgsize/4*3
				let style ={height:baseheight+'px'}
				let imglength = this.imglist.length
				if(imglength==1){
					style.width = this.imgsize*2+'px'
					style.height  =  this.imgsize*3/2+'px'
				}else if(imglength==2){
					style.width = this.imgsize*3/2+'px'
					style.height  =  style.width/4*3+'px'
				}else if(imglength==4){
					style.width = this.imgsize*3/2+'px'
				}else if((imglength==5||imglength==8)&&index<2){
					style.width=this.imgsize*3/2+'px'
				}else if(imglength==7&&index<4){
					style.width=this.imgsize*3/2+'px'
				}else{
					style.width=this.imgsize+'px'
				}
				return style
			}
		},
	}
</script>

<style scoped lang="scss">
.msg-imgtext{
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.msg-img{

		border-radius: 10px;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		.msg-img-item{
			box-sizing: border-box;
			padding: 0.5px;
			height: 100%;
		}
	}
	.send-image{
		height: 100%;
		width: 100%;
	}
	.text-content{
		box-sizing: border-box;
		margin-top: 1px;
		padding: 14px 28px 14px 8px;
		min-height: 16px;
		text-align: left;
		line-height: 16px;
		border-radius: 10px;
		background-color: var(--bg-color);
	}
}

</style>
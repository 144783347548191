<template>
	<div class="right-menu-mask" @click.stop="close()" @contextmenu.prevent.stop="close()">
		<div class="right-menu" :style="rightmenuStyle">
			<el-menu text-color="#7678ED">
				<el-menu-item v-for="(item) in items" :key="item.key" :title="item.name"
					@click.native.stop="onSelectMenu(item)" :style="item.color?'color:'+item.color:''">
					<span :class="item.icon"></span>
					<span>{{item.name}}</span>
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>

<script>
	export default {
		name: "rightMenu",
		data() {
			return {
				pos_x : 0,
				pos_y : 0,
			}
		},
		props: {
			pos: {
				type: Object
			},
			items: {
				type: Array
			}
		},
		computed:{
			rightmenuStyle(){
				let w =  document.documentElement.clientWidth;
				let h =  document.documentElement.clientHeight; 
				let style = {'left':(w > this.pos.x + 150 ? this.pos.x:w - 150)+'px'}
				if(h > this.pos.y + this.items.length * 35){
					style.top = this.pos.y+'px'
				}else{
					style.bottom = h  - this.pos.y+'px'
				}
				return  style
			}
		},
		methods: {
			close() {
				this.$emit("close");
			},
			onSelectMenu(item) {
				this.close();
				this.$emit("select", item);
			}
		}
	}
</script>

<style scoped lang="scss">
	.right-menu-mask {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
	}
	.right-menu {
		position: fixed;
		box-shadow: 0px 0px 10px #ccc;
		border-radius: 8px;
		overflow: hidden;
		.el-menu {
			.el-menu-item {
				height: 40px;
				width: 160px;
				line-height: 40px;
			    text-align: left;	
				color: #7678ED;
				padding: 0 10px !important;
				display: flex;
				justify-content: space-between;
				flex-direction: row-reverse;
				align-items: center;
				span {
					font-weight: 400;
					font-size: 14px;
				}
				:first-child{
					font-size: 15px;
				}
				&:hover{
					background: #7678ED;
					color: #fff;
					border-radius: 2px;
				}
			}
		}
	}
</style>
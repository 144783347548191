<template>
	<div class="group-member">
		<head-image :id="member.userId" :name="member.aliasName" 
			:url="member.headImage" :size="50"  :canFriend="canFriend"
			:online="member.online" :showunline ="true">
			<div  v-if="showDel" @click.stop="onDelete()" class="btn-kick el-icon-error"></div>
		</head-image>
		<div class="member-name">{{member.aliasName}}</div>
		<div v-if="isjy" :title="$t('comm.usernospeak')" class="icon el-icon-remove"></div>
	</div>
</template>

<script>
	import HeadImage from "../common/HeadImage.vue";
	export default{
		name: "groupMember",
		components:{HeadImage},
		data(){
			return {};
		},
		props:{
			member:{
				type: Object,
				required: true
			},
			showDel:{
				type: Boolean,
				default: false
			},
			canFriend:{//允许用户信息添加好友
				type: Boolean,
				default:true
			}
		},
		computed: {
			isjy(){
				let activagroup = this.$store.state.groupStore.activeGroup
				if(activagroup){
					let inx =activagroup.groupUserMute.findIndex(uid=>uid==this.member.userId)
					if(inx>=0){
						return true
					}
				}
				return false;
			}
		},
		methods:{
			onDelete(){
				this.$emit("del",this.member);
			}
		}
	}
</script>

<style lang="scss">
	.group-member{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50px;
		.icon{
			font-size: 15px;
			top: 0px;
			right: 0px;
			color: #E04C45;
			position: absolute;
			
		}
		.member-name {
			font-size: 12px;
			text-align: center;
			width: 100%;
			height: 20px;
			line-height: 20px;
			white-space: nowrap;
			text-overflow:ellipsis; 
			overflow:hidden
		}
		
		.btn-kick {
			display: none;
			position: absolute;
			right: -8px;
			top: -8px;
			color: darkred;
			font-size: 20px;
			cursor: pointer;
		}
		
		&:hover .btn-kick{
			display: block;
			color: #ce1818;
		}
	}
</style>

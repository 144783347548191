<template>
    <div class="chat-msg-file">
        <img v-if="getFileIconName=='icon-unknow'" src="@/assets/im-icons/icon-unknow.svg" loading="lazy"/>	
        <img v-else-if="getFileIconName=='icon-exce'" src="@/assets/im-icons/icon-exce.svg" loading="lazy"/>	
        <img v-else-if="getFileIconName=='icon-pdf'" src="@/assets/im-icons/icon-pdf.svg" loading="lazy"/>
        <img v-else-if="getFileIconName=='icon-ppt'" src="@/assets/im-icons/icon-ppt.svg" loading="lazy"/>
        <img v-else-if="getFileIconName=='icon-word'" src="@/assets/im-icons/icon-word.svg" loading="lazy"/>
        <img v-else-if="getFileIconName=='icon-png'" src="@/assets/im-icons/icon-png.svg" loading="lazy"/>
        <img v-else-if="getFileIconName=='icon-mp3'" src="@/assets/im-icons/icon-mp3.svg" loading="lazy"/>
        <img v-else-if="getFileIconName=='icon-mp4'" src="@/assets/im-icons/icon-mp4.svg" loading="lazy"/>
        <img v-else-if="getFileIconName=='icon-txt'" src="@/assets/im-icons/icon-txt.svg" loading="lazy"/>

        <div class="file-info">
            <el-link class="file-name" :underline="false" target="_blank" type="primary" 
            @click="clickhref(fileinfo.url,fileinfo.name)">{{ fileinfo.name }}</el-link>
            <div class="file-size">{{fileSize}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ChatMsgFile",
    data(){
        return {}
    },
    props:["fileinfo"],//{name,url,size}
    methods:{
        clickhref(hurl,filename){
            fetch(hurl).then(res=>res.blob().then(blob=>{
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            }))
        },
    },
    computed: {
        getFileIconName(){
            let iname="icon-unknow"
            if(this.fileinfo.name){
                let ext = this.fileinfo.name.split('.').pop();
                ext = ext.toLowerCase()
                if(ext =="excel" || ext =="xlsx"){
                    iname="icon-exce"
                }else if(ext =="pdf"){
                    iname="icon-pdf"
                }else if(ext =="ppt"||ext =="pptx"){
                    iname="icon-ppt"
                }else if(ext =="docx"||ext =="doc"){
                    iname="icon-word"
                }else if(ext =="jpg"||ext =="png"||ext =="gif"){
                    iname="icon-png"
                }else if(ext =="mp3"||ext =="m4a"||ext =="wma"||ext =="mid"||ext =="wv"||ext =="wav"){
                    iname="icon-mp3"
                }else if(ext =="mp4"||ext =="avi"||ext =="wmv"||ext =="mov"||ext =="rm"||ext =="ram"||ext =="swf"||ext =="flv"){
                    iname="icon-mp4"
                }else if(ext =="txt"){
                    iname="icon-txt"
                }
            }
            return iname
        },
        fileSize() {
            let size = this.fileinfo.size||0;
            if (size > 1024 * 1024) {
                return Math.round(size / 1024 / 1024) + "M";
            }
            if (size > 1024) {
                return Math.round(size / 1024) + "KB";
            }
            return size + "B";
        }
    }
}
</script>


<style scoped lang="scss">
.chat-msg-file{
    height: 56px;
    width: 100%;
    background: #F5F5F5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    img{
        width: 40px;
        height: 40px;
        margin-left: 8px;
    }
    .file-info{
        flex: 1;
        .file-name{
            font-size: 14px;
            color: #333333;
            line-height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .file-size{
            margin-top: 4px;
            font-size: 12px;
            color: #999999;
            line-height: 16px;
        }
    }
}

</style>
<template>
	<div class="chat-item" :class="active ? 'active' : ''" @contextmenu.prevent="showRightMenu($event)">
		<div class="chat-left">
			<head-image 
				:url="chat.headImage"
				:name="chat.showName" :size="48" 
				:id="chat.type=='PRIVATE'?chat.targetId:0"
				:online="isonline"
				:showunline="chat.type=='PRIVATE'"
				></head-image>
	
		</div>
		<div class="chat-right">
			<div class="chat-name">
				<div class="chat-name-text">{{chat.showName}}</div>
				<div class="chat-time-text">{{showTime}}</div>
			</div>
			<div class="chat-content">
				<div class="chat-at-text">{{atText}}</div>
				<div class="chat-send-name" v-show="sendNickName">{{sendNickName+':&nbsp;'}}</div>
				<div class="chat-content-text" v-html="$emo.transform(chat.lastContent||'')"></div>
				<div v-show="chat.unreadCount>0" class="unread-text">{{showunreadCount}}</div>
				<span v-show="chat.disturbing"  class="iconfont icon-miandarao"></span>
			</div>
		</div>
		<right-menu v-show="rightMenu.show" :pos="rightMenu.pos" :items="rightMenu.items" @close="rightMenu.show=false"
			@select="onSelectMenu"></right-menu>
	</div>

</template>

<script>
	import HeadImage from '../common/HeadImage.vue';
	import RightMenu from '../common/RightMenu.vue';

	export default {
		name: "chatItem",
		components: {
			HeadImage,
			RightMenu
		},
		data() {
			return {
				rightMenu: {
					show: false,
					pos: {
						x: 0,
						y: 0
					},
					items: []
				}
			}
		},
		props: {
			chat: {
				type: Object
			},
			active: {
				type: Boolean
			},
			index: {
				type: Number
			}
		},
		methods: {
			showRightMenu(e) {
				let items =[]
				if(!this.chat.topnum){
					items.push({key: 'TOP',name: this.$t('comm.top')})
				}else{
					items.push({key: 'CANCELTOP',name: this.$t('comm.CancelTop')})
				}
				if(this.chat.unreadCount>0){
					items.push({key: 'BJREAD',name: this.$t('comm.bj_read')})
				}else{
					items.push({key: 'BJUNREAD',name: this.$t('comm.bj_Noread')})
				}
				if(this.chat.disturbing){
					items.push({key: 'TURBING',name: this.$t('comm.clear_disturb')})
				}else{
					items.push({key: 'TURBING',name: this.$t('comm.set_disturb')})
				}
				items.push({key: 'DELETE',name:this.$t('comm.remove_char')})
				this.rightMenu.items = items
				this.rightMenu.pos = {
					x: e.x,
					y: e.y
				};
				this.rightMenu.show = "true";
			},
			onSelectMenu(item) {
				this.$emit(item.key.toLowerCase());
			}
		},
		computed: {
			sendNickName(){
				if(this.chat.type=='GROUP'&&this.chat.lastsendId){
					let friend = this.friendStore.friends.find(fd=> fd.id == this.chat.lastsendId)
					if(friend){
						return friend.friendRemark || friend.nickName 
					}else{
						this.chat.sendNickName
					}
				}
				return null
			},
			isonline(){
				if(this.chat.type!='GROUP'){
					let friend = this.friendStore.friends.find(fd=> fd.id == this.chat.targetId)
					if(friend){
						return friend.online
					}
				}
				return false
			},
			friendStore() {
				return this.$store.state.friendStore;
			},
			showTime() {
				return this.$date.toTimeText(this.chat.lastSendTime, true)
			},
			atText() {
				if (this.chat.atMe) {
					return this.$t('comm.atme')
				} else if (this.chat.atAll) {
					return this.$t('comm.atAll')
				}
				return "";
			},
			showunreadCount(){
				return this.chat.unreadCount>99?"99+":this.chat.unreadCount
			}
		}
	}
</script>

<style scoped lang="scss">
	.chat-item {
		height: 64px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		color: black;
		
		border-radius: 8px;
		cursor: pointer;
		&:hover {
			background: rgba(246, 246, 252, 0.8);
		}

		&.active {
			background: #EEEEF8;
		}

		.chat-left {
			position: relative;
			display: flex;
			width: 48px;
			height: 48px;
			margin-left: 10px;
		}


		.chat-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			padding-left: 10px;
			text-align: left;
			overflow: hidden;

			.chat-name {
				display: flex;
				align-items: center;
				.chat-name-text {
					flex: 1;
					height:18px;
					line-height:18px;
					font-size: 16px;
					font-weight: 400;
					white-space: nowrap;
					overflow: hidden;
					color: #333333;
				}
				.chat-time-text{
					font-size: 12px;
					color: #999999;
					line-height: 14px;
					margin-right: 10px;
				}
			}

			.chat-content {
				height: 16px;
				display: flex;
				align-items: center;
				margin-top: 10px;
				width: 100%;
				font-size: 12px;
				line-height: 13px;
				.chat-at-text {
					color: #c70b0b;
				}
				.chat-send-name{
					color: #999999;
				}
				.chat-content-text {
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: #999999;
					::v-deep{
						img {
							width: 20px !important;
							height: 20px !important;
							vertical-align: middle !important;
						}
					}
				
				}
				.icon-miandarao{
					margin-right: 8px;
					font-size: 16px;
					color: #6C757D;
				}
				.unread-text {
					box-sizing: border-box;
					margin-right: 8px;
					height: 16px;
					min-width: 16px;
					background-color: #FD7E14;
					border-radius: 8px;
					border: 1px solid #FFFFFF;
					left: 31px;
					padding:0px 4px;
					font-weight: 400;
					font-size: 10px;
					color: #FFFFFF;
					line-height: 14px;
				}
			}
		}
	}
</style>
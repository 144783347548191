<template>
	<div class="chat-group-side" @click.stop>
		<div v-if="chat.type=='GROUP'" class="search">
			<el-input prefix-icon="el-icon-search" :placeholder="$t('comm.findGroupUser')" v-model="searchText"></el-input>
		</div>
		<el-scrollbar class="content-box">
			<!-- 好友 -->
			<template  v-if="chat.type!='GROUP'">
				<div class="item-info">
					<div class="friend-headinfo">
						<head-image :name="friend.nickName" :size="48" :url="friend.headImage" :id="friend.id" :showunline ="true" :online ="friend.online"></head-image>
						<span v-if="friend.online">{{$t('comm.online')}}</span>
					</div>
					<span>{{friend.friendRemark||friend.nickName}}</span>
				</div>
				<div class="item-info">
					<edit-string :title="$t('comm.Remark')" :value="friend.friendRemark" :min="0" :placeholder="friend.nickName" :max="15" filed="friendRemark" @editafter="editfriendafter"/>
				</div>
			</template>
			<!-- 群聊 -->
			<template v-else>
				<div class="item-info group-users" :style="{'height':Membersheight}"  :class="{'isShowALL':isShowALL}">
					<el-scrollbar class="group-side-scrollbar">
						<div v-show="!group.quit" class="group-side-member-list">
							<div v-for="(member,index) in showMembers" :key="member.id">
								<group-member  v-if="isShowALL||index<12"  class="group-side-member"  :member="member" 
									:showDel="false"  :canFriend="(isOwner||group.canFriend !=1) && member.type !='1'" ></group-member>
							</div>		
						</div>
					</el-scrollbar>
					<span class="showmore" v-if="!isShowALL&&groupMembers.length>=12" @click="isShowALL=true">{{$t('comm.showmore')}}<i class="el-icon-arrow-down"></i></span>
					<span class="showmore" v-if="isShowALL&&groupMembers.length>=12" @click="isShowALL=false">{{$t('comm.hidemore')}}<i class="el-icon-arrow-up"></i></span>
				</div>
				<div class="item-info">
					<div class="group-headinfo">
						<head-image :name="group.name" :size="48" :url="group.headImage"></head-image>
						<edit-string class="group-editname" :disabled="!isOwner" :value="group.name"  :placeholder="group.name" :min="2" :max="15" filed="name" @editafter="editafter"/>
					</div>
				</div>
				<div class="item-info editgroup">
					<edit-string :title="$t('comm.Remark')" :value="group.remark" :min="0" :placeholder="$t('comm.remark_inputinfo')" :max="15" filed="remark" @editafter="editafter"/>
					<edit-string :title="$t('comm.group_aliasName')" :value="group.aliasName" :placeholder="myname" :min="0" :max="15" filed="aliasName" @editafter="editafter"/>
					<edit-string :disabled="!isOwner" :title="$t('comm.group_info')" :iswrap="false" :value="group.notice"  :placeholder="$t('comm.notice_inputinfo')" :max="200" filed="notice" @editafter="editafter"/>
				</div>
			</template>
			<div class="item-info msgfind" @click="viewhistory">
				<span>{{$t("comm.findhistory")}}</span>
				<i class="el-icon-arrow-right"></i>
			</div>
			<div class="item-info setting" style="height:auto ;">
				<div><span>{{$t('comm.chatnobb')}}</span><el-switch  :value="isnobb"  @click.native="onmsgtip"/></div>
				<div><span>{{$t('comm.chattop')}}</span><el-switch :value="chattop"  @click.native="onTop"/></div>
			</div>
			<div class="item-info clearmsg" @click="clearallmsg">
				<span>{{$t('comm.clearhistory')}}</span>
			</div>
		</el-scrollbar>
		<div v-if="!isOwner&&chat.type=='GROUP'" class="bt">
			<el-button type="danger" @click="onQuit()" plain>{{$t('comm.exitgrouup')}}</el-button>
		</div>
	</div>
</template>

<script>
	import GroupMember from '../group/GroupMember.vue';
	import HeadImage from "../common/HeadImage.vue";
	import EditString from "../common/EditString.vue";

	export default {
		name: "chatGroupSide",
		components: {
			GroupMember,
			HeadImage,
			EditString
		},
		data() {
			return {
				searchText: "",
				showAddGroupMember: false,
				isShowALL:false,
				visible:false
			}
		},
		props: {
			chat: {
				type: Object
			},
			friend: {
				type: Object
			},
			group: {
				type: Object
			},
			groupMembers: {
				type: Array
			}
		},
		methods: {
			viewhistory(){
				this.$emit("viewhistory")
			},
			change(val){
				console.log(this.setdata,val)
			},
			onClose() {
				this.$emit('close');
			},
			onTop(){
				if(!this.chattop){
					let chatIdx = this.$store.state.chatStore.chats.findIndex(cs => cs == this.chat)
					this.$store.commit("top", chatIdx);
				}else{
					let chatIdx = this.$store.state.chatStore.chats.findIndex(cs => cs == this.chat)
					this.$store.commit("cleantop", chatIdx);
				}
			},
			onmsgtip(){
				if(this.chat.type!='GROUP'){
					this.editfriendafter({filed:"disturbing",value:!this.isnobb?1:0})
				}else{
					this.editafter({filed:"disturbing",value:!this.isnobb?1:0})
				}
			},
			editfriendafter({filed,value}){
				let param = {
					friendId:this.friend.id,
				}
				param[filed] = value
				//修改私聊备注
				this.$http({
					url: "/im/imFriend/updateFriendById",
					method:'PUT',
					data:param
				}).then((res) => {
					this.$emit('reload');
				})	
			},
			editafter({filed,value}){
				if(filed=="aliasName"&&!value.trim()){
					value = this.myname
				}
				let vo = JSON.parse(JSON.stringify(this.group));
				vo[filed] = value
				this.onSaveGroup(vo)
			},
			//清空聊天记录
			clearallmsg(){
				let is_del_friend = false
				const h = this.$createElement;
				let message =null
				if(this.chat.type =='GROUP'){
					message = h('div',{style:{'height': '90px','padding-top':'20px','padding-left':'25px'}},this.$t('comm.confirm_delAllhistory'))
				}else{
					let friendname =this.friend.friendRemark||this.friend.nickName
					message = h('div',{style:{'height':'90px','padding-left':'25px'}},[
						h('div',{style:{'padding-top':'20px'}},this.$t('comm.confirm_delAllhistory')),
						h('el-checkbox',{
							key:"checkbox"+Date.now(),
							style:{'padding-top':'20px'},
							on:{
								change:(val) => {
									is_del_friend =val
								}
							}
						}, this.$t('comm.togetherDel',[friendname]))
					])
				}
				this.$msgbox({
					title: this.$t('comm.clearhistory'),
					message: message,
					showCancelButton: true,
					confirmButtonText: this.$t('comm.delete'),
					cancelButtonText: this.$t('comm.cancel'),
					confirmButtonClass:"delete-confirm-Button-Class",
				}).then(() => {
					if(this.chat.type !='GROUP'&& is_del_friend){
						//双向删除好友信息
						let param = {
							idRemore:[],
							type:1,
							empty:2,
							friendId:this.chat.targetId
						}
						this.$http({
							url: `/im/groupMessage/addSingle`,
							method: 'POST',
							data:param
						}).then(() => {
							//删除本地
							this.$store.commit("clearAllMessage", this.chat);
						})
					}else{
						//删除本地
						this.$store.commit("clearAllMessage", this.chat);
					}
				}).catch(() => {})
			},
			onSaveGroup(vo) {
				this.$http({
					url: "/im/imGroup/modify",
					method: "put",
					data: vo
				}).then((group) => {
					this.$store.commit("updateGroup", group);
					this.$emit('reload');
					this.$message.success(this.$t('comm.editsuccess'));
				})
			},
			onQuit() {
				this.$confirm(this.$t('comm.exitgroupmsg'), this.$t('comm.confirmexit'), {
					confirmButtonText:this.$t('comm.confirm'),
					cancelButtonText: this.$t('comm.cancel'),
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `im/imGroup/quit/${this.group.id}`,
						method: 'delete'
					}).then(() => {
						this.$message.success(this.$t('comm.editsuccess'));
						this.$store.commit("removeGroup", this.group.id);
						this.$store.commit("activeGroup", -1);
						this.$store.commit("activeChat", -1);
						this.$store.commit("removeGroupChat", this.group.id);
					});
				})
			},

		},
		computed: {
			//成员列表高度计算
			Membersheight(){
				if(this.isShowALL){
					return  '500px'
				}else if(this.groupMembers.length>12){
					return  '280px'
				}else{
					let shownum = this.groupMembers.length
					return (Math.ceil(shownum/4)*80)+'px'
				}
			},
			myname(){
				return this.$store.state.userStore.userInfo.nickName
			},
			isnobb(){
				if(this.chat.type =="GROUP"){
					return this.group.disturbing?true:false
				}else{
					return this.friend.disturbing?true:false
				}
			},
			chattop(){
			 	return this.chat.topnum?true:false
			},
			ownerName() {
				let member = this.groupMembers.find((m) => m.userId == this.group.ownerId);
				return member && member.aliasName;
			},
			isOwner() {
				return this.group.ownerId == this.$store.state.userStore.userInfo.id;
			},
			isgroupjs(){
				return this.group.groupMute==1
			},
			showMembers(){
				let searchText = this.searchText.trim()
				return this.groupMembers.filter((member)=>{
					return !member.quit && (!searchText||member.aliasName.includes(searchText))?true:false
				})
			}
			
		}
	}
</script>
<style>
	.delete-confirm-Button-Class{
		background-color: #FF4D4F !important;
		border-color: #FF4D4F !important;
		color: #FFF;
	}
	.delete-confirm-Button-Class:hover{
		background-color: #ff3538 !important;
		border-color: #ff3538 !important;
	}
</style>
<style scoped lang="scss">
	.chat-group-side {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		.search{
			margin: 10px;
			.el-input{
				height: 32px;
				line-height: 32px;
			}
		}
		.content-box{
			flex: 1;
			height: 0px;
			::v-deep{
				.is-horizontal{
					display: none;
				}
				.el-scrollbar__wrap{
					margin: 0px !important;
					width: 100%;
				}
			} 
		}
		.item-info{
			height:72px ;
			margin: 0px 10px;
			border-bottom: 1px solid #EEEEEE;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			color: #333333;
			.friend-headinfo{
				display: flex;
				align-items: flex-end;
				span{
					font-size: 12px;
					line-height: 24px;
					margin-left: 10px;
				}
			}
		}
		.msgfind{
			cursor: pointer;
			i{
			width: 16px;
			height: 16px;
			color:#999999;
			}
		}
		.setting{
			flex-direction: column;
			justify-content:center;
			line-height: 29px;
			>div{
				margin:5px 0px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		.clearmsg{
			cursor: pointer;
			height: 48px;
		}
		.group-headinfo{
			width: 100%;
			display: flex;
			align-items: center;
			.group-editname{
				margin-left: 10px;
				::v-deep{
					.nowrap{
						color: #333333 !important;
					}
				}
			}
		}
		.group-users{
			flex-direction: column;
			justify-content: start;
			.group-side-scrollbar{
				width: 100%;
				flex: 1;
				.group-side-member-list {
					padding: 0px 0px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					font-size: 16px;
					text-align: center;
					.group-side-member,.group-side-invite{
						margin:5px 10px;
					}
					.group-side-invite {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 50px;
						align-self: start;
						.invite-member-btn {
							width: 100%;
							height: 50px;
							line-height: 50px;
							border-radius: 50%;
							color:#cccccc ;
							border: #cccccc solid 1px;
							font-size: 25px;
							cursor: pointer;
							box-sizing: border-box;
							&:hover {
								color:#aaaaaa ;
								border: #aaaaaa solid 1px;
							}
						}
						.invite-member-text {
							font-size: 16px;
							text-align: center;
							width: 100%;
							height: 30px;
							line-height: 30px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden
						}
					}
				}
			}
			.showmore{
				margin: 10px 0px;
				width: 100%;
				cursor: pointer;
				text-align: center;
				color: #999999;
			}
		}
		.editgroup{
			.edit-string{
				margin-top: 10px;
			}
			flex-direction: column;
			height: auto;
		}
		.bt{
			padding:10px;
			.el-button {
				margin: 10px 0px 0px 0px;
				width: 100%;
				height: 32px;
			}
		}
	}
</style>

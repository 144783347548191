<template>
  <el-dialog
      :visible.sync="dialogVisible"
      width="640px"
      :close-on-click-modal="false"
      :show-close="false">
      <div class="trans-view">
        <div class="left-view">
            <div class="search-bar">
              <el-input :placeholder="$t('comm.search')" prefix-icon="el-icon-search" v-model="searchText"></el-input>
              <div class="yy-icons" @click="isyymenu">
                <img src="@/assets/im-icons/im-setting.svg"  loading="lazy"/>	
              </div>
            </div>
            <el-collapse class="collapse" accordion>
              <el-collapse-item>
                <template slot="title"><div class="item-title">{{$t('comm.chatlist')}}</div></template>
                <el-scrollbar class="list-items">
                  <template v-for="(chat,index) in chatStore.chats">
                    <div class="char-items"  v-if="chat.groupType!=2" v-show="!searchText.trim()||chat.showName.includes(searchText.trim())" :key="index" @click="clickitem({id:chat.targetId,type:chat.type,name:chat.showName,headImage:chat.headImage})">
                      <head-image :url="chat.headImage" :name="chat.showName" :size="40"></head-image>
                      <div class="charname">{{chat.showName}}</div>
                      <div  class="chat-item-check"  :class ="{'item-active':ischeck({id:chat.targetId,type:chat.type})}">
                        <svg class="check-icon" viewBox="0 0 32 32"><path d="M1.952 18.080q-0.32-0.352-0.416-0.88t0.128-0.976l0.16-0.352q0.224-0.416 0.64-0.528t0.8 0.176l6.496 4.704q0.384 0.288 0.912 0.272t0.88-0.336l17.312-14.272q0.352-0.288 0.848-0.256t0.848 0.352l-0.416-0.416q0.32 0.352 0.32 0.816t-0.32 0.816l-18.656 18.912q-0.32 0.352-0.8 0.352t-0.8-0.32l-7.936-8.064z" fill="#FFF"></path></svg>
                      </div>
                    </div>
                  </template>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title"><div class="item-title">{{$t('comm.friend')}}</div></template>
                <el-scrollbar class="list-items">
                  <div class="char-items" v-for="(friend,index) in friendStore.friends" v-show="!searchText.trim()||(friend.friendRemark||friend.nickName).includes(searchText.trim())" :key="index" @click="clickitem({id:friend.id,type:'PRIVATE',name:friend.friendRemark||friend.nickName,headImage:friend.headImage})">
                    <head-image :url="friend.headImage" :name="friend.nickName" :size="40"></head-image>
                    <div class="charname">{{friend.friendRemark||friend.nickName}}</div>
                    <div  class="chat-item-check"  :class ="{'item-active':ischeck({id:friend.id,type:'PRIVATE'})}">
                      <svg class="check-icon" viewBox="0 0 32 32"><path d="M1.952 18.080q-0.32-0.352-0.416-0.88t0.128-0.976l0.16-0.352q0.224-0.416 0.64-0.528t0.8 0.176l6.496 4.704q0.384 0.288 0.912 0.272t0.88-0.336l17.312-14.272q0.352-0.288 0.848-0.256t0.848 0.352l-0.416-0.416q0.32 0.352 0.32 0.816t-0.32 0.816l-18.656 18.912q-0.32 0.352-0.8 0.352t-0.8-0.32l-7.936-8.064z" fill="#FFF"></path></svg>
                    </div>
                  </div>
                </el-scrollbar>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title"><div class="item-title">{{$t('comm.groupchat')}}</div></template>
                <el-scrollbar class="list-items">
                  <template v-for="(group,index) in groupStore.groups">
                    <div class="char-items"  v-if="group.groupType!=2" v-show="!searchText.trim()||(group.remark||group.name).includes(searchText.trim())" :key="index" @click="clickitem({id:group.id,type:'GROUP',name:group.remark||group.name,headImage:group.headImage})">
                        <head-image :url="group.headImage" :name="group.name" :size="40"></head-image>
                        <div class="charname">{{group.remark||group.name}}</div>
                        <div  class="chat-item-check"  :class ="{'item-active':ischeck({id:group.id,type:'GROUP'})}">
                          <svg class="check-icon" viewBox="0 0 32 32"><path d="M1.952 18.080q-0.32-0.352-0.416-0.88t0.128-0.976l0.16-0.352q0.224-0.416 0.64-0.528t0.8 0.176l6.496 4.704q0.384 0.288 0.912 0.272t0.88-0.336l17.312-14.272q0.352-0.288 0.848-0.256t0.848 0.352l-0.416-0.416q0.32 0.352 0.32 0.816t-0.32 0.816l-18.656 18.912q-0.32 0.352-0.8 0.352t-0.8-0.32l-7.936-8.064z" fill="#FFF"></path></svg>
                        </div>
                    </div>
                  </template>
                </el-scrollbar>
              </el-collapse-item>
            </el-collapse>
        </div>
        <div class="right-veiw">
          <div class="topinfo">
              <span>{{$t('comm.translateTo')}}:</span>
              <span>{{$t('comm.translateTo',[checkdata.length])}}</span>
          </div>
          <div class="selectuser">
            <el-scrollbar class="list-items">
              <div class="char-items" v-for="(item,index) in checkdata"  :key="index">
                <head-image :url="item.headImage" :name="item.name" :size="40"></head-image>
                <div class="charname">{{item.name}}</div>
                <i class="chat-item-cancel el-icon-error" @click="clickitem(item)"></i>
              </div>
            </el-scrollbar>
          </div>
          <div class="transmsg">
            <div class="info">{{$t('comm.translateTo',[transmsg.length])}}</div>
            <div class="content">{{$t('comm.from')}}:{{fromname}}</div>
          </div>  
          <div class="sentmsg">
            <el-input :placeholder="$t('comm.leaveToMsg')"  v-model="sendmsg"></el-input>
          </div>
          <div class="bt">
            <el-button @click="handleClose">{{$t('comm.cancel')}}</el-button>
            <el-button class="sendmsg" :disabled="checkdata.length==0"  @click="sendTransMsg">{{$t('comm.send')}}</el-button>
          </div>
        </div>
      </div>
      <right-menu v-show="rightMenu.show" :pos="rightMenu.pos" :items="rightMenu.Items"	@close="rightMenu.show = false" @select="onSelectMenu"></right-menu>
    </el-dialog>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";
import RightMenu from '../common/RightMenu.vue';
  export default {
    name:"CharTrans-dialog",
    components: {
			HeadImage,
      RightMenu
		},
    data() {
      return {
        dialogVisible: false,
        checkdata:[],
        searchText:"",
        transmsg:[],
        isyy:1,
        fromname:"",
        sendmsg:"",
        rightMenu: {
				  show: false,
          Items:[],
          pos: {
            x: 0,
            y: 0
          }
        },
      };
    },
    computed: {
      mine() {
			  return this.$store.state.userStore.userInfo;
		  },
      chatStore() {
				return this.$store.state.chatStore;
			},
      friendStore() {
				return this.$store.state.friendStore;
			},
      groupStore() {
				return this.$store.state.groupStore;
			}
    },
    methods: {
      open(transmsg){
        this.transmsg = transmsg
        this.checkdata = []
        this.fromname = this.abbrMsgFrom(transmsg)
        this.sendmsg=""
        this.isyy = 1
        this.dialogVisible =true
      },
      handleClose() {
        this.dialogVisible = false
      },
      sendTransMsg(){
        let msginfo = {
					transmitmsg:this.transmsg,
					charinfos: this.checkdata,
					sendText :this.sendmsg,
					isyy:this.isyy
				}
				this.$emit("sendmsg",msginfo)
        this.handleClose()
      },
      isyymenu(e){
        let items = []
        items.push({
          key: 'yy',
          name: this.$t('comm.showyy'),
          icon: this.isyy?"el-icon-check":""
        });
        items.push({
          key: 'noyy',
          name: this.$t('comm.hiedyy'),
          icon: !this.isyy?"el-icon-check":""
        });
        this.rightMenu.Items = items
        this.rightMenu.show = true
        this.rightMenu.pos = {
          x: e.x,
          y: e.y
        };
      },
      onSelectMenu(item){
        if(item.key=="yy"){
          this.isyy = 1
        }else if(item.key=="noyy"){
          this.isyy = 0
        }
      },
      abbrMsgFrom(msgs){
        //获取转发的消息人名 逗号隔开
        let namelist = {};
        for (var i = 0; i < msgs.length; i++) {
          let sendId = msgs[i].sendId+""
          if(namelist[sendId]){
            continue;
          }
          let name = msgs[i].sendNickName//sendNickName私聊为空，从好友里获取名称
          if(!name){
            if(msgs[i].selfSend){
              name = this.mine.nickName
            }else{
              let friend = this.friendStore.friends.find(fd=>fd.id == sendId)
              name =friend.nickName
            }
          }
          if(name){
            namelist[sendId] = name
          }
        }
        return Object.values(namelist).join(",")
      },
      clickitem(item){
        let index =  this.checkdata.findIndex(cd=> cd.id == item.id && cd.type == item.type)
        if(index>=0){
          this.checkdata.splice(index,1)
        }else{
          this.checkdata.push(item)
        }
      },
      ischeck({id,type}){
        return this.checkdata.findIndex(cd=> cd.id == id && cd.type == type)>=0?true:false
      }
    }
  };
</script>


<style scoped lang="scss">
.trans-view{
  height: 560px;
  display: flex;
  .left-view{
    flex: 1;
    width: 0px;
    display: flex;
    flex-direction: column;
    border-right: #D9D9D9 solid 1px;
    .search-bar{
      height: 56px;
      display: flex;
      padding: 20px 20px 0 20px;
      .el-input{
        flex: 1;
        height: 36px;
        ::v-deep{
          .el-input__inner{
            background-color: #F7F7F7;
            border: none;
          }
        }
      }
      .yy-icons{
        cursor: pointer;
        margin-left: 10px;
        width: 36px;
        height: 36px;
        background-color: #F7F7F7;
        border-radius: 6px;
        &:hover{
          background: #F0F0F0;
        }
        img{
          height: 24px;
          width: 24px;
          margin: 6px;
        }
      }
    }
    .collapse{
      border: none;
      flex:1;
      margin: 10px;
      height: 0px;
      ::v-deep{
        .el-collapse-item__header,.el-collapse-item__wrap{
          border: none;
        }
        .el-collapse-item__header{
          flex-direction: row-reverse;
          padding-left: 10px;
          height: 40px;
        }
        .el-collapse-item__content{
          padding: 0px;
        }
      }
      .item-title{
        flex:1;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
        color: #333333;
      }
      .list-items{
        height: 350px;
        .char-items{
          display: flex;
          align-items: center;
          padding:4px 10px ;
          border-radius: 5px;
          &:hover{
            background-color: #f5f5f5;
          }
          .charname{
            flex: 1;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            margin-left: 8px;
            text-align: left;
          }
          .chat-item-check{
            width: 16px;
            height: 16px;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            margin-right: 10px;
            .check-icon{
              height: 15px;
              width: 15px;
              display: none;
            }
          }
          .item-active{
            border:none;
            background-color:#7678EC;
            .check-icon{
              display: inline;
            }
          }
        }
      }
    }
  }
  .right-veiw{
    flex: 1;
    width: 0px;
    display: flex;
    flex-direction: column;
    .topinfo{
      height: 56px;
      padding:28px 20px 10px 20px;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .selectuser{
      flex: 1;
      height: 0px;
      padding: 10px;
      .list-items{
        height: 100%;
        .char-items{
          display: flex;
          align-items: center;
          padding:4px 10px ;
          border-radius: 5px;
          &:hover{
            background-color: #f5f5f5;
          }
          .charname{
            flex: 1;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            margin-left: 8px;
            text-align: left;
          }
          .chat-item-cancel{
            cursor: pointer;
            margin-right: 10px;
            font-size: 16px;
            color:#999999;
          }
          .chat-item-check{
            width: 16px;
            height: 16px;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            margin-right: 10px;
            .check-icon{
              height: 15px;
              width: 15px;
              display: none;
            }
          }
          .item-active{
            border:none;
            background-color:#7678EC;
            .check-icon{
              display: inline;
            }
          }
        }
      }
    }
    .transmsg{
      height: 60px;
      margin:0px 20px;
      background: #F7F7F7;
      border-radius: 6px;
      text-align:left;
      line-height:22px;
      font-weight:400;
      padding: 8px 10px;
      .info{
        font-size: 14px;
        color: #7678EC;
      }
      .content{
        font-size: 12px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .sentmsg{
      height: 36px;
      margin:10px 20px 0px 20px;
      ::v-deep{
          .el-input__inner{
            background-color: #F7F7F7;
            border: none;
          }
        }
    }
    .bt{
      margin-right: 20px;
      align-items: center;
      height: 56px;
      display: flex;
      justify-content: flex-end;

    }
  }
}
::v-deep{
  div .el-dialog__body{
    padding: 0px !important;
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog{
    border-radius: 12px;
  }
  .el-dialog__header{
    border-bottom:1px solid #D9D9D9;;
  }
}
</style>
<template>
	<div class="chat-box"  v-loading="loading"   @contextmenu.prevent="(e)=>{e.preventDefault()}">
		<el-container   class="chat-container" @click.native="closeRefBox()">
			<el-header height="88px">
				<head-image v-if="chat.type != 'GROUP'" class="avatar-image" :name="friend.nickName" :size="48" :url="friend.headImage" :id="friend.id" :showunline ="true" :online ="friend.online"></head-image>
				<head-image v-if="chat.type == 'GROUP'" class="avatar-image" :name="group.name" :size="48" :url="group.headImage"></head-image>
				<div class="char-header-info">
					<span>{{ title }}</span>
					<span class="char-lastonline" v-if="!friend.online&&friend.lastLoginTime&&chat.type!='GROUP'">{{$t('comm.Offlinetime')}}:{{$date.toTimeText(friend.lastLoginTime)}}</span>
				</div>
				<!-- <span title="" v-show="chat.groupType != '2'" class="btn-side el-icon-more"	@click.stop="showSide = !showSide"></span> -->
				<div title="" v-show="chat.type == 'GROUP'&&chat.groupType != '2'" class="btn-side">
					<svg @click="qiandao" v-show="!isqd_today"  class="icon" aria-hidden="true"><use xlink:href="#icon-qiandao"></use></svg>
					<svg @click="showqiandaoinfo" v-show="isqd_today" class="icon" aria-hidden="true"><use xlink:href="#icon-yiqiandao"></use></svg>
				</div>
			</el-header>
			<el-main style="padding: 0;">
				<el-container>
					<el-container class="content-box">
						<!-- 置顶内容 -->
						<el-header class="im-chat-topmsg" height="48px" v-if="Topmsg.length>0"  @click.native="clicktop">
							<div class="msgtop-text" >
								<div class="msgtop-title">{{$t('comm.topMsg')}}</div>
								<div class="msgtop-contents">
									<div class="msg-trans" :style="{transform:'translateY('+(-TopMsgIndex*17)+'px)'}">
										<div v-for="tmsg in Topmsg" :key="tmsg.id" class="msgtop-value" v-html="$emo.simplemsg(tmsg)"></div>
									</div>
								</div>
								<div class="msgtop-index">
									<div :style="{'height':100/Topmsg.length+'%',transform:'translateY('+((Topmsg.length>6?36-6+36/Topmsg.length:36)/Topmsg.length*TopMsgIndex)+'px)'}"></div>
								</div>
							</div>
							<svg v-if="Topmsg.length==1" @click="cancelAlltop()" class="icon msgtop-icon top-close" aria-hidden="true"><use xlink:href="#icon-guanbi"></use></svg>
							<svg v-else @click.stop="openTopMsg" class="icon msgtop-icon" aria-hidden="true"><use xlink:href="#icon-zhiding"></use></svg>
						</el-header>
						<el-main class="im-chat-main" >
							<msg-scrollview ref="msgscrollview" :chat="chat" :ismultiple="ismultiple" :changelist="chat.messages" @onScroll="onScroll">
								<template v-slot:default='{item,nextitem,islightrow}'>
									<chat-message-item 
										:mine="item.sendId == mine.id"
										:headImage="headImage(item)"
										:showName="showName(item)"
										:canFriend ="addFriendable"
										:msgInfo="item"
										:isAdmin="isAdmin"
										:lastmsgInfo="nextitem" 
										:groupMembers="groupMembers"
										@onShowMenu ="onShowMenu"
										@showimageMenu="showimageMenu"
										@checkclick ="checkclick"
										:highlight="islightrow"
										@clicktopmsg ="clicktopmsg"
										:isshowcheck="ismultiple"
										:online="headOnline(item)"
										@cilckyymsg="cilckyymsg"
										:ischannel ="chat.groupType == '2'"
										@robAction = "robAction"
										:defcheck="defcheckmsgid==item.id">
									</chat-message-item>
								</template>
							</msg-scrollview>
						</el-main>
						<el-footer :height="(104+(replymsg?48:0)+(more_bar?48:0))+'px'" v-show="chat.groupType != '2'||chat.groupType == '2'&&isLeader" class="im-chat-footer">
							<template v-if="ismultiple">
								<div class="multiple-view">
									<span></span>
									<div style="display:flex; justify-content: space-between; width: 144px;">
										<div>
											<span class="multitem" @click="onTransMultiMsg()">
												<span class="iconfont icon-zhuanfa"></span>
											</span>
											<span style="margin-top:4px;">{{$t('comm.transmit')}}</span>
										</div>
										<div>
											<span class="multitem" @click="onDeleteMultiMsg($event)">
												<span class="iconfont icon-shanchu"></span>
											</span>
											<span style="margin-top:4px">{{$t('comm.delete')}}</span>
										</div>
									</div>
									<div class="close" @click="ismultiple=false"><i class="iconfont icon-guanbi"></i></div>
								</div>
							</template>
							<!-- 禁言 -->
							<template v-else-if="nospeak">
								<div class="nospeak-view">
									<span>{{$t('comm.jyNotSend')}}</span>
								</div>
							</template>
							<template v-else>
								<!-- 回复 -->
								<div v-if="replymsg" class="chat-reply-msg">
									<div class="reply-info">
										<div class="reply-user">{{$t('comm.reply')+' '+ showName(replymsg)}}</div>
										<div class="reply-content" v-html="$emo.simplemsg(replymsg)"></div>
									</div>
									<span  @click="replymsg = null" class="iconfont icon-guanbi reply-close"></span>
								</div>		
								<!-- 按钮bar -->
								<div v-if="more_bar" class="char-showmore-bar">
									<file-upload :title="$t('comm.Sendpictures')" class="toolbar-bt" :maxSize="100 * 1024 * 1024" :fileTypes="['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/gif']" @success="uploadimg" :multiple="true" >
										<span class="iconfont icon-tupian1"></span>
									</file-upload>
									<file-upload  :title="$t('comm.sendfiles')" class="toolbar-bt" :action="'/file/upload'" :maxSize="100 * 1024 * 1024" @before="onFileBefore"	@success="onFileSuccess" @fail="onFileFail">
										<span class="iconfont icon-wenjian1"></span>
									</file-upload>
									<span :title="$t('comm.Receipt')" v-show="chat.type == 'GROUP'" class="iconfont icon-liaotianjilu1 toolbar-bt" :class="isReceipt ? 'chat-tool-active' : ''" @click="onSwitchReceipt"></span>
									<span :title="$t('comm.giftbox')" class="iconfont icon-liwu toolbar-bt"  @click="sendTreasure"></span>
									<span v-if="chat.type == 'GROUP'" :title="$t('comm.activeNum')" class="iconfont icon-huoyuedu toolbar-bt" @click="show_active=!show_active"></span>
								</div>
								<div v-show="show_active" class="send-content-area">
									<div class="active-item">
										<div class="tool-icon" @click ="sendActivation(1)">
											<img  src="@/assets/im-icons/activation-1.svg"/>
										</div>
										<span>{{$t('comm.activelevel2',['1'])}}</span>
									</div>
									<div class="active-item" >
										<div class="tool-icon" @click ="sendActivation(7)">
											<img  src="@/assets/im-icons/activation-7.svg"/>
										</div>
										<span>{{$t('comm.activelevel2',['7'])}}</span>
									</div>
									<div class="active-item">
										<div class="tool-icon" @click ="sendActivation(15)">
											<img  src="@/assets/im-icons/activation-15.svg"/>
										</div>
										<span>{{$t('comm.activelevel2',['15'])}}</span>
									</div>
									<div class="active-item">
										<div class="tool-icon" @click ="sendActivation(30)">
											<img  src="@/assets/im-icons/activation-30.svg"/>
										</div>
										<span>{{$t('comm.activelevel2',['30'])}}</span>
									</div>
								</div>

										
								<div v-show="!show_active" class="send-content-area">
									<template v-if="showVoice">
										<span class="record">{{$t('comm.recordsendinfo')}}</span>
										<chat-voice  @send="onSendRecord"></chat-voice>
										<span @click.stop="closeVoiceBox()" class="iconfont icon-guanbi guanbi-right"></span>
									</template>
									<!-- 发送输出界面 -->
									<template v-else>
										<div :title="$t('comm.Emoji')" class="toolbar-bt" ref="emotion"  @click.stop="showEmotionBox()">
											<span class="iconfont icon-biaoqing"></span>
										</div>
										<span title="更多" class="iconfont icon-huixingzhen toolbar-bt"  @click="more_bar =! more_bar"></span>
										<div class="input-message">
											<chat-input-msg ref="editBox" :disabled="lockMessage"  :isReceipt="isReceipt" :groupMembers="groupMembers" :groupownerId="group.ownerId" @sendEnter="sendMessage()" @isempty="(val)=>{ableSend=!val}"></chat-input-msg>
										</div>
										<div v-if="!No_YuYIN" :title="$t('comm.voice')" class=" toolbar-bt">
											<span class="iconfont icon-yuyin"  @click.stop="showVoiceBox"></span>
										</div>
										<span class="iconfont icon-fasong" :class="{'disClick':!ableSend}" @click="()=>{ableSend&&sendMessage()}"></span>
										<div v-if="sendState" class="send-state">{{sendState=='sending'&&$t('comm.sending')}} </div>
									</template>
								</div>
							</template>
						</el-footer>
					</el-container>
					<!-- <el-aside class="chat-group-side-box" width="300px" v-if="showSide"  >
						<chat-group-side :group="group" :groupMembers="groupMembers" :friend="friend" :chat="chat" @close="closeRefBox"  @viewhistory="showHistoryBox"  @reload="reload"></chat-group-side>
					</el-aside> -->
					<chat-sign-in ref="signin" :group="group"></chat-sign-in>
				</el-container>
			</el-main>
			<emotion ref="emoBox" @emotion="onEmotion"></Emotion>

			<right-menu v-show="rightMenu.show" :pos="rightMenu.pos" :items="rightMenu.Items"	@close="rightMenu.show = false" @select="onSelectMenu"></right-menu>
		</el-container>
		<div class="right-side" v-if="chat.groupType != '2'">
			<div class="right-side-top">
				<chat-user-info v-if="chat.type != 'GROUP'" :friend="friend" @reload="reload"/>
				<chat-details v-if="chat.type == 'GROUP'"  :group="group" :chat="chat" :groupMembers="groupMembers" :isAdmin="isAdmin" @viewhistory="showHistoryBox" @reload="reload"/>
			</div>
			<div class="right-side-bottom">
				<chat-details v-if="chat.type != 'GROUP'" :friend="friend" :chat="chat" @viewhistory="showHistoryBox" @reload="reload"/>
				<chat-member v-if="chat.type == 'GROUP'" :canFriend="this.group.canFriend!=1" @reload="reload" 	@showimageMenu="showimageMenu" :group="group" :groupMembers="group.id?groupMembers:[]"/>
			</div>
		</div>
		<charmsgdialog 
			ref ="msgdialog"
			:title="$t('comm.topMsgNum',[Topmsg.length])"
			:chattype="chat.type"
			:groupMembers="groupMembers"
			:group="group"
			:isAdmin="isAdmin"
			:friend="friend"
			@onShowMenu ="onTopShowMenu"
			@cancelAlltop = "cancelAlltop"
		></charmsgdialog>

		<chat-bx-dialog ref ="chatbxdialog" :chattype="chat.type"  :friend="friend" :groupMembers="groupMembers" @sendRedwrap="sendRedwrap"> </chat-bx-dialog>
		<chatbx-open-dialog :friend="friend" :chattype="chat.type" :groupMembers="groupMembers" ref ="chatbxopendialog"></chatbx-open-dialog>
		<chartransdialog ref = "transdialog" @sendmsg="sendtransmitmsg"></chartransdialog>
		<chat-history-dialog  :title ="historytitle" ref="historydialog" :chat="chat" :friend="friend" :group="group" :groupMembers="groupMembers"/>
	</div>
</template>

<script>
import ChatGroupSide from "./ChatGroupSide.vue";
import ChatMessageItem from "./ChatMessageItem.vue";
import FileUpload from "../common/FileUpload.vue";
import Emotion from "../common/Emotion.vue";
import ChatHistory from "./ChatHistory.vue";
import ChatAtBox from "./ChatAtBox.vue"
import HeadImage from "../common/HeadImage.vue";
import RightMenu from '../common/RightMenu.vue';
import charmsgdialog from './Charmsg-dialog.vue';
import chartransdialog from './CharTrans-dialog.vue';
import chatHistoryDialog from './ChatHistory-dialog.vue'
import ChatVoice from "./ChatVoice.vue";
import ChatbxOpenDialog from './ChatBx-open-dialog.vue'
import ChatBxDialog from './ChatBx-dialog.vue'
import ChatInputMsg from './ChatInputMsg.vue'
import ChatSignIn from './ChatSignIn.vue'
import chatDetails from './chat-details.vue'
import chatUserInfo from './chat-user-info.vue'
import chatMember from './chat-member.vue'
import {uploadFile} from '@/api/oss.js'
import msgScrollview from './msg-scrollview.vue'

export default {
	name: "chatPrivate",
	components: {
		ChatMessageItem,
		FileUpload,
		ChatGroupSide,
		Emotion,
		ChatHistory,
		ChatAtBox,
		HeadImage,
		RightMenu,
		charmsgdialog,
		chartransdialog,
		chatHistoryDialog,
		ChatVoice,
		ChatInputMsg,
		ChatBxDialog,
		chatDetails,
		chatUserInfo,
		chatMember,
		ChatbxOpenDialog,
		ChatSignIn,
		msgScrollview
	},
	props: {
		chat: {
			type: Object
		}
	},
	data() {
		return {
			No_YuYIN:process.env.VUE_APP_NO_YUYIN=='1',
			loading :false,
			friend: {},
			group: {},
			groupMembers: [],
			isReceipt: true,
			showVoice: false, // 是否显示语音录制弹窗
			showSide: false, // 是否显示群聊信息栏
			lockMessage: false, // 是否锁定发送，
			showMinIdx: 0, // 下标低于showMinIdx的消息不显示，否则页面会很卡

			TopMsgIndex:0,//置顶显示第N条
			rightMenu: {
				show: false,
				Items:[],
				msgInfo:null,
				pos: {
					x: 0,
					y: 0
				}
			},
			historytitle:"",
			ismultiple:false,
			multipledata:[],//多选选中项
			defcheckmsgid:-1,//多选默认选中消息id，默认一条
			showlightrow:false,//高亮指定行
			firsttop:true,//点击置顶 是否定位到当前位置，而不是上一条
			timer:null,
			noscrollevent:false,//是否触发滚动临时变量，避免高频触发该事件
			replymsg:null,//回复消息
			ableSend:false,
			sendState:'',//为空可发送 sending  发送中
			more_bar:false,//是否显示底部按钮bar
			show_active:false//是否显示底部发送活跃度
		}
	},
	methods: {
		showqiandaoinfo(){
			this.$refs.signin.open()
		},
		qiandao(){
			let datenow = Date.now()
			this.$http({
				url: 'im/groupMember/addGroupSign',
				method: 'POST',
				data: {
					signTime:Math.floor(Date.now()/1000),
					groupId:this.group.id,
					signType:0,
				}
			}).then(()=>{
				this.$message.success(this.$t('comm.signsuccessful'));
				this.$store.commit("setqiandado",{group:this.group,datetime:datenow});
			}).catch((res)=>{
				if(res.code==-1){
					this.$store.commit("setqiandado",{group:this.group,datetime:datenow});
					this.$message.error(res.msg)
				}
			})
		},
		//发送活跃度
		sendActivation(day){
			this.show_active = false
			this.$http({
				url: '/im/groupMessage/qryLiveness',
				method: 'POST',
				data: {
					groupId:this.group.id,
					day:day
				}
			}).then((res)=>{})
		},
		showVoiceBox() {
			this.showVoice = true;	//打开语言窗口
		},
		closeVoiceBox() {
			this.showVoice = false; //关闭语言窗口
		},
		onSendRecord(data){
			let msgInfo = {
				content: JSON.stringify(data),
				type: 3,
				receipt: this.isReceipt
			}
			// 填充对方id
			this.fillTargetId(msgInfo, this.chat.targetId);
			this.$http({
				url: this.messageAction,
				method: 'post',
				data: msgInfo
			}).then((id) => {
				msgInfo.id = id;
				msgInfo.sendTime = new Date().getTime();
				msgInfo.sendId = this.$store.state.userStore.userInfo.id;
				msgInfo.selfSend = true;
				msgInfo.status = this.$enums.MESSAGE_STATUS.UNSEND;
				msgInfo.readedCount = 0;
				this.$store.commit("insertMessage", msgInfo);
				// 会话置顶
				this.moveChatToTop();
				// 滚动到底部
				this.scrollToBottom();
				// 关闭录音窗口
				// this.showVoice = false;
				// this.isReceipt = false;
			})
		},
		//点击引用消息
		cilckyymsg(msgInfo){
			let isnowchar = false
			if(msgInfo.cite_groupId){
				if(msgInfo.cite_groupId == this.chat.targetId &&this.chat.type=="GROUP"){
					isnowchar = true
				}
			}else if(this.chat.targetId!="GROUP"){
				isnowchar = true
			}
			if(isnowchar){
				let yyid = msgInfo.cite_id
				if(yyid){
					this.locationmsg(yyid)
				}
			}
		},
		onDeleteMultiMsg(e){
			let t = this
			let existOhterMsg = false
			if(!this.isAdmin && this.chat.type == "GROUP"){//群里非自己消息不允许删除(非群主)
				for (var i = 0; i < this.multipledata.length; i++) {
					if(!this.multipledata[i].selfSend){
						existOhterMsg = true
					}
				}
			}
			this.rightMenu.Items = [ 
				{key: 'del_multi_onlyme',color:"#FF4D4F",name: this.$t('comm.del_onlyme')},
				{key: 'del_multi_infom',color:existOhterMsg?"#757575":"#FF4D4F",name:this.chat.type == "GROUP"?this.$t('comm.del_group_infom'):this.$t('comm.del_friend_infom')},
			]
			this.rightMenu.pos = {
				x: e.x,
				y: e.y
			};
			this.rightMenu.show = "true"
		},
		onTransMultiMsg(){
			if(this.multipledata.length>0){
				this.$refs.transdialog.open(this.multipledata)
			}else{
				this.$message.error(this.$t("comm.placeCheckdata"));
			}
		},
		//取消所有置顶消息
		cancelAlltop(){
			let topmsg = this.Topmsg.slice();
			topmsg.forEach(tmsg=>{
				if (this.chat.type == "GROUP"&&this.group.groupTop&&!this.isAdmin){//非管理员只取消本地置顶
					this.$store.commit("cleantopmsg",tmsg);
				}else{
					this.canceltop(tmsg)
				}
			})
		},
		//发送转发的消息
		sendtransmitmsg({isyy,sendText,charinfos,transmitmsg}){
			this.ismultiple = false
			charinfos.forEach(({id,type})=>{
				let isnowchat = id==this.chat.targetId&&type==this.chat.type 
				let msgInfos = []
				//消息根据从小到大排列
				transmitmsg.sort(function(a,b){if(a.id < b.id){return -1;}else{return -0;}})
				transmitmsg.forEach(msg=>{
					let msgInfo = {
						content:msg.content,
						atUserIds:[],
						receipt:false,
						type:msg.type,
					}
					if (type == "GROUP") {
						msgInfo.groupId = id;
					} else {
						msgInfo.recvId = id;
					}
					if(isyy){
						let cite_name = msg.sendNickName
						if(!cite_name){
							if(msg.selfSend){
								cite_name = this.mine.nickName
							}else{
								let friend = this.friendStore.friends.find(fd=>fd.id == id)
								if(friend){
									cite_name = friend.nickName
								}else{
									cite_name = this.chat.showName
								}
							}
						}
						msgInfo.cite_id=msg.id,
						msgInfo.cite_type=msg.type,
						msgInfo.cite_groupId=msg.groupId||null,
						msgInfo.cite_name=cite_name,
						msgInfo.cite_mes_type=1
					}
					msgInfos.push(msgInfo)
				})
				if (sendText.trim()) {
					let msgInfo = {
						content: sendText,
						atUserIds: [],
						receipt: false,
						type: 0
					}
					// 填充对方id
					if (type == "GROUP") {
						msgInfo.groupId = id;
					} else {
						msgInfo.recvId = id;
					}
					msgInfos.push(msgInfo)
				}
				let url = `/im/privateMessage/sendList`
				if (type == "GROUP") {
					url = "/im/groupMessage/sendList"
				}
				this.$http({
					url: url,
					method: 'POST',
					data: msgInfos
				}).then((ids) => {
					this.transmitmsg = []
					this.replymsg = null
					if(!isnowchat){
						this.addchatlist({id,type})
					}
					for (var i = 0; i < msgInfos.length; i++) {
						let msgInfo = msgInfos[i]
						msgInfo.id = ids[i];
						msgInfo.sendTime = new Date().getTime();
						msgInfo.sendId = this.mine.id;
						msgInfo.selfSend = true;
						msgInfo.readedCount = 0,
						msgInfo.status = this.$enums.MESSAGE_STATUS.UNSEND;
						this.$store.commit("insertMessage", msgInfo);
					}
				}).finally(() => {
					if(isnowchat){
						// 滚动到底部
						this.scrollToBottom();
					}
				})
			})
		},
		addchatlist({type,id}){
			if(type=="GROUP"){
				let group = this.groupStore.groups.find(fd=>fd.id == id)
				if(group){
					let chat = {
						type: 'GROUP',
						targetId: group.id,
						showName: group.remark||group.name,
						headImage: group.headImage,
						groupType: group.groupType,
						disturbing: group.disturbing,
					};
					this.$store.commit("openChat", chat);
				}
			}else{
				let friend = this.friendStore.friends.find(fd=>fd.id == id)
				if(friend){
					let chat = {
						type: 'PRIVATE',
						targetId: friend.id,
						showName:  friend.friendRemark || friend.nickName,
						headImage: friend.headImage,
						disturbing: friend.disturbing,
					}
					this.$store.commit("openChat", chat);
				}
			}
		},
		openTopMsg(){
			this.$refs.msgdialog.open(this.Topmsg)
		},
		checkclick({check,msgInfo}){
			if(this.ismultiple){
				let index = this.multipledata.findIndex(msg=>msg.id == msgInfo.id)
				if(check&&index<0){
					this.multipledata.push(msgInfo)
				}else if(!check&&index>=0){
					this.multipledata.splice(index,1)
				}
			}
		},
		//点击置顶消息提示，地位到置顶消息
		clicktopmsg(msgid){
			let index = this.Topmsg.findIndex(msg=>msg.id == msgid)
			if(index>=0){
				this.firsttop = true
				this.TopMsgIndex = index
				this.clicktop()
			}else{
				this.locationmsg(msgid)
			}
		},
		//点击置顶消息定位到置顶消息
		clicktop(){
			if(!this.firsttop){
				this.TopMsgIndex--
				if(0>this.TopMsgIndex){
					this.TopMsgIndex = this.Topmsg.length-1
				}
			}
			this.firsttop = false
			let tmsg = this.Topmsg[this.TopMsgIndex]
			if(tmsg){
				this.locationmsg(tmsg.id)
			}
		},
		locationmsg(msgid){
			this.$refs.msgscrollview.queryscrollMsg(this.chat.type,this.chat.targetId,msgid)
		},
		moveChatToTop(){
			let chatIdx = this.$store.getters.findChatIdx(this.chat);
			this.$store.commit("moveTop",chatIdx);
		},
		closeRefBox() {
			this.showSide = false
			this.$refs.emoBox.close();
			if(this.$refs.editBox){
				this.$refs.editBox.closeRefBox();
			}
		},
		onCall(type){
			if(type == this.$enums.MESSAGE_TYPE.RT_VOICE){
				this.showChatVideo('voice');
			}else if(type == this.$enums.MESSAGE_TYPE.RT_VIDEO){
				this.showChatVideo('video');
			}
		},
		onSwitchReceipt() {
			this.isReceipt = !this.isReceipt;
		},
		onImageFail(e, file) {
			let msgInfo = JSON.parse(JSON.stringify(file.msgInfo));
			msgInfo.loadStatus = 'fail';
			this.$store.commit("insertMessage", msgInfo);
		},
		onFileSuccess(res, file) {
			let data = {
				name: file.name,
				size: file.size,
				url: res.url
			}
			let msgInfo = JSON.parse(JSON.stringify(file.msgInfo));
			msgInfo.content = JSON.stringify(data);
			msgInfo.receipt = this.isReceipt
			if(this.replymsg){//是否回复消息
				msgInfo.cite_id=this.replymsg.id
				msgInfo.cite_type=this.replymsg.type
				msgInfo.cite_groupId=this.group.id||null
				msgInfo.cite_content=this.replymsg.content
				msgInfo.cite_name=this.showName2(this.replymsg)
			}
			this.$http({
				url: this.messageAction,
				method: 'post',
				data: msgInfo
			}).then((id) => {
				this.replymsg =null
				msgInfo.loadStatus = 'ok';
				msgInfo.id = id;
				this.isReceipt = false;
				this.$store.commit("insertMessage", msgInfo);
				// 会话置顶
				this.moveChatToTop();
				// 滚动到底部
				this.scrollToBottom();
			}).finally(()=>{
				this.sendState =''
			})
		},
		onFileFail(e, file) {
			this.sendState =''
			this.$message.error(this.$t('comm.sendfail'))
		},
		onFileBefore(file) {
			this.show_active=false
			let url = URL.createObjectURL(file);
			let data = {
				name: file.name,
				size: file.size,
				url: url
			}
			let type = this.$enums.MESSAGE_TYPE.FILE
			let ext = file.name.split('.').pop();
			if(ext =="jpg"||ext =="png"||ext =="gif"){
				type = this.$enums.MESSAGE_TYPE.IMAGE
			}
			let msgInfo = {
				id: 0,
				sendId: this.mine.id,
				content: JSON.stringify(data),
				sendTime: new Date().getTime(),
				selfSend: true,
				type: type,
				loadStatus: "loading",
				readedCount: 0,
				status: this.$enums.MESSAGE_STATUS.UNSEND
			}
			// 填充对方id
			this.fillTargetId(msgInfo, this.chat.targetId);
			// 插入消息
			// this.$store.commit("insertMessage", msgInfo);
			//避免消息顺序混乱.这里不提前插入消息，直接改发送状态
			this.sendState ='sending'//发送状态改成发送中
			// // 会话置顶
			// this.moveChatToTop();
			// // 滚动到底部
			// this.scrollToBottom();
			// 借助file对象透传
			file.msgInfo = msgInfo;
		},
		onCloseSide() {
			this.showSide = false;
		},
		onScrollToTop() {
			// 多展示10条信息
			this.showMinIdx = this.showMinIdx > 10 ? this.showMinIdx - 10 : 0;
		},
		onScroll(e) {
			// let scrollElement = e.target
			// let scrollTop = scrollElement.scrollTop
			// if (scrollTop < 30&&this.showMinIdx!=0) { // 在顶部,不滚动的情况
			// 	// 多展示20条信息
			// 	this.showMinIdx = this.showMinIdx > 20 ? this.showMinIdx - 20 : 0;
			// 	let scrollHeight = this.scrollWrapper.scrollHeight 
			// 	this.$nextTick(() => {
			// 		this.scrollWrapper.scrollTop = this.scrollWrapper.scrollHeight-scrollHeight;
			// 	});
			// }
			// if(!this.noscrollevent){
			// 	this.noscrollevent = true
			// 	this.firsttop = true
			// 	setTimeout(()=>{
			// 		let lastindex =  this.Topmsg.length -1
			// 		for(var i = 0; i < this.Topmsg.length; i++) {
			// 			let index = this.chat.messages.findIndex(msg=>msg.id == this.Topmsg[i].id)
			// 			let itemli = document.getElementById('chat-item-'+index)
			// 			if(itemli){
			// 				if(this.scrollWrapper.clientHeight+this.scrollWrapper.scrollTop-(itemli.offsetTop-this.scrollWrapper.offsetTop)>=0){
			// 					lastindex = i
			// 				}
			// 			}else{
			// 				lastindex = i
			// 			}
			// 		}
			// 		this.TopMsgIndex = lastindex
			// 		this.noscrollevent =false
			// 	},500)
			// }
		},
		showEmotionBox() {
			let width = this.$refs.emotion.offsetWidth;
			let left = this.$elm.fixLeft(this.$refs.emotion);
			let top = this.$elm.fixTop(this.$refs.emotion);
			this.$refs.emoBox.open({
				x: left + width / 2,
				y: top
			})
		},
		onEmotion(emoText) {
			//this.$refs.editBox.insertEmotion(emoText)
			this.$refs.editBox.insertText(emoText)
		},
		showChatVideo(mode) {
			let rtcInfo = {
				mode: mode,
				isHost: true,
				friend: this.friend,
				sendId: this.mine.id,
				recvId: this.friend.id,
				offer: "",
				state: this.$enums.RTC_STATE.WAIT_CALL
			}
			this.$store.commit("setRtcInfo",rtcInfo);
		},
		showHistoryBox() {
			if(this.chat.type!="GROUP"){
				this.historytitle = this.$t("comm.ToXXFriendhistory",[this.friend.friendRemark||this.friend.nickName])
			}else{
				this.historytitle =this.$t("comm.ToXXGrouphistory",[this.group.remark||this.group.name])
			}
			this.$refs.historydialog.open()
		},
		onSendVoice(data) {
			let msgInfo = {
				content: JSON.stringify(data),
				type: 3,
				receipt: this.isReceipt
			}
			// 填充对方id
			this.fillTargetId(msgInfo, this.chat.targetId);
			this.$http({
				url: this.messageAction,
				method: 'post',
				data: msgInfo
			}).then((id) => {
				msgInfo.id = id;
				msgInfo.sendTime = new Date().getTime();
				msgInfo.sendId = this.mine.id;
				msgInfo.selfSend = true;
				msgInfo.status = this.$enums.MESSAGE_STATUS.UNSEND;
				msgInfo.readedCount = 0;
				this.$store.commit("insertMessage", msgInfo);
				// 会话置顶
				this.moveChatToTop();
				// 保持输入框焦点
				this.$refs.editBox.focus();
				// 滚动到底部
				this.scrollToBottom();
				// 关闭录音窗口
				this.showVoice = false;
				this.isReceipt = false;
				
			})
		},
		fillTargetId(msgInfo, targetId) {
			if (this.chat.type == "GROUP") {
				msgInfo.groupId = targetId;
			} else {
				msgInfo.recvId = targetId;
			}
		},
		async sendMessage() {
			if(this.sendState){
				return
			}
			let type  = 0
			let InputValObj = this.$refs.editBox.getInputVal()
			let sendText = InputValObj.SendText
			if(InputValObj.type==1) {//发送单张图片
				this.sendImageMessage(InputValObj.imgFileList[0]);
			}else if(InputValObj.type==0) {//文本
				this.sendTextMessage(sendText,0);
			}else{
				this.sendState ='sending'
				let tempindex = 0
				do{
					let islast = false
					let files = InputValObj.imgFileList.splice(0,9)
					if(InputValObj.imgFileList.length==0){
						islast = true
					}
					tempindex++
					this.sendTextImgMessage(files).then((res)=>{
						let data = []
						for (let key in res) {
							data.push(res[key])
						}
						if(sendText){
							data.push({type:0,text:sendText})
						}
						this.sendTextMessage(JSON.stringify(data),9);
					}).catch((e)=>{
						this.$message.error(this.$t('comm.sendfail'))
					}).finally(()=>{
						tempindex--
						if(tempindex==0){
							this.sendState =''
						}
					})
				}while(InputValObj.imgFileList.length!=0)
			}
		},
		uploadimg(file){
			this.show_active=false
			this.$refs.editBox.insertImg(file)
		},
		sendTextImgMessage(files) {
			return new Promise((resolve, reject) => {
				let length  = files.length
				let sucNum  = 0
				let failNum = 0
				let tempdate = {}
				files.forEach((file,index)=>{
					uploadFile(file).then((res) => {
						if(res.res.status == 200){
							let data = {name:res.name,url:res.url};
							data.type = 1
							tempdate[index] = data
							sucNum++
						}else{
							failNum++
						}
					}).catch((res) => {
						failNum++
					}).finally(()=>{
						if(failNum ==length){
							reject()
						}
						if(failNum+sucNum == length){
							resolve(tempdate);
						}
					})
				})
			})
		},
		sendImageMessage(file) {
			this.sendState ='sending'
			uploadFile(file).then((res) => {
				if(res.res.status == 200){
					let data = {name:res.name,url:res.url};
					let msgInfo = {
						id:0,
						sendId: this.mine.id,
						content: JSON.stringify(data),
						sendTime: new Date().getTime(),
						selfSend: true,
						type: 1,
						readedCount: 0,
						loadStatus: "loading",
						status: this.$enums.MESSAGE_STATUS.UNSEND
					}
					// 填充对方id
					this.fillTargetId(msgInfo, this.chat.targetId);
					msgInfo.receipt = this.isReceipt;
					if(this.replymsg){//是否回复消息
						msgInfo.cite_id=this.replymsg.id
						msgInfo.cite_type=this.replymsg.type
						msgInfo.cite_groupId=this.group.id||null
						msgInfo.cite_content=this.replymsg.content
						msgInfo.cite_name=this.showName2(this.replymsg)
					}
					this.$http({
						url: this.messageAction,
						method: 'post',
						data: msgInfo
					}).then((id) => {
						this.replymsg = null
						msgInfo.loadStatus = 'ok';
						msgInfo.id = id;
						this.isReceipt = false;
						this.$store.commit("insertMessage", msgInfo);
						this.resetEditor()
						// 会话置顶
						this.moveChatToTop();
						// 滚动到底部
						this.scrollToBottom();
						this.sendState =''
					})
				}else{
					this.sendState =''
					this.$message.error(this.$t('comm.sendfail'))
				}	
			}).catch((res) => {
				this.sendState =''
				this.$message.error(this.$t('comm.sendfail'))
			})
		},
		//直接发送，1译文发送
		sendTextMessage(sendText,type,callback) {
			if (!sendText.trim()) {
				return
			}
			let msgInfo = {
				content: sendText,
				type: type||0//默认0，文本   9:图文
			}
			// 填充对方id
			this.fillTargetId(msgInfo, this.chat.targetId);
			if(this.replymsg){//是否回复消息
				msgInfo.cite_id=this.replymsg.id
				msgInfo.cite_type=this.replymsg.type
				msgInfo.cite_groupId=this.group.id||null
				msgInfo.cite_content=this.replymsg.content
				msgInfo.cite_name=this.showName2(this.replymsg)
			}
			// 被@人员列表
			if (this.chat.type == "GROUP") {
				msgInfo.atUserIds = this.$refs.editBox.createAtUserIds();
				msgInfo.receipt = this.isReceipt;
			}
			this.lockMessage = true;
			this.sendState ='sending'
			this.$http({
				url: this.messageAction,
				method: 'post',
				data: msgInfo
			}).then((id) => {
				callback&&callback(msgInfo)
				this.replymsg = null
				msgInfo.id = id;
				msgInfo.sendTime = new Date().getTime();
				msgInfo.sendId = this.mine.id;
				msgInfo.selfSend = true;
				msgInfo.readedCount = 0;
				msgInfo.status = this.$enums.MESSAGE_STATUS.UNSEND;
				this.$store.commit("insertMessage", msgInfo);
				// 会话置顶
				this.moveChatToTop();
			}).finally(() => {
				this.sendState =''
				// 解除锁定
				this.lockMessage = false;
				this.inputTransText =""
				this.TransInputState="read"
				this.scrollToBottom();
				this.resetEditor();
				this.isReceipt = false;
			});
		},
		sendRedwrap(info){
			let parameter = {
			    type:info.type,
			    totalAmount:info.amount,
			    totalPacket:info.num,
			    params: {
			        propId: 1,
			        content:info.msg,
			    },
			    appointUser:info.items
			}
			this.fillTargetId(parameter.params, this.chat.targetId);
			const loading = this.$loading({
				lock: true,
				text: '',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			this.$http({
				url: 'im/propInfo/send',
				method: 'post',
				data: parameter
			}).then((res) => {
				let content = {type:info.type,redPacketId:res.redPacketId,title:info.msg}
				content.userIds = info.items.map((uinfo)=>{
					return  uinfo.uid
				})
				let msgInfo = {
					content: JSON.stringify(content),
					type:7
				}
				this.fillTargetId(msgInfo, this.chat.targetId);
				msgInfo.id = res.msgId;
				msgInfo.sendTime = new Date().getTime();
				msgInfo.sendId = this.mine.id;
				msgInfo.selfSend = true;
				msgInfo.readedCount = 0;
				msgInfo.status = this.$enums.MESSAGE_STATUS.UNSEND;
				this.$store.commit("insertMessage", msgInfo);
				// 会话置顶
				this.moveChatToTop();
			}).catch((res)=>{
				if(res.code==-1){
					this.$message.error(res.msg)
				}
			}).finally(() => {
				loading.close();
				this.scrollToBottom();
			});
		},
		onTopShowMenu({e,msgInfo}){
			let items = [];
			if(msgInfo.type == this.$enums.MESSAGE_TYPE.TEXT){
				items.push({
					key: 'COYP',
					name: this.$t('comm.copy'),
				});
			}
			items.push({
				key: 'UNTOPMsg',
				name: this.$t('comm.CancelTop'),
			});
			items.push({
				key: 'Transmit',
				name: this.$t('comm.transmit'),
			});
			items.push({
				key: 'DELETE',
				name: this.$t('comm.delete'),
				color:'#FF4D4F'
			});
			this.rightMenu.msgInfo = msgInfo
			this.rightMenu.Items = items
			this.rightMenu.pos = {
				x: e.x,
				y: e.y
			};
			this.rightMenu.show = "true"
		},
		sendTreasure(e){
			//发宝箱界面
			this.$refs.chatbxdialog.open()
		},
		robAction(msgInfo){
			//抢宝箱界面
			this.$refs.chatbxopendialog.open(msgInfo)
		},
		//头像右键
		showimageMenu({e,msgInfo}){
			let UserMember = this.groupMembers.find(gm=>gm.userId==msgInfo.sendId)
			let toadmin =  UserMember.memberType == 1||UserMember.memberType == 2?true:false
			if(this.chat.type!="GROUP"||msgInfo.sendId == this.mine.id){
				return
			}
			let items = [];
			items.push({
				key: 'at-user',
				name: '@ TA',
			});
			if(UserMember?.type!=1 &&(this.isAdmin&&!toadmin ||this.isLeader)){
				items.push({
					key: 'remove-group',
					name: this.$t('comm.removeGroup'),
				});
				let isjy = this.group.groupUserMute.findIndex(jyuser=>jyuser==msgInfo.sendId)>=0?true:false
				items.push({
					key: 'jy-user',
					name: isjy?this.$t('comm.setclearbb'):this.$t('comm.setnobb'),
					color:"#FF4D4F"
				});
			}
			this.rightMenu.msgInfo = msgInfo
			this.rightMenu.Items = items
			this.rightMenu.pos = {
				x: e.x,
				y: e.y
			};
			this.rightMenu.show = "true"
		},
		//消息右键
		onShowMenu({e,msgInfo}){
			if(this.chat.groupType == '2'){
				return
			}
			if(msgInfo.type == this.$enums.MESSAGE_TYPE.RED_WRAP){
				return
			}
			let items = [];
			items.push({
				key: 'Reply',
				name: this.$t('comm.reply'),
			});
			if(msgInfo.type == this.$enums.MESSAGE_TYPE.TEXT){
				items.push({
					key: 'COYP',
					name: this.$t('comm.copy'),
				});
			}
			//this.group.groupTop为空或0可以置顶
			if(this.isAdmin||!this.group.groupTop){
				let istop = this.Topmsg.findIndex(tmsg=>tmsg.id==msgInfo.id)>=0
				if(!istop){
					items.push({
						key: 'TOPMsg',
						name: this.$t('comm.top'),
					});
				}else{
					items.push({
						key: 'UNTOPMsg',
						name: this.$t('comm.CancelTop'),
					});
				}
			}
			if (msgInfo.type == this.$enums.MESSAGE_TYPE.FILE) {
				items.push({
					key: 'DOWNLOAD',
					name: this.$t('comm.download'),
				});
			}
			items.push({
				key: 'Transmit',
				name: this.$t('comm.transmit'),
			});
			if(!this.ismultiple){
				items.push({
					key: 'MULTIPLE',
					name: this.$t('comm.mutilple'),
				});
			}
			items.push({
				key: 'DELETE',
				name: this.$t('comm.delete'),
				color:'#FF4D4F'
			});
			this.rightMenu.msgInfo = msgInfo
			this.rightMenu.Items = items
			this.rightMenu.pos = {
				x: e.x,
				y: e.y
			};
			this.rightMenu.show = "true"
		},
		onSelectMenu(item){
			let msgInfo = this.rightMenu.msgInfo
			this.rightMenu.show = false;
			if(item.key=="Reply"){//回复
				this.replymsg = msgInfo
				this.transmitmsg = []
			}if(item.key=="COYP"){//复制
				this.writeTextCOYP(msgInfo.content)
			}else if(item.key=="Transmit"){//转发
				this.$refs.transdialog.open([msgInfo])
			}else if(item.key=="DELETE"){//删除
				if (this.chat.type == "GROUP") {
					this.rightMenu.Items = [
						{key: 'del_onlyme',color:"#FF4D4F",name:this.$t('comm.del_onlyme')},
					]
					if(this.isAdmin||msgInfo.selfSend){
						this.rightMenu.Items.push({key: 'del_group_infom',color:"#FF4D4F",name:this.$t('comm.del_group_infom')})
					}
				}else{
					this.rightMenu.Items = [ 
						{key: 'del_onlyme',color:"#FF4D4F",name:this.$t('comm.del_onlyme')},
						{key: 'del_friend_infom',color:"#FF4D4F",name:this.$t('comm.del_friend_infom')},
					]
				}
				this.rightMenu.show = true;
			}else if(item.key=="del_onlyme"){
				this.onDeleteMessage([msgInfo],true)
			}else if(item.key=="del_friend_infom"||item.key=="del_group_infom"){
				this.onDeleteMessage([msgInfo])
			}else if(item.key=="del_multi_onlyme"){//批量删除信息前台缓存消息
				this.onDeleteMessage(this.multipledata,false)
			}else if(item.key=="del_multi_infom"){//批量删除双向删除消息
				let existOhterMsg = false
				if(!this.isAdmin && this.chat.type == "GROUP"){//群里非自己消息不允许删除(非群主)
					for (var i = 0; i < this.multipledata.length; i++) {
						if(!this.multipledata[i].selfSend){
							existOhterMsg = true
						}
					}
				}
				if(existOhterMsg){
					this.$message.error(this.$t('comm.othermsgdelinfo'))
					return
				}
				this.onDeleteMessage(this.multipledata)
			}else if(item.key=="TOPMsg"){
				if (this.chat.type == "GROUP") {
					this.rightMenu.Items = [
						{key: 'TOPMsg_group',name:this.$t('comm.TOPMsg_group')},
						{key: 'TOPMsg_group_infom',name:this.$t('comm.TOPMsg_group_infom')},
					]
				}else{
					this.rightMenu.Items = [
						{key: 'TOPMsg_friend',name: this.$t('comm.TOPMsg_friend')},
						{key: 'TOPMsg_friend_infom',name:this.$t('comm.TOPMsg_friend_infom')},
					]
				}
				this.rightMenu.show = true;
			}else if(item.key=="TOPMsg_group"){//群置顶
				this.setmsgtop(msgInfo,true,false)
			}else if(item.key=="TOPMsg_group_infom"){//群置顶并通知
				this.setmsgtop(msgInfo,true,true)
			}else if(item.key=="TOPMsg_friend"){//好友单项置顶
				this.$store.commit("topmsg",{msgInfo:msgInfo});
			}else if(item.key=="TOPMsg_friend_infom"){//好友双向置顶
				this.setmsgtop(msgInfo,true)
			}else if(item.key=="UNTOPMsg"){
				let topmsg = this.Topmsg.find(tmsg=>tmsg.id==msgInfo.id)
				this.canceltop(topmsg)
			}else if(item.key=="MULTIPLE"){
				this.replymsg =null
				this.replymsg = false
				this.more_bar =false
				this.ismultiple = true
				this.multipledata = []
				this.checkclick({check:true,msgInfo:msgInfo})
				this.defcheckmsgid = msgInfo.id
			}else if(item.key=="transMsg"){
				this.transMsg(msgInfo)
			}else if(item.key=="at-user"){//at好友
				let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
				this.$refs.editBox.focus()
				this.$refs.editBox.onAtSelect(member,true)
			}else if(item.key=="remove-group"){//移出本群
				this.tickUserGroup(msgInfo.sendId)
			}else if(item.key=="jy-user"){//禁言
				this.nospack(msgInfo.sendId)
			}
		},
		tickUserGroup(userid){
			if(this.group.id){
				let member = this.groupMembers.find((m) => m.userId == userid);
				this.$confirm(`是否将用户${member.aliasName}移出群聊?`, '移出群聊', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `im/imGroup/kick/${this.group.id}?userId=${userid}`,
						method: "delete",
					}).then((res)=>{
						let member = this.groupMembers.find((m) => m.userId == userid);
						if(member){
							member.quit =true
						}
						this.$message.success(this.$t('comm.TickGroupinfo',[member.aliasName]));
					})
				})
			}
		},
		nospack(userid){
			let member = this.groupMembers.find((m) => m.userId == userid);
			if(member&&this.group){
				let index = this.group.groupUserMute.findIndex(jyuser=>jyuser==userid)
				let isjy = index>=0?0:1
				this.$http({
					url: "im/groupMember/modify",
					method: "PUT",
					data: {
						groupId:this.group.id,
						userId:member.userId,
						userMute:isjy,
					}
				}).then((data)=>{
					if(isjy){
						this.$message.success(this.$t('comm.TickGroupinfo',[member.userNobb_info]));
						this.group.groupUserMute.push(userid)
					}else{
						this.$message.success(this.$t('comm.TickGroupinfo',[member.userbb_info]));
						this.group.groupUserMute.splice(userid)
					}
				})
			}
		},
		//复制文本到剪贴板
		writeTextCOYP(text){
			if(navigator.clipboard){
				navigator.clipboard.writeText(text).then(()=>{
					this.$message.success(this.$t('comm.copysuc'))
				});
			}else{
				const textArea = document.createElement('textarea');
				textArea.value = text;
				// 防止元素显示在屏幕上
				textArea.style.position = 'fixed';
				textArea.style.top = '0';
				textArea.style.left = '0';
				textArea.style.width = '2em';
				textArea.style.height = '2em';
				textArea.style.padding = '0';
				textArea.style.border = 'none';
				textArea.style.outline = 'none';
				textArea.style.boxShadow = 'none';
				textArea.style.background = 'transparent';
				document.body.appendChild(textArea);
				textArea.select();
				try {
					document.execCommand('copy');
					this.$message.success(this.$t('comm.copysuc'))
				}catch(err){
					//conson.log(err)
				}
				document.body.removeChild(textArea);
			}
		},
		//更新dialog数据
		updatmsgdialog(){
			if(this.$refs.msgdialog.dialogVisible){
				this.$refs.msgdialog.open(this.Topmsg)
			}
		},
		canceltop(msgInfo){
			if(msgInfo.mesTop&&this.isAdmin){//是否双向置顶
				this.setmsgtop(msgInfo,false)
			}else{
				this.$store.commit("cleantopmsg",msgInfo);
			}
		},
		onDeleteMessage(msgInfos,onlyme){
			let ids = msgInfos.map(msg=>{return msg.id})
			let t = this
			if(onlyme){
				let param = {
					idRemore:ids,
					type:1,
					empty:0
				}
				if(this.chat.type == "GROUP"){
					param.type = 2
					param.groupId = this.chat.targetId
				}else{
					param.friendId = this.friend.id
				}
				this.$http({
					url: `/im/groupMessage/addSingle`,
					method: 'POST',
					data:param
				}).then(() => {
					//删除本地
					msgInfos.forEach(msgInfo=>{
						this.$store.commit("deleteMessage", msgInfo);
					})
					this.$message.success(this.$t('comm.delsuccess'))
					this.ismultiple =false
				})
			}else{
				let msgInfo = msgInfos[0]
				//双向删除
				let url = ""
				if(this.chat.type =="GROUP"){
					url = `/im/groupMessage/recall`
				}else{
					url = `/im/privateMessage/recall`
				}
				this.$http({
					url: url,
					method: 'DELETE',
					data:{ids:ids}
				}).then(() => {
					msgInfos.forEach(msgInfo=>{
						this.$store.commit("deleteMessage", msgInfo);
					})
					this.$message.success(this.$t('comm.delsuccess'))
					this.ismultiple =false
				})
			}
		},
		setmsgtop(msginfo,mesTop,infom){
			let url =""
			let param = {}
			if (this.chat.type == "GROUP") {
				url = `/im//groupMessage/top`
				param={
					id:msginfo.id,
					mesTop:mesTop?1:0, 
					groupId:this.group.id,
					inform:infom?1:0,
				}
			}else{
				url = `/im/privateMessage/top`
				param={
					id:msginfo.id,
					mesTop:mesTop?1:0, 
					recvId:this.friend.id
				}
			}
			this.$http({
				url: url,
				method: 'PUT',
				data:param
			}).then((res) => {});
		},
		loadReaded(fId) {
			this.$http({
				url: `/im/privateMessage/maxReadedId?friendId=${fId}`,
				method: 'get'
			}).then((id) => {
				this.$store.commit("readedMessage", {
					friendId: fId,
					maxId: id
				});
			});
		},
		//重新加载数据
		reload(onlyinfo){
			if(this.chat.type == 'GROUP'){
				this.loadGroup(this.group.id,onlyinfo)
			}else{
				this.loadFriend(this.friend.id)
			}
		},
		loadGroup(groupId,onlyinfo){
			this.$http({
				url: `/im/imGroup/find/${groupId}`,
				method: 'get'
			}).then((group) => {
				this.group = group;
				this.$store.commit("updateChatFromGroup", group);
				this.$store.commit("updateGroup", group);
				//赋值Store里的group值，可以监听Store里的group的变化
				let newgroup = this.groupStore.groups.find(gs=>gs.id==group.id)
				if(newgroup){
					this.group = newgroup
				}
			}).finally(()=>{
				this.loading  = false
			});
			if(!onlyinfo){
				this.$http({
					url: `/im/imGroup/members/${groupId}`,
					method: 'get'
				}).then((groupMembers) => {
					this.groupMembers = groupMembers;
				});
			}
		},
		loadFriend(friendId) {
			// 获取对方最新信息
			this.$http({
				url: `/im/imUser/find/${friendId}`,
				method: 'get'
			}).then((friend) => {
				this.friend = friend;
				this.$store.commit("updateChatFromFriend", friend);
				this.$store.commit("updateFriend", friend);
			}).finally(()=>{
				this.loading  = false
			})
		},
		showName(msgInfo) {
			if (this.chat.type == 'GROUP' ) {
				if(this.chat.groupType=='2'){//频道显示群头像
					return ""
				}
				let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
				return (member ? member.aliasName : "")||'';
			} else {
				return (msgInfo.sendId == this.mine.id ? this.mine.nickName : this.chat.showName)||""
			}
		},
		showName2(msgInfo) {
			if (this.chat.type == 'GROUP' ) {
				if(this.chat.groupType=='2'){//频道显示群头像
					return ""
				}
				let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
				return (member ? member.nickName : "")||'';
			} else {
				return (msgInfo.sendId == this.mine.id ? this.mine.nickName : this.friend.nickName)||""
			}
		},
		headImage(msgInfo) {
			if (this.chat.type == 'GROUP') {
				if(this.chat.groupType == '2'){
					return this.group.headImage||""
				}
				let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
				return member ? member.headImage : "";
			} else {
				return msgInfo.sendId == this.mine.id ? this.mine.headImage : this.chat.headImage
			}
		},
		headOnline(msgInfo){
			if (this.chat.type == 'GROUP') {
				if(this.chat.groupType=='2'){
					return false
				}
				let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
				return member ? member.online : false;
			} else {
				return this.friend.online || msgInfo.selfSend?true:false
			}
		},
		resetEditor() {
			this.$refs.editBox?.resetEditor();
		},
		scrollToBottom() {
			this.$refs.msgscrollview.scrollToBottom()
		},
		onQuit() {
			this.$confirm(this.$t('comm.exitChannelmsg'), this.$t('comm.confirmexit'), {
				confirmButtonText: this.$t('comm.confirm'),
				cancelButtonText:  this.$t('comm.cancel'),
				type: 'warning'
			}).then(() => {
				this.$http({
					url: `im/imGroup/quit/${this.group.id}`,
					method: 'delete'
				}).then(() => {
					this.$store.commit("removeGroup", this.group.id);
					this.$store.commit("activechGroup", -1);
					this.$store.commit("activeChat", -1);
					this.$store.commit("removeGroupChat", this.group.id);
				});
			})
		},
		issameday(date1,date2){
			let datenow = date1.getFullYear()+'-'+(date1.getMonth()+1)+'-'+date1.getDate()
			let date = date2.getFullYear()+'-'+(date2.getMonth()+1)+'-'+date2.getDate()
			return datenow == date
		},
		loadMessage(){
			if(!this.$refs.msgscrollview){
				this.$nextTick(()=>{
					this.loadMessage()
				})
				return 
			}
			let refMsgscoll = this.$refs.msgscrollview
			refMsgscoll.init()
			let targetId = this.chat.targetId
			if(this.chat.messages.length>20){
				this.$nextTick(()=>{
					refMsgscoll.initsuccess()
				})
				return
			}
			if (this.chat.type == "GROUP") {
				refMsgscoll.QueryGroupMsg(targetId,-1,0,(msgs)=>{
					msgs.forEach(msginfo => {
						msginfo.selfSend = msginfo.sendId == this.mine.id;
						this.$store.commit("insertBeforeMessage",msginfo)
					});
					this.$nextTick(()=>{
						refMsgscoll.initsuccess()
					})
				})
			}else{
				refMsgscoll.QueryPrivateMsg(targetId,-1,0,(msgs)=>{
					msgs.forEach(msginfo => {
						msginfo.selfSend = msginfo.sendId == this.mine.id;
						this.$store.commit("insertBeforeMessage",msginfo)
					});
					this.$nextTick(()=>{
						refMsgscoll.initsuccess()
					})
				})
			}
		},
	},
	computed: {
		addFriendable(){
			if (this.chat.type == 'GROUP'){
				if(this.chat.groupType!='2'&&(this.isAdmin||this.group.canFriend !=1)){
					return true
				}
				return false
			}else {
				return true
			}
		},
		//今日是否签到
		isqd_today(){
			if(this.chat.lastqdtime){
				return this.issameday(new Date(),new Date(this.chat.lastqdtime))
			}
			return false
		},
		//是否禁言
		nospeak(){
			if(this.group.groupMute ==1&&!this.isAdmin){
				return true
			}
			if(this.group.groupUserMute){
				if(this.group.groupUserMute.findIndex(id=> id==this.mine.id)>=0){
					return true
				}
			}
			return false
		},
		Topmsg(){
			let key = (this.chat.type == "GROUP"?"G":'P')+this.chat.targetId
			return (this.$store.state.chatStore.topmessages[key]||[]).filter(tmsg => !tmsg.isdelete)
		},
		mine() {
			return this.$store.state.userStore.userInfo;
		},
		friendStore() {
			return this.$store.state.friendStore;
		},
      	groupStore() {
				return this.$store.state.groupStore;
		},
		isLeader(){//是否群主
			return this.group.ownerId == this.mine.id
		},
		isAdmin(){//是否管理员
			let groupmine =  this.groupMembers.find(m => m.userId  == this.mine.id);
			return  groupmine?.memberType ==1 || groupmine?.memberType ==2
		},
		title() {
			let title = this.chat.showName;
			if (this.chat.type == "GROUP") {
				let size = this.groupMembers.filter(m => !m.quit).length;
				title += `(${size})`;
			}
			return title;
		},
		messageAction() {
			if (this.chat.type == "GROUP" ) {
				return "/im/groupMessage/send"
			}else{
				return `/im/privateMessage/send`;
			}
		},
		unreadCount() {
			return this.chat.unreadCount;
		},
	},
	watch: {
		'Topmsg.length':function(){
			this.updatmsgdialog(this.Topmsg)
			this.TopMsgIndex = this.Topmsg.length-1
		},
		chat: {
			handler(newChat, oldChat) {
				if (newChat.targetId > 0 && (!oldChat || newChat.targetId != oldChat.targetId ||newChat.type != oldChat.type )) {
					this.friend ={}
					this.group = {}
					this.groupMembers=[]
					this.ismultiple =false
					this.showlightrow=false
					this.firsttop=true
					this.timer=null
					this.more_bar = false
					this.replymsg = null
					this.noscrollevent=false
					this.loading  = true
					this.$store.dispatch("queryTopMsg",this.chat);
					if (this.chat.type == "GROUP") {
						this.loadGroup(this.chat.targetId);
					} else {
						this.loadFriend(this.chat.targetId);
						// 加载已读状态
						this.loadReaded(this.chat.targetId)
					}
					// 滚到底部
					this.loadMessage()
					this.showSide = false;
					// 重置输入框
					this.resetEditor();
					// 复位回执消息
					this.isReceipt = false;
					this.showVoice = false;
					this.$refs.signin&&this.$refs.signin.close()
					this.inputTransText =""
				}
			},
			immediate: true
		},
	}
}
</script>

<style scoped lang="scss">
.el-calendar{
	::v-deep{
		.el-calendar-day{
			height:auto;
			padding:0px;
		}
		.el-calendar-table:not(.is-range) td.prev .el-calendar-day{
			display: none;
		}
		.el-calendar-table:not(.is-range) td.next .el-calendar-day{
			display: none;
		}
	}
	.date-item-qd{
		height: 35px;
		width: 100%;
		display: flex;
		span{
			margin: auto;
		}
	}
}
.chat-box {
	position: relative;
	width: 100%;
	display: flex;
	min-width: 820px;
	background-color: #202020;
	.chat-container{
		position: relative;
		flex: 1;
		background: #fff;
		border-radius: 0 40px 40px 0;
		overflow: hidden;
		min-width: 630px;
		.char-header-info{
			display: flex;
			flex-direction: column;
			text-align: left;
		}
		.char-lastonline{
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #666666;
			margin-top: 5px;
		}
	}
	.right-side{
		width: 332px;
		min-width: 255px;
		margin-left: 8px;
		display: flex;
		flex-direction: column;
		text-align: center;
		.right-side-top{
			overflow: hidden;
			flex: 1;
			height: 0;
			border-radius: 40px;
			background-color: #FFFFFF;
			padding-top: 16px;
		}
		.right-side-bottom{
			overflow: hidden;
			flex: 1;
			height: 0;
			margin-top: 8px;
			border-radius: 40px;
			background-color: #DBDCFF;
			padding-top: 16px;
		}
	}
	.el-header {
		padding: 5px;
		background-color: white;
		font-size: 20px;
		font-weight: 600;
		display: flex;
		align-items: center;
		span{
			font-weight: 500;
			font-size: 18px;
			line-height: 22px;
			color: #333333;
		}
		.avatar-image{
			margin-left: 11px;
			margin-right: 16px;
		}
		.btn-side {
			position: absolute;
			right: 10px;
			width: 40px;
			display: flex;
			color: #666666;
			cursor: pointer;
			.icon{
				margin: auto;
				width: 22px;
				height: 22px;
				&:hover {
					width: 24px;
					height: 24px;
				}
			}
			.is-selected {
				color: #1989FA;
			}
		}
		.transsetting{
			padding: 0 10px;
			position: absolute;
			top: 32 px;
			right: 50%;
			transform: translateX(50%);
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			line-height: 20px;
			background-color: #FFF;
			a{
				font-size: 16px;
			}
			.translang{
				color: #7678ED;
				text-decoration: underline;
			}
			.transauto{
				color: #FF4D4F;
				text-decoration: underline;
				&.isstatrfy{
					color: #7678ED;
				}
			}
		}
		.transsetting{
			transition:all 0.5s;
		}
		@media (max-width: 1400px) {
			.transsetting,.transsetting a{
				right:50px;
				transform:none;
				font-size: 14px;
			}
		}
		@media (min-width: 1400px) and (max-width: 1600px) {
			.transsetting {
				transform:none;
				right:80px;
			}
		}
	}
	.im-chat-topmsg{
		border-radius: 12px;
		background-color:#DBDCFF;
		border: none;
		position: relative;
		.msgtop-text{
			display: flex;
			flex-direction: column;
			margin-left:30px;
			text-align: left;
			width: 0;
			flex: 1;
			.msgtop-title{
				font-weight: 400;
				font-size: 14px;
				color: #7678ED;
				line-height: 20px;

			}
			.msgtop-contents{
				height: 17px;
				overflow: hidden;
				font-weight: 400;
				font-size: 12px;
				color: #626569;
				line-height: 17px;
				.msg-trans{
					transition:all 1s;
					.msgtop-value{
						height: 17px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						max-width: 75%;
					}
				}
			}
			.msgtop-index{
				position: absolute;
				background-color: rgba(118,120,237,0.5);
				width: 2px;
				top: 6px;
				bottom: 6px;
				left: 20px;
				div{
					min-height: 6px;
					background-color: #7678ED;
					transition:all 1s;
				}
			}
		}
		.msgtop-icon{
			position: absolute;
			right: 20px;
			width: 24px;
			height: 24px;
			&.top-close{
				width: 20px;
				height: 20px;
			}
		}

	}

	.im-chat-main {
		padding: 0;
		overflow-x: hidden;
		.im-chat-box {
			height: 100%;
			ul {
				padding: 0;
				li {
					list-style-type: none;
				}
			}
			::v-deep{
				.online{
					width: 8px;
					height: 8px;
				}
				.Offline{
					width: 8px;
					height: 8px;
				}
				.is-horizontal{
					display: none;
				}

			}
		}
	}

	.im-chat-footer {
		display: flex;
		flex-direction: column;
		padding: 0;
		.multiple-view{
			flex: 1;
			background-color: #EEEEF8;
			margin: 4px 24px 20px 0px;
			border-radius: 12px;
			display: flex;
			justify-content:space-between;
			align-items: center;
			font-size: 14px;
			color: #626569;
			.multitem{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: #fff;
				display: flex;
				&:hover{
					background: #D9D9D9;
				}
				.iconfont{
					font-size: 16px;
					margin: auto;
					color: #7678ED;
				}
			}
			.close{
				height: 25px;
				width: 25px;
				color: #6A78F0;
				font-size: 16px;
				line-height: 27px;
				margin-right: 30px;
				&:hover{
					background: #D9D9D9;
					border-radius: 50%;
				}
			}
		}
		.nospeak-view{
			flex: 1;
			text-align: center;
			background-color: #EEEEF8;
			font-size: 16px;
			color: #666;
			padding-top: 30px;
			margin: 4px 24px 20px 0px;
			border-radius: 12px;

		}
		.chat-reply-msg{
			margin: 4px 24px 0 16px;
			display: flex;
			height: 48px;
			display: flex;
			background-color: #EEEEF8;
			border-radius: 12px;
			align-items: center;
			text-align: left;
			margin-right: 24px;
			.reply-info{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				overflow: hidden;
				flex: 1;
				margin-left: 20px;
				border-left: #7678ED 2px solid;
				margin: 6px 20px 6px 20px;
				.reply-user{
					margin-left: 8px;
					font-weight: 400;
					font-size: 14px;
					color: #7678ED;
					line-height: 20px;
				}
				.reply-content{
					margin-left: 8px;
					font-weight: 400;
					font-size: 12px;
					color: #626569;
					line-height: 17px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 80%;
				}
			}
			.reply-close{
				cursor: pointer;
				margin-right: 16px;
				font-size: 16px;
				color: #7678ED;
			}
		}
		.chat-trans-msg{
			> span {
				cursor: pointer;
				color: #1485EE;
				margin-left: 15px;
				font-size: 14px;
				padding: 5px 5px;
				border-radius: 2px;
				&:hover{
					background-color: #eee;
				}
			}
			height: 48px;
			background: #F5F5F5;
			display: flex;
			align-items: center;
			.chat-trans-lang{
				line-height: 32px;
				color: #1485EE;
				font-size: 14px;
				border-radius: 4px;
				font-weight: 400;
				padding: 4px 8px;
				border: 1px solid #1485EE;

			}
			.el-input {
				flex: 1;
				margin:0 10px;
				height: 32px;
				::v-deep{
					input{
						border: none;
						color: #666666;
						background-color: #fff;
						height: 32px;
						padding-right: 70px;
					}
				}
				// &.is-disabled ::v-deep input{
				// 	background-color: #eee;
				// }
			}
		}
		.toolbar-bt{
			padding: 5px;
			margin: 3px;
			border-radius:5px;
			&:hover{
				background-color: #a5a5a546;
			}
			height: 30px;
		}
		.char-showmore-bar{
			margin: 4px 24px 0 16px;
			background-color: #EEEEF8;
			border-radius: 12px;
			height:44px;
			display: flex;
			align-items: center;
			padding: 0 8px;
			.iconfont{
				font-size: 20px;
				color: #7678ED;
			}
			.icon-liaotianjilu1{
				&.chat-tool-active {
					background-color: #a5a5a59d;
				}
			}
			.toolbar-bt{
				margin: 3px 11px;
			}
		}
		.send-content-area {
			margin: 4px 24px 20px 16px;
			padding: 0 8px;
			flex: 1;
			position: relative;
			display: flex;
			height: 0px;
			background-color: #EEEEF8;
			align-items: center;
			border-radius: 12px;
			.active-item{
				margin: 0 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 12px;
				color: #626569;
				&:first-child{
					margin-left: auto;
				}
				&:last-child{
					margin-right: auto;
				}
				.tool-icon{
					width: 48px;
					height: 48px;
					background-color: #FFFFFF;
					border-radius: 8px;
					display:flex;
					margin-bottom: 4px;
					img{
						margin: auto;
						width: 24px;
						height: 24px;
					}
					&:hover{
						background-color: #f9f9f9;
					}
				}
			}
			.chat-voice{
				position: absolute;
				left: 50%;
				transform: translateX(-20px);
			}
			.record{
				font-size: 14px;
				color: #7678ED;
			}
			.guanbi-right{
				margin-right:8px ;
				margin-left: auto;
			}
			.send-state{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ffffff88;
				font-size: 20px;
				padding-top: 30px;
				color: #333333;
			}
			.iconfont{
				font-size: 20px;
				color: #7678ED;
			}
			.chat-input-msg{
				height: 100%;
				width: 100%;
				padding: 5px;
				box-sizing: border-box;
				resize: none;
			}
			.input-message{
				margin-left: 8px;
				max-height:70px;
				flex: 1;
				width: 0;
			}
			.icon-fasong{
				margin:0 8px;
				font-size: 32px;
				color: #7678ED;
				&.disClick{
					color: #B2B3F3;
				}
			}
			.send-btn-area {
				padding: 10px;
				position: absolute;
				bottom: 0;
				right: 0;
				.el-button{
					margin-left: 10px;
				}
			}
		}

	}

	.chat-group-side-box {
		animation: rtl-drawer-in .3s 1ms;
		background: #FFFFFF;
		box-shadow: -2px 0px 8px 0px rgba(0,0,0,0.1);
		position: relative;
	}
}</style>
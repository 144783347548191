<template>
    <div class="chat-member">
        <div class="title">
            <span class="gropu-num">{{$t('comm.groupuser')+'('+gMembers.length+')'}}</span>
            <span class="iconfont serarch-icon icon-sousuo" @click="$refs.serarch.focus()"></span>
            <el-input ref="serarch" @blur="isFinitefocus=false" @focus="isFinitefocus=true" :class="{'zk-serarch':searchText.trim()||isFinitefocus?true:false}" v-model="searchText"></el-input>
            <span v-if="isAdmin" class="iconfont icon-tianjia"  @click="showAddGroupMember=true"></span>
        </div>
        <virtual-scroll  class="virtual-scroll" :source-data-list="Members" :one-data-height="64" >
            <template v-slot:default="{row}">
                <div class="row-item" :key="row.userId" @contextmenu.prevent="showimageMenu($event,row.userId)">
                    <head-image :id="row.userId" :name="row.aliasName" 
                        :url="row.headImage" :size="48"  :canFriend="canFriend&&row.type !='1'||isAdmin" 
                        :online="row.online&&isAdmin" :showunline ="isAdmin">
                    </head-image>
                    <div class="user-name">
                        <span>{{row.aliasName}}</span>
                        <div class=user-id>
                            <span v-if="isAdmin">ID: {{row.userId}} </span> 
                            <span v-if="isjy(row.userId)" :title="$t('comm.usernospeak')" class="icon el-icon-remove">{{$t('comm.usernospeak')}}</span>
                        </div>
                    </div>
                    <span v-if="row.memberType ==1" class="user-qt">{{$t('comm.GroupLeader')}}</span>
                    <span v-if="row.memberType ==2" class="user-qt">管理员</span>
                </div>
            </template>
        </virtual-scroll>
        <add-group-member 
			:visible="showAddGroupMember" 
			:groupId="group.id" 
			:members="groupMembers" 
			@reload="$emit('reload')" 
			@close="showAddGroupMember=false"/>
    </div>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";
import VirtualScroll from "../common/VirtualScroll.vue";
import AddGroupMember from '../group/AddGroupMember.vue';

export default {
    name: "chat-member",
    data(){
        return{
            showAddGroupMember:false,
            searchText :"",
            isFinitefocus:false//查询焦点
        }
    },
    components: {
        HeadImage, 
        VirtualScroll,
        AddGroupMember
    },
    props:['group','groupMembers','canFriend'], 
    watch:{
        group(){
            this.searchText=""
        }
    },
    methods:{
        isjy(userId){
			if (this.group.groupUserMute) {
				let inx = this.group.groupUserMute.findIndex((mid) => mid == userId);
                if(inx>=0){
					return true
				}
			}
			return false
		},
        addFriendable(userId){
            let member = this.groupMembers.find((m) => m.userId == userId);
            if(this.chat.groupType!='2'&&(!member||member.type !='1')&&(member.memberType ==1||member.memberType ==2||this.group.canFriend !=1)){
                return true
            }
            return false
		},
        showimageMenu(e,userId){
            this.$emit("showimageMenu",{e:e,msgInfo:{sendId:userId }})
        }
    },
    computed: {
        gMembers(){
            return this.groupMembers.filter((gm)=>{
                return !gm.quit 
            })
        },
        isLeader(){//是否群主
			return this.group.ownerId == this.mine.id
		},
        isAdmin(){//是否管理员
			let groupmine =  this.groupMembers.find(m => m.userId  == this.mine.id);
			return  groupmine?.memberType ==1 || groupmine?.memberType ==2
		},
        mine() {
			return this.$store.state.userStore.userInfo;
		},
        Members(){
            if(!this.searchText.trim()){
                return this.gMembers
            }else{
                return this.gMembers.filter((gm)=>{
                    return gm.aliasName.includes(this.searchText.trim())||this.isAdmin&&(gm.userId+'').includes(this.searchText.trim())
                })
            }
        }
    }
}

</script>

<style scoped lang="scss">
.chat-member{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title{
        text-align: left;
        line-height: 56px;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;
        padding: 0px 16px 0px 16px;
        .sousuo{
            margin-left: auto;
            margin-right: 16px;
        }
        .iconfont{
           cursor: pointer;
           font-size: 20px;
           color: #5B5C95;
        }
    }
    .serarch-icon{
        margin-left: auto;
    }
    .gropu-num{
        margin-right: 16px;
        font-size: 16px;
        font-weight: 600;
    }
    .el-input{
        flex: 0;
        transition: all 0.4s;
        width: 0px;
        line-height: 20px;
        margin:8px;
        ::v-deep{
            input{
                font-size: 16px;
                color: #626569;
                background-color: #FFFFFF00;
                border: none;
                padding: 0px;
            }
        } 
        &.zk-serarch{
            flex: 1;
        }
    }

}
.virtual-scroll{
    flex: 1;
    .row-item{
        height: 64px;
        display: flex;
        align-items: center;
        margin: 0px 16px;
        .user-name{
            margin: 0px 16px;
            text-align: left;
            flex: 1;
            width: 0;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
            .el-icon-remove{
                margin-top: 4px;
                font-size: 12px;
                color: #ca3b3b;
            }
            span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .user-id{
                margin-top: 4px;
                font-size: 12px;
                color: #626569;
                line-height: 16px;
            }
        }
        .user-qt{
            font-size: 12px;
            color: #FFFFFF;
            line-height: 16px;
            padding: 0 8px;
            background: #67C23A;
            border-radius: 4px;
        }
    } 
}
</style>
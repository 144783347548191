<template>
    <div class="calendar">
        <div class="header">
            <div class="iconfont icon-xiangyou1 icon-left" @click="addMonth(-1)"></div>
            <span class="month-year">{{year}}-{{day_zero(month)}}</span>
            <div class="iconfont icon-xiangyou1" @click="addMonth(1)"></div>
        </div>
        <div class="weekdays">
            <span class="weekdays-text" v-for="day in weekdays" :key="day">{{ day }}</span>
        </div>
        <div class="days">
            <div class="week" v-for="week in days">
                <div v-for="day in week" :key="day.day" class="item-day" @click="clickday(day)" :class="{'selectday':isselectdate(day.date),'today':day.current,'activeMonth':!day.isbeforeMonth&&!day.islastMonth }">
                    <slot :day="day">
                        <span class="item-day-centent">{{ day_zero(day.day)}}</span>
                    </slot>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            year:new Date().getFullYear(),
            month:new Date().getMonth() + 1,
            selday:new Date()
        };
    },
    computed: {
        weekdays(){
            return   [this.$t('date.Sunday'), this.$t('date.Monday'),this.$t('date.Tuesday'),this.$t('date.Wednesday'), this.$t('date.Thursday'), this.$t('date.Friday'), this.$t('date.Saturday')]
        },
        days() {
            let days = []
            let daysInMonth = new Date(this.year, this.month, 0).getDate();
            let firstDayOfWeek = new Date(this.year, this.month - 1, 1).getDay();

            let beforeDate = new Date(this.year, this.month - 1, 0).getDate() - firstDayOfWeek+1

            let currentDate = 1;
            let lastDate = 1;
            let nowdate = new Date()
            for (let i = 0; i < 6; i++) {
                days[i] = [];
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < firstDayOfWeek) {
                        let current = nowdate.getFullYear() == this.year &&nowdate.getMonth() ==this.month-2&&nowdate.getDate() == beforeDate
                        days[i][j] = {day:beforeDate,date:new Date(this.year,this.month-2,beforeDate),current: current,isbeforeMonth:true};
                        beforeDate++
                    } else if (currentDate <= daysInMonth) {
                        let current = nowdate.getFullYear() == this.year &&nowdate.getMonth() ==this.month-1&&nowdate.getDate() == currentDate
                        days[i][j] = {day:currentDate,date:new Date(this.year,this.month-1,currentDate),current:current };
                        currentDate++;
                    } else {
                        let current = nowdate.getFullYear() == this.year &&nowdate.getMonth() ==this.month-1&&nowdate.getDate() == lastDate
                        days[i][j] = {day:lastDate,date:new Date(this.year,this.month,lastDate),current: current,islastMonth:true};
                        lastDate++
                    }
                }
            }
            return days;
        },
    },
    methods: {
        //月份 天补0
        day_zero(day){
            let str = day +''
            return (str.length==1?'0':'') +str
        },
        addMonth(mon){
            let tempdate = new Date(this.year,this.month+mon-1, 1)
            this.year = tempdate.getFullYear()
            this.month = tempdate.getMonth() + 1
            this.$emit('monthchange',tempdate)
        },
        selnowdate(){
            this.clickday({date:new Date()})
        },
        clickday(day){
            this.year = day.date.getFullYear()
            this.month = day.date.getMonth()+1 
            this.selday = day.date
            this.$emit('clickday',day)
        },
        isselectdate(seldate){
            return seldate.getFullYear() == this.selday.getFullYear() &&seldate.getMonth() == this.selday.getMonth()  && seldate.getDate() == this.selday.getDate() 
        }
    },
  };
  </script>
  
  <style scoped lang="scss">
  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        font-size: 16px;
        color: #333333;
        position: relative;
        .iconfont {
            cursor: pointer;
            font-size: 16px;
            margin: 16px;
            color: #999999;
        }
        .icon-left{
            transform: rotate(180deg);
        }
        .nowdate{
            cursor: pointer;
            position: absolute;
            right: -1px;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            background-color: #f1f1f1;
            text-align: right;
            padding: 0 5px 0 10px;
            border-radius: 10px 0  0 10px;
        }
    }
    .weekdays {
        display: flex;
        width: 100%;
        font-size: 12px;
        color: #999999;
        line-height: 40px;
        .weekdays-text{
            flex: 1;
            width: 0;
            text-align:center;
        }
    }
    .days {
        padding-top: 10rx;
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 18px;
  
        .week{
            display: flex;
            align-items: center;
            justify-content: center;
            .item-day {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                width: 0;
                text-align: center;
                color: rgb(192, 192, 192);
                .item-day-centent{
                    height: 30px;
                    width: 30px;
                    margin: 5px;
                    line-height: 30px;
                    border-radius: 50%;
                }
            }
            .today .item-day-centent{
                background-color: #d4d4d4;
            }
            .activeMonth{
                color: #333333;
            }
            .selectday .item-day-centent{
                color:#DCDCF3;
                background-color: #7678ED;
            }
        }
        
    }
  }
  </style>
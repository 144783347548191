<template>
    <div class="chat-user-info">
        <div class="title">{{$t('comm.userinfo')}}</div>
        <el-scrollbar class="details-scrollbar">
            <div class="info-item">
                <head-image class="avatar-image" :name="friend.nickName" :size="48" :url="friend.headImage" :id="friend.id" :showunline ="true" :online ="friend.online"></head-image>
                <span>{{friend.friendRemark||friend.nickName}}</span>
            </div>
            <div class="info-item">
                <span class="item-name">{{$t('comm.nickname')}}</span>
                <span class="item-info">{{friend.nickName}}</span>
            </div>
            <div class="info-item">
                <span class="item-name">{{$t('comm.Remark')}}</span>
                <edit-string iconalign="right"  :value="friend.friendRemark" :min="0" :placeholder="friend.friendRemark||friend.nickName" :max="15" filed="remark" @editafter="editfriendRemark"/>
            </div>
            <div class="info-item">
                <span class="item-name signature">{{$t('comm.Pertags')}}</span>
                <span class="item-info">{{friend.signature||$t('comm.signtis')}}</span>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
	import EditString from "../common/EditString.vue";
    import HeadImage from "../common/HeadImage.vue";
	export default {
		name: "chat-user-info",
        components: {
			EditString,
            HeadImage, 
		},
        props:['friend'],
        methods:{
            editfriendRemark({value}) {
                value = value.trim()
                this.$http({
					url: "/im/imFriend/updateFriendById",
					method:'PUT',
					data:{
						friendId:this.friend.id,
						friendRemark:value
					}
				}).then((res) => {
					this.$emit('reload')
				})
            }
        }
    }
</script>

<style scoped lang="scss">

.chat-user-info{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title{
        text-align: left;
        line-height: 56px;
        font-weight: 600;
        font-size: 16px;
        padding: 0px 16px 0px 16px;
    }
}
.details-scrollbar{
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #333333;
    text-align: left;
    overflow-y: scroll;
    height: 100%;
    .info-item{
        display: flex;
        justify-content: space-between;
        padding: 0px 16px;
        align-items: center;
        min-height: 56px;
        .item-name{
            font-size: 16px;
            min-width: 100px;
        }
        .item-info{
            font-size: 16px;
            color: #626569;
            &.signature{
                align-self: flex-start;
            }
        }
        .edit-string{
            flex: 1;
            width: 0px;
            margin-left: 30px;
            font-size: 16px;
            color: #626569;
            ::v-deep{
                .content{
                    justify-content: end;
                }
            }
        }
    }
}
</style>
<template>
	<div class="chat-input-msg">
		<div contenteditable="true" ref="editBox" class="send-text-area" :class="{'receipt':isReceipt,'fixedheight':isfixedHeight}" ondrop="return false" ondragover="return false"
			:disabled="disabled" @paste.prevent="onEditorPaste"
			@compositionstart="onEditorCompositionStart" @compositionend="onEditorCompositionEnd"
			@input="onEditorInput" :placeholder="isReceipt ? '【'+$t('comm.Receipt')+'】' : (!isempty?'':$t('comm.sendPlaceholder'))" @blur="onEditBoxBlur()"
			@keydown.down="onKeyDown" @keydown.up="onKeyUp" @keydown="keydown">
		</div>
		<chat-at-box ref="atBox" :ownerId="groupownerId" :members="groupMembers" :search-text="atSearchText" @select="onAtSelect"></chat-at-box>
	
		<el-image-viewer 
			v-if="showViewer" 
			:initial-index="0"
            :hide-on-click-modal="true"
			:on-close="()=>{showViewer = false}" 
			:url-list="[yrurl]"/>
	</div>
</template>
<script>
import ChatAtBox from "./ChatAtBox.vue"
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
	name: "ChatInputMsg",
	components: {
		ChatAtBox,
		ElImageViewer
	},
    props: {
		disabled: {
			type: Boolean,
            default:true
		},
        isReceipt:{
            type: Boolean,
            default:false
        },
        groupMembers:{
            type: Array,
            default:function(){
				return []
			}
        },
        groupownerId:{}
	},
    data(){
        return {
            zhLock: false, // 解决中文输入法触发英文的情况
            atSearchText: "",
            focusNode: null, // 缓存光标所在节点
            focusOffset: null, // 缓存光标所在节点位置

			type:0,//消息类型  0纯文本  1单张图片  2图文/多张图片
            SendText:"",//纯文本
			imgUrlList:[],

			tempFild:{},//临时图片存储key:url val:File
			isempty:true,//输入是否为空
			showViewer:false,//是否显示预览
			yrurl:"",//预览零时变量
			isfixedHeight:false
        }
    },
	watch:{
		isempty:{
			handler(newSize, oldSize) {
				this.$emit("isempty",newSize)
			}
		},
	},
	mounted(){
		this.$refs.editBox.focus();
		let temptimefun = null;
		let t = this
		this.resizeObserver = new ResizeObserver(entries => {
            if(!temptimefun){
              temptimefun  = setTimeout( () => {
				t.setfixedHeight()
				temptimefun = null
              }, 500)
            }
          });
          this.resizeObserver.observe(this.$refs.editBox);
		this.onEditBoxBlur()
	},
	methods: {
		setfixedHeight(){
			this.isfixedHeight = this.$refs.editBox?.scrollHeight >61?true:false
		},
		focus(){
			this.$refs.editBox.focus();
		},
		resetEditor(){
			this.$nextTick(() => {
				if(this.$refs.editBox){
					this.isempty = true
					this.SendText =""
					this.imgUrlList = []
					this.tempFild = {}
					this.$refs.editBox.innerHTML = "";
					this.isfixedHeight= false
					this.$refs.editBox.focus();
				}
			});
		},
		createAtUserIds(){
			let ids = [];
			this.$refs.editBox.childNodes.forEach((node) => {
				if (node.nodeName == "SPAN") {
					ids.push(node.dataset.id);
				}
			})
			return ids;
		},
		closeRefBox(){
			this.$refs.atBox.close();
		},
		getImgFile(){
			return  this.imgUrlList.map((url)=>{
				return this.tempFild[url]
			})
		},
		getInputVal(){
			return {
				type:this.type,
				SendText:this.SendText,
				imgUrlList:this.imgUrlList,
				imgFileList:this.getImgFile()
			}
		},
        //粘贴
        onEditorPaste(e) {
			let txt = e.clipboardData.getData('Text')
			if (typeof (txt) == 'string') {
				let range = window.getSelection().getRangeAt(0)
				let textNode = document.createTextNode(txt);
				range.insertNode(textNode)
				range.collapse();
			}
			let items = (e.clipboardData || window.clipboardData).items
			if (items.length) {
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf('image') !== -1) {
						let file = items[i].getAsFile();
						let selection = window.getSelection()
						// 记录光标位置(点击emoji时)
						this.focusNode = selection.focusNode;
						this.focusOffset = selection.focusOffset;
						this.insertImg(file);
					}
				}
			}
			this.createSendText();
		},
        onEditorCompositionStart() {
			this.zhLock = true;
		},
        onEditorCompositionEnd(e) {
			this.zhLock = false;
			this.onEditorInput(e);
		},
        onEditorInput(e){
            //如果触发@
			if (this.groupMembers.length  && !this.zhLock) {
				if (e.data == '@') {
					// 打开选择弹窗
                    this.showAtBox(e);
				} else {
					let selection = window.getSelection()
					let range = selection.getRangeAt(0)
					this.focusNode = selection.focusNode;
					this.focusOffset = selection.focusOffset;
					// 截取@后面的名称作为过滤条件
					let stIdx = this.focusNode.textContent.lastIndexOf('@');
					this.atSearchText = this.focusNode.textContent.substring(stIdx + 1);
				}
			}
			this.createSendText();
        },
        //显示at弹出
        showAtBox(e) {
			this.atSearchText = "";
			let selection = window.getSelection()
			let range = selection.getRangeAt(0)
			// 记录光标所在位置
			this.focusNode = selection.focusNode;
			this.focusOffset = selection.focusOffset;
			// 光标所在坐标
        
			let pos = range.getBoundingClientRect();
			this.$refs.atBox.open({
				x: pos.x,
				y: pos.y
			})
		},
        onEditBoxBlur() {
			let selection = window.getSelection()
			// 记录光标位置(点击emoji时)
			this.focusNode = selection.focusNode;
			this.focusOffset = selection.focusOffset;
		},
        onKeyDown() {
			if (this.$refs.atBox.show) {
				this.$refs.atBox.moveDown()
			}
		},
        onKeyUp() {
			if (this.$refs.atBox.show) {
				this.$refs.atBox.moveUp()
			}
		},
        keydown(e){
			//Enter发送，Shift+Enter换行
			if(e.keyCode==13 && e.shiftKey){
				e.preventDefault();
				//已废弃，但大部分浏览器仍然支持
				document.execCommand('insertLineBreak')
			}else if(e.keyCode==13){
				e.preventDefault();
                if (this.$refs.atBox.show) {
					// 键盘操作不会自动修正焦点，需要手动修正,原因不详
					this.focusOffset += this.atSearchText.length;
					this.$refs.atBox.select();
				}else{
                    if(!this.isempty){
						this.$emit('sendEnter')
                    }
                }
			}
		},
        //sendText赋值文本信息
        createSendText() {
			let sendText =""
			let imgUrlList = []
			let lastishr = true
			this.$refs.editBox.childNodes.forEach((node) => {
				if (node.nodeName == "#text") {
					sendText += this.html2Escape(node.textContent);
					if(sendText){
						lastishr = false
					}
				} else if (node.nodeName == "SPAN") {
					sendText += node.innerHTML;
					lastishr = false
				} else if (node.nodeName == "IMG") {
					if(node.className=='emo'){
						sendText += node.dataset.code;
						lastishr = false
					}else if(node.className=='img'){
						imgUrlList.push(node.src)
						if(!lastishr){
							lastishr = true
							sendText += '\r';
						}
					}
				}else if (node.nodeName == "BR") {
					sendText += '\r';
					lastishr = true
				}
			})
			let type = 0
			if(!sendText.trim()&&imgUrlList.length==1){
				type = 1
			}else if(sendText.trim()&&imgUrlList.length==1||imgUrlList.length>1){
				type = 2
			}
			if(imgUrlList.length<=0){
				this.tempFild = {}
			}
			this.isempty = type==0&&(!sendText.trim()||sendText==`\r`)?true:false
			this.SendText = sendText
			this.imgUrlList =imgUrlList
			this.type = type
			this.setfixedHeight()
		},
		html2Escape(strHtml) {
			return strHtml.replace(/[<>&"]/g, function (c) {
				return {
					'<': '&lt;',
					'>': '&gt;',
					'&': '&amp;',
					'"': '&quot;'
				}[c];
			});
		},
        //插入at用户
        onAtSelect(member,noinput_at) {
			let range = window.getSelection().getRangeAt(0)
			// 选中输入的 @xx 符
			if(!noinput_at){
				range.setStart(this.focusNode, this.focusOffset - 1 - this.atSearchText.length)
				range.setEnd(this.focusNode, this.focusOffset)
				range.deleteContents()
			}
			// 创建元素节点
			let element = document.createElement('SPAN')
			element.className = "at"
			element.dataset.id = member.userId;
			element.contentEditable = 'false'
			element.innerText = `@${member.aliasName}`
			range.insertNode(element)
			// 光标移动到末尾
			range.collapse()
			// 插入空格
			let textNode = document.createTextNode('\u00A0');
			range.insertNode(textNode)
			range.collapse()
			this.atSearchText = "";
			this.$refs.editBox.focus()
			this.createSendText();
		},
        //插入表情
        insertEmotion(emoText) {
			this.$refs.editBox.focus();
			let range = window.getSelection().getRangeAt(0);
			// 插入光标所在位置
			range.setStart(this.focusNode, this.focusOffset)
			let element = document.createElement('IMG')
			element.className = "emo"
			element.dataset.code = emoText;
			element.contentEditable = 'true'
			element.setAttribute("src",this.$emo.textToUrl(emoText));
			// 选中元素节点
			range.insertNode(element)
			// 光标移动到末尾
			range.collapse()
			this.createSendText();
			element.addEventListener('click',(e)=> {
				this.getNodeindex(element)
			})
		},
        //插入图片
        insertImg(file) {
			let url = URL.createObjectURL(file)
			this.tempFild[url] = file
            //保持输入框焦点
			this.$refs.editBox.focus();
			let range = window.getSelection().getRangeAt(0);
			// 插入光标所在位置
			range.setStart(this.focusNode, this.focusOffset)
			let element = document.createElement('IMG')
			element.className = 'img'
			// element.dataset.code = code;
			element.contentEditable = 'true'
			element.setAttribute("src",url);
			// 选中元素节点
			range.insertNode(element)
			// 光标移动到末尾
			range.collapse()
			this.createSendText();
			element.addEventListener('click',(e)=> {
				this.getNodeindex(element)
			})
			element.addEventListener('dblclick', (e)=> {
				//双击预览
				this.yrurl = url
				this.showViewer = true
			})
		},
		//插入文本
		insertText(txt){
			this.$refs.editBox.focus();
			let range = window.getSelection().getRangeAt(0);
			// 插入光标所在位置
			range.setStart(this.focusNode, this.focusOffset)
			let textNode = document.createTextNode(txt);
			range.insertNode(textNode)
			range.collapse()
			this.createSendText();
		},
		getNodeindex(node){
			let editBox = this.$refs.editBox
			editBox.blur()
			this.$nextTick(() => {
				editBox.focus();
				let selection = window.getSelection()
				let range = selection.getRangeAt(0);
				this.focusNode = editBox;
				let ind = -1;
				//获取输入框中节点位置
				let childNodes  = editBox.childNodes
				for (let i = 0; i < childNodes.length; i++) {
					if(childNodes[i]== node){
						ind = i
						break
					}
				}
				if(ind>=0){
					range.setStart(editBox,ind)
					range.setEnd(editBox,ind+1)
				}
			}) 

		}
    }
}
</script>
<style scoped lang="scss">
.send-text-area {
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
	height: 100%;
	min-height: 20px;
    resize: none;
    font-size: 16px;
    color: black;
    text-align: left;
    line-height: 20px;
    &:before {
        content: attr(placeholder);
        color: gray;
		position: absolute;
    }
	&.fixedheight{
		height: 60px;
	}
	&:focus-visible {
		outline: none;
	}
    .at {
        color: blue;
        font-weight: 600;
    }
    .receipt {
        color: darkblue;
        font-size: 15px;
        font-weight: 600;
    }
    ::v-deep{
		.emo {
			width: 25px;
			height: 25px;
			vertical-align: bottom;
		}
		.img{
			max-width: 80px;
			height: 60px;
			vertical-align: bottom;
			object-fit: contain;
			object-position: bottom;
			margin: 0px 2px;
		}
	}
    // &:focus-visible{
    //     outline: rgba(83, 160, 231, 0.61) auto 1px;
    // }
}
.receipt{
	&:before {
		position: relative;
	}
}
</style>
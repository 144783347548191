<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="640px"
      :close-on-click-modal="false"
      :close="handleClose">
      <div class="chat-list-items">
        <div class="chat-list-search">
          <el-input prefix-icon="el-icon-search"  :placeholder="$t('comm.search')" v-model="searchText" @change ="searchTextchange"></el-input>
        </div>
        <div class="chat-topbar">
          <ul>
            <li :class ="{'active':selitem==0}" @click="onSelectItem(0)">{{$t('comm.All')}}</li>
            <li :class ="{'active':selitem==1}" @click="onSelectItem(1)">{{$t('comm.flle')}}</li>
            <li :class ="{'active':selitem==2}" @click="onSelectItem(2)">{{$t('comm.picture')}}</li>
          </ul>
          <div  class="datepicker-box">
            <el-date-picker class="datepicker" ref="datepicker"  v-model="date" :editable="false"  @change="handleChangedate" type="date" :picker-options="{disabledDate:time=>{return time.getTime()>new Date()}}"/>
            <div class="img-background" @click="seldate"></div>
            <span @click="seldate" class="iconfont icon-shizhong"></span>
          </div>
        </div>
        <el-scrollbar class="chat-history" ref="ScrollBox" >
          <ul>
            <li class="blank_li" v-if="!loading&&!beforeloadAll"></li>
            <!-- 查找聊天/文件 -->
            <template v-if="selitem!=2">
              <li v-for="(msgInfo) in messages" :key="msgInfo.id" :class="{'itemsticky': msgInfo.type == $enums.MESSAGE_TYPE.TIP_TIME}">
                <div class="chat-msg-time" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TIP_TIME">
                  {{showdate(msgInfo.sendTime) }}
                </div>
                <div class="msg-content" v-else>
                  <div class="headimage">
                    <head-image :url="headImage(msgInfo)" :name="showName(msgInfo)" :size="40"></head-image>
                  </div>
                  <div class="chat-msg-info">
                    <div class="sendname">{{showName(msgInfo)+' '+showtime(msgInfo.sendTime)}}</div>
                    <div class="chat-msg-gift" v-if="msgInfo.type==$enums.MESSAGE_TYPE.RED_WRAP">[{{$t('comm.giftbox')}}]</div>
                    <div class="chat-msg-text" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TEXT" v-html="$emo.transform(msgInfo.content)"></div>
                    <msg-imgtext :content="JSON.parse(msgInfo.content)"  v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMG_TEXT" :imgsize="50"></msg-imgtext>
                    <div class="chat-msg-image" v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMAGE">
                      <el-image class="send-image" :src="JSON.parse(msgInfo.content).url+IMG_PEOXESS" :preview-src-list="[JSON.parse(msgInfo.content).url]" lazy></el-image>
                    </div>
                    <div  v-if="msgInfo.type == $enums.MESSAGE_TYPE.FILE">
                      <chat-msg-file :fileinfo="JSON.parse(msgInfo.content||'{}')"></chat-msg-file>
                    </div>
                    <div class="chat-msg-voice"  v-if="msgInfo.type == $enums.MESSAGE_TYPE.AUDIO" @click="onPlayVoice(msgInfo)">
                      <span v-show="msgInfo.audio_state" style="font-size: 20px;color: #fff;" class="iconfont icon-zanting"></span>
                      <span v-show="!msgInfo.audio_state" style="font-size: 20px;color: #fff;" class="iconfont icon-kaishi"></span>
                      <div class="voice-pause" :class="{'voice-playing':msgInfo.audio_state?true:false}"></div>
                      <span>{{JSON.parse(msgInfo.content).duration||0}}''</span>
                    </div>

                  </div>
                </div>
              </li>
            </template>
            <!-- 查找图片 -->
            <template v-else>
              <li v-for="(msgInfo, idx) in messages" :key="idx"  class="li-showimg" :class="{'itemsticky': msgInfo.type == $enums.MESSAGE_TYPE.TIP_TIME}">
                <div class="chat-msg-time" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TIP_TIME">
                  {{showdate(msgInfo.sendTime) }}
                </div>
                <div class="view-image" v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMAGE">
                  <el-image  fit="cover" class="send-image" :src="JSON.parse(msgInfo.content).url" :preview-src-list="[JSON.parse(msgInfo.content).url]" lazy></el-image>
                </div>
              </li>
            </template>
            <li class="blank_li"> 
              {{loadAll?$t('comm.Tobottom'):''}}
            </li>
          </ul>
        </el-scrollbar>
      </div>
  </el-dialog>
</template>


<script>
import HeadImage from '../common/HeadImage.vue';
import ChatMsgFile from './ChatMsgFile.vue';
import msgImgtext from  './msg-imgtext.vue';
import {audio_PlayVoice} from "@/utils/im-utils.js";
import {IMG_PEOXESS} from '@/api/oss.js'
export default {
  name:"ChatHistory-dialog",
  components: {
    HeadImage,
    ChatMsgFile,
        msgImgtext
  },
  data() {
    return {
      dialogVisible:false,
      searchText:'',
 
      selitem:0,
      loading:false,
      date:"",
      messages:[],
      beforeloadAll:false,
      loadAll:false,
      IMG_PEOXESS:IMG_PEOXESS
    }
  },
  props: {
    title: {
      type: String
    },
    chat: {
      type: Object
    },
    friend: {
      type: Object
    },
    group: {
      type: Object
    },
    groupMembers: {
      type: Array,
    }
  },
  computed: {
    mine() {
      return this.$store.state.userStore.userInfo;
    },
    scrollWrapper() {
      return this.$refs.ScrollBox.$refs.wrap
    }
  },
  methods: {
    onPlayVoice(msg){
      this.$set(msg,'audio_state',1)
      audio_PlayVoice(JSON.parse(msg.content).url,()=>{
        this.$set(msg,'audio_state',0)
      })
    },
    open(){
      this.selitem = 0
      this.date =""
      this.dialogVisible =true
      this.init()
      this.beforeloadAll = true
      this.queryhistory()
      this.$nextTick(() => {
        this.scrollWrapper.addEventListener('scroll', this.onScroll, true)
      })
    },
    onScroll(e){ 
      let scrollTop = this.scrollWrapper.scrollTop;
      let scrollHeight = this.scrollWrapper.scrollHeight;
      let clientHeight = this.scrollWrapper.clientHeight;
      if(scrollHeight-scrollTop-clientHeight<100){//后翻查询
        if(!this.loading){
          if(!this.loadAll){
            let id = this.messages.length!=0?this.messages[this.messages.length-1].id:-1
            this.queryhistoryid(id,0)
          }
        }else{
          this.scrollWrapper.scrollTop =this.scrollWrapper.scrollTop - 100
        }
      }else if(scrollTop<100){//前翻查询
        if(!this.loading){
          if(!this.beforeloadAll){
            let id = this.messages.length!=0?this.messages[1].id:-1
            this.queryhistoryid(id,1)
          }
        }else{
          this.scrollWrapper.scrollTop = 100
        }
      }
    },
    seldate(){
      this.$refs.datepicker.focus()
    },
    init(){
      this.loadAll = false
      this.beforeloadAll = false
      this.messages = []
    },
    onSelectItem(selitem){
      this.selitem = selitem
      this.init()
      this.beforeloadAll = true
      this.queryhistory()
    },
    handleChangedate(val){
      this.init()
      this.beforeloadAll = false
      this.queryhistory(val.getTime()/1000)
    },
    searchTextchange(){
      this.init()
      this.beforeloadAll = true
      this.queryhistory()
    },
    //初次查询调用接口
    queryhistory(beforetime){
      let param = {
        page: 1,
        size:15
      }
      if(this.selitem==1){
        param.type = this.$enums.MESSAGE_TYPE.FILE
      }
      else if(this.selitem==2){
        param.size = 25
        param.type = this.$enums.MESSAGE_TYPE.IMAGE+',' + this.$enums.MESSAGE_TYPE.IMG_TEXT
      }else if(this.searchText){
        param.content = this.searchText
      }
      if(beforetime){
        param.qryDate = beforetime
      }
      let url =""
      if (this.chat.type == 'GROUP') {
        param.groupId = this.chat.targetId;
        url = '/im/groupMessage/history'
      } else {
        param.friendId = this.chat.targetId;
        url =`/im/privateMessage/history`;
      }
      this.loading = true;
      this.$http({
        url: url,
        method: 'get',
        params: param
      }).then(messages => {
        this.insertMsg(messages)
        this.loading = false;
        if(messages.length <param.size){
          this.loadAll = true;
          if(!this.beforeloadAll){
            let id = this.messages.length!=0?this.messages[1].id:-1
            this.queryhistoryid(id,1)
          }
        }else{
          if(!this.beforeloadAll){
            this.$nextTick(()=>{
              this.scrollWrapper.scrollTop = 100
            })
          }
        }
      }).catch(()=>{
        this.loading = false;
      })
    },
    insertMsg(messages,isbefore){
      let id = isbefore? this.messages[1]?.id:this.messages[this.messages.length-1]?.id
      messages.forEach(m =>{ 
        if(m.id!=id){
          m.selfSend = m.sendId == this.mine.id
          //和上一条时间比较日期，跨天则插入一条时间
          if(!isbefore){
            if(this.messages.length==0 || new Date(this.messages[this.messages.length-1].sendTime).getDate()!=new Date(m.sendTime).getDate()){
              this.messages.push({
                sendTime: m.sendTime,
                type: this.$enums.MESSAGE_TYPE.TIP_TIME,
              });
            }
          }else{
            //和第一条时间比较日期，跨天则插入一条时间
            if(this.messages.length==0||new Date(this.messages[0].sendTime).getDate()!=new Date(m.sendTime).getDate()){
              this.messages.unshift({
                sendTime: m.sendTime,
                type: this.$enums.MESSAGE_TYPE.TIP_TIME,
              });
            }
          }
          if(this.selitem==2&&m.type==this.$enums.MESSAGE_TYPE.IMG_TEXT){
            let imglist =  JSON.parse(m.content)
            imglist.forEach( imginfo=> {
              if(imginfo.type==this.$enums.MESSAGE_TYPE.IMAGE){
                if(!isbefore){
                  this.messages.push({
                    id:m.id,
                    type:this.$enums.MESSAGE_TYPE.IMAGE,
                    content:JSON.stringify({url:imginfo.url})
                  })
                }else{
                  this.messages.splice(1,0,{
                    id:m.id,
                    type:this.$enums.MESSAGE_TYPE.IMAGE,
                    content:JSON.stringify({url:imginfo.url})
                  })
                }
              }
            });
          }else{
            if(!isbefore){
              this.messages.push(m)
            }else{
              this.messages.splice(1,0,m)
            }
          }
        }
      });
    },
    //根据消息id查询前后消息
    queryhistoryid(id,gtAndLt){
      if(this.loading){
        return
      }
      let param = {id:id,size:15,gtAndLt:gtAndLt}
      if(this.selitem==1){
        param.type = this.$enums.MESSAGE_TYPE.FILE
      }
      else if(this.selitem==2){
        param.size = 30
        param.type = this.$enums.MESSAGE_TYPE.IMAGE+',' + this.$enums.MESSAGE_TYPE.IMG_TEXT
      }else if(this.searchText){
        param.content = this.searchText
      }
      let url =null
      if(this.chat.type =="GROUP"){
        param.groupId = this.chat.targetId;
        url = '/im/groupMessage/historyById'
      }else{
        param.friendId = this.chat.targetId;
        url =`/im/privateMessage/historyById`;
      }
      this.loading = true;
      this.$http({
        url:url,
        method: 'get',
        params: param
      }).then((messages) => {
        this.loading = false;
        if(gtAndLt){
          this.insertMsg(messages,true)
          if(messages.length <param.size){
            this.beforeloadAll = true;
          }
          let scrollHeight = this.scrollWrapper.scrollHeight
          this.$nextTick(()=>{
            this.scrollWrapper.scrollTop = this.scrollWrapper.scrollHeight - scrollHeight
          })
        }else{
          this.insertMsg(messages)
          if(messages.length <param.size){
            this.loadAll = true;
          }
        }
      }).catch(()=>{
        setTimeout(() => {
          this.loading = false;
        }, 500);
      })
    },
    showName(msgInfo) {
      if (this.chat.type == 'GROUP') {
        let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
        return member ? member.aliasName : "";
      } else {
        return msgInfo.sendId == this.mine.id ? this.mine.nickName : this.chat.showName
      }
    },
    headImage(msgInfo) {
      if (this.chat.type == 'GROUP') {
        let member = this.groupMembers.find((m) => m.userId == msgInfo.sendId);
        return member ? member.headImage : "";
      } else {
        return msgInfo.sendId == this.mine.id ? this.mine.headImage : this.chat.headImage
      }
    },
    showdate(ts){
      const date = new Date(ts);
       const year = date.getFullYear();
       const month = (date.getMonth() + 1).toString().padStart(2, '0');
       const day = date.getDate().toString().padStart(2, '0');
       return `${year}-${month}-${day}`;
    },
    showtime(ts){
       const date = new Date(ts);
       const hour = date.getHours().toString().padStart(2, '0');
       const minute = date.getMinutes().toString().padStart(2, '0');
       return `${hour}:${minute}`;
    },
    handleClose() {
      this.dialogVisible = false
      this.scrollWrapper.removeEventListener('scroll', this.onScroll)
    }
  }
};
</script>


<style scoped lang="scss">
::v-deep{
  .el-dialog{
    border-radius: 12px;
    overflow: hidden;
  }
  .el-dialog__header{
    height: 56px;
    .el-dialog__title{
      font-size: 16px;
    }
  }
  .el-dialog__body{
    padding: 0 0 20px 0 !important;
  }
  .el-scrollbar__wrap{
    margin: 0px !important;
  }
  .is-horizontal{
    display: none;
  }
}
.chat-list-items{
display: flex;
flex-direction: column;
height: 650px;
.chat-list-search{
  padding:8px 20px;
  ::v-deep{
      .el-input__icon{
        line-height: 32px;
      }
      input{
        font-size: 14px;
        color: #999999;
        border: none;
        height: 32px;
        background-color:#F7F7F7;
      }
    }
}
.chat-topbar{
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin: 0 20px 0 5px;
  align-items: center;
  ul{
    padding: 0;
    margin: 0;
    display: flex;
    li{
      list-style-type: none;
      cursor: pointer;
      font-size: 14px;
      color: #333333;
      margin: 0px 15px;
      line-height: 40px;
      &.active{
        color: #7678ED;
        border-bottom:2px solid #7678ED;
      }
    }
  }
  .datepicker-box{
    display: flex;
    align-items: center;
  }
  .icon-shizhong{
    cursor: pointer;
    font-size: 16px;
    color: #333333;
    &:hover{
      color: #7678ED;
    }
  }
  .datepicker{
    width: 0px;
    overflow: hidden;
    margin-right: 20px;
  }

}
.chat-history{
  flex: 1;
  height: 0px;
  ul{
    text-align: left;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    li{
      list-style-type: none;
    }
    .li-showimg{
      display: inline-block;
      margin-bottom: 10px;
    }
    .itemsticky{
      width: 100%;
      position: -webkit-sticky; 
      position: sticky;
      top: 0; /* 距离顶部的距离 */
      z-index: 1;
    }
  }
  .blank_li{
    width: 100%;
    line-height: 100px;
    height: 100px;
    text-align: center;
    font-size: 12px;
    color: #c0c0c0;
  }
  .chat-msg-time{
    margin-left: -10px;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    border-bottom: #EEEEEE 1px solid;
    background-color: #fff;
  }
  .msg-content{
    padding-left:10px;
    display: flex;
    .headimage{
      margin-top: 10px;
    }
    .chat-msg-info{
      flex: 1;
      border-top: #EEEEEE 1px solid;
      margin-top: -1px;
      padding-bottom: 20px;
      padding:11px 20px 20px 10px;
      .sendname{
        font-size: 12px;
        color: #333333;
        line-height: 17px;
        margin-bottom: 8px;
      }
      .chat-msg-text,.chat-msg-gift{
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      .chat-msg-image .send-image{
        height: 74px;
        border-radius: 5px;
      }
      .chat-msg-voice{
        padding-left: 12px ;
        position: relative;
        background: #7678ED;
        color: #fff;
        border-radius: 8px;
        display: flex;
        width: 192px;
        height: 48px;
        align-items: center;
        span{
          margin: 0 4px;
        }
        .voice-pause{
          margin:0 10px 0 5px;
          width: 20px;
          aspect-ratio: 1;
          --c: no-repeat radial-gradient(circle closest-side,#fff 90% 0);
          background: var(--c) 0%  50%,var(--c) 20% 50%,var(--c) 40% 50%,var(--c) 60% 50%,var(--c) 80% 50%,var(--c) 100% 50%;
          background-size: 10% 45%,10% 80%,10% 60%;
        }
        .voice-playing{
          animation: l1 1s infinite linear;
          @keyframes l1 {
            0%  {background-size: 10% 45%,10% 80%,10% 60%}
            25% {background-size: 10% 10%,10% 45%,10% 80%}
            50% {background-size: 10% 60%,10% 10%,10% 45%} 
            75% {background-size: 10% 80%,10% 60%,10% 10%}
            100%{background-size: 10% 40%,10% 80%,10% 60%}
          }
        }
      }
    }
  }
  .view-image{
    .send-image{
      height: 74px;
      width: 107px;
    }
  }
}
}
</style>
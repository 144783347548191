<template>
    <div class="chat-details">
        <template v-if="group_mgview">
            <div class="title" @click="group_mgview=false"><span class="iconfont icon-xiangyou1 icon-title"></span>{{$t('comm.groupmanagement')}}</div>
        </template>
        <template v-else>
            <div class="title">{{chat.type=='GROUP'?$t('comm.groupdetails'):$t('comm.frienddetails')}}</div>
        </template>
        <el-scrollbar class="details-scrollbar">
            <template v-if="group_mgview">
                <div class="info-item ableclick" @click="openDeleteMember = true">
                    <span class="history-name">{{$t('comm.removemembers')}}</span>
                    <span class="iconfont icon-xiangyou1"></span>
                </div>
                <div class="info-item">
                    <span class="info-itemname">{{$t('comm.allprohibitedspeech')}}</span>
                    <el-switch :value="isgroupjs"  @click.native="AllSpeak"/>
                </div>
            </template>
            <template v-else>
                <template v-if="chat.type=='GROUP'">
                    <div class="info-item">
                        <head-image :name="group.name" :size="48" :url="group.headImage"></head-image>
                        <edit-string iconalign="right" class="group-editname" :disabled="!isAdmin" :value="group.name"  :placeholder="group.name" :min="2" :max="15" filed="name" @editafter="editafter"/>
                    </div>
                    <div class="info-item">
                        <span class="item-name">{{$t('comm.GroupRemark')}}</span>
                        <edit-string iconalign="right"  :value="group.remark" :min="0" :placeholder="group.remark||group.name" :max="100" filed="remark" @editafter="editafter"/>
                    </div>
                    <!-- <div class="info-item">
                        <span class="item-name">{{$t('comm.group_aliasName')}}</span>
                        <edit-string iconalign="right"  :value="group.aliasName" :min="0" :placeholder="myname" :max="15" filed="aliasName" @editafter="editafter"/>
                    </div> -->
                    <div class="info-item">
                        <span class="item-name notice">{{$t('comm.group_info')}}</span>
                        <edit-string iconalign="right" class="notice-value" :iswrap="false" :disabled="!isAdmin" :value="group.notice" :min="0" :placeholder="$t('comm.notice_inputinfo')" :max="200" filed="notice" @editafter="editafter"/>
                    </div>
                    <div v-if="isAdmin" class="info-item ableclick" @click="group_mgview = true">
                        <span class="history-name">{{$t('comm.groupmanagement')}}</span>
                        <span class="iconfont icon-xiangyou1"></span>
                    </div>
                </template>
                <div class="info-item ableclick" @click="viewhistory">
                    <span class="history-name">{{$t('comm.findhistory')}}</span>
                    <span class="iconfont icon-xiangyou1"></span>
                </div>
                <div class="info-item">
                    <span class="info-itemname">{{$t('comm.chatnobb')}}</span>
                    <el-switch  :value="isnobb"  @click.native="onmsgtip"/>
                </div>
                <div class="info-item">
                    <span class="info-itemname">{{$t('comm.chattop')}}</span>
                    <el-switch :value="chattop"  @click.native="onTop"/>
                </div>
                <span  class="info-item clear-msg ableclick" @click="clearallmsg">{{$t('comm.clearhistory')}}</span>
            </template>
        </el-scrollbar>
        <delete-member :visible="openDeleteMember" :isAdmintype="isLeader?1:(isAdmin?2:3)"  @reload="$emit('reload')" :groupMembers="groupMembers" @close="openDeleteMember = false"></delete-member>
    </div>
</template>

<script>
import HeadImage from "../common/HeadImage.vue";
import DeleteMember from '../group/DeleteMember.vue';
import EditString from "../common/EditString.vue";
export default {
		name: "chat-details",
        data(){
            return{
                group_mgview:false,
                openDeleteMember:false
            }
        },
        components: {
			EditString,
            HeadImage,
            DeleteMember,
		},
        props:['chat','friend','group','groupMembers','isAdmin'],
        watch:{
            group(){
                this.group_mgview = false
            }
        },
        methods:{
            viewhistory(){
                this.$emit('viewhistory')
            },
            onmsgtip(){
                if(this.chat.type!='GROUP'){
					this.editfriendafter({filed:"disturbing",value:!this.isnobb?1:0})
				}else{
					this.editafter({filed:"disturbing",value:!this.isnobb?1:0})
				}
            },
            editfriendafter({filed,value}){
				let param = {friendId:this.friend.id}
				param[filed] = value
				this.$http({
					url: "/im/imFriend/updateFriendById",
					method:'PUT',
					data:param
				}).then((res) => {
					this.$emit('reload');
				})
			},
			editafter({filed,value}){
				if(filed=="aliasName"&&!value.trim()){
					value = this.myname
				}
				let vo = JSON.parse(JSON.stringify(this.group));
				vo[filed] = value
                this.$http({
					url: "/im/imGroup/modify",
					method: "put",
					data: vo
				}).then((group) => {
					this.$emit('reload',true);
                    this.$message.success(this.$t('comm.editsuccess'));
				})
			},
            AllSpeak(){
                if(this.isgroupjs){
                this.$confirm(this.$t('comm.confirmliftallbans'), this.$t('comm.liftallbans'), {
                    confirmButtonText: this.$t('comm.confirm'),
                    cancelButtonText: this.$t('comm.cancel'),
                    type: 'warning'
                }).then(()=>{
                    this.setableSpeak(0)
                })
                }else{
                this.$confirm(this.$t('comm.liftallbanstips'), this.$t('comm.allprohibitedspeech'), {
                    confirmButtonText: this.$t('comm.confirm'),
                    cancelButtonText: this.$t('comm.cancel'),
                    type: 'warning'
                }).then(()=>{
                    this.setableSpeak(1)
                })
                }
            },
            setableSpeak(groupMute){
            this.$http({
              url: `im/imGroup/groupMute`,
              method: 'PUT',
              data:{
                id:this.group.id,
                groupMute:groupMute
              }
            }).then(() => {
              this.$message.success(this.$t('comm.editsuccess'));
              this.$emit('reload');
            });
          },
            onTop(){
                if(!this.chattop){
					let chatIdx = this.$store.state.chatStore.chats.findIndex(cs => cs == this.chat)
					this.$store.commit("top", chatIdx);
				}else{
					let chatIdx = this.$store.state.chatStore.chats.findIndex(cs => cs == this.chat)
					this.$store.commit("cleantop", chatIdx);
				}
            },
            //清空聊天记录
			clearallmsg(){
				let is_del_friend = false
				const h = this.$createElement;
				let message =null
				if(this.chat.type =='GROUP'){
					message = h('div',{style:{'height': '60px','padding-top':'20px','padding-left':'25px'}}, this.$t('comm.confirm_delAllhistory'))
				}else{
					let friendname =this.friend.friendRemark||this.friend.nickName
					message = h('div',{style:{'height':'90px','padding-left':'25px'}},[
						h('div',{style:{'padding-top':'20px'}}, this.$t('comm.confirm_delAllhistory')),
						h('el-checkbox',{
							key:"checkbox"+Date.now(),
							style:{'padding-top':'20px'},
							on:{
								change:(val) => {
									is_del_friend =val
								}
							}
						}, this.$t('comm.togetherDel',[friendname]))
					])
				}
				this.$msgbox({
					title: this.$t('comm.clearhistory'),
					message: message,
					showCancelButton: true,
					confirmButtonText: this.$t('comm.delete'),
					cancelButtonText: this.$t('comm.cancel'),
					confirmButtonClass:"delete-confirm-Button-Class",
				}).then(() => {
					if(this.chat.type !='GROUP'&& is_del_friend){
						//双向删除好友信息
						let param = {
							idRemore:[],
							type:1,
							empty:2,
							friendId:this.chat.targetId
						}
						this.$http({
							url: `/im/groupMessage/addSingle`,
							method: 'POST',
							data:param
						}).then(() => {
							//删除本地
							this.$store.commit("clearAllMessage", this.chat);
						})
					}else{
						//删除本地
						this.$store.commit("clearAllMessage", this.chat);
					}
				}).catch(() => {})
            },
        },
        computed: {
            myname(){
				return this.$store.state.userStore.userInfo.nickName
			},
            isnobb(){
				if(this.chat.type =="GROUP"){
					return this.group.disturbing?true:false
				}else{
					return this.friend.disturbing?true:false
				}
			},
            chattop(){
			 	return this.chat.topnum?true:false
			},
            isgroupjs(){
                return this.group.groupMute==1
            },
            yltolang(){//预览语言
                return this.chat.yltolang||'EN-US'
            },
            isLeader(){
                return this.group?.ownerId == this.$store.state.userStore.userInfo.id;
            }
        }
    }

</script>

<style scoped lang="scss">
.chat-details{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title{
        text-align: left;
        line-height: 56px;
        font-weight: 600;
        font-size: 16px;
        padding: 0px 16px 0px 16px;
    }
    .icon-title{
        display: inline-block;
        margin-right:4px;
        transform: rotate(180deg)
    }
}
.details-scrollbar{
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #333333;
    text-align: left;
    font-size: 16;
    overflow-y: scroll;
    height: 100%;
    ::v-deep{
        .el-switch.is-checked .el-switch__core{
            border-color: #7678ED ;
            background-color: #7678ED ;
        }
    }
    .info-item{
        display: flex;
        justify-content: space-between;
        padding: 0px 16px;
        align-items: center;
        &.ableclick:hover{
            cursor: pointer;
            background-color: #33333311;
        }
        .history-name,&.clear-msg{
            line-height: 56px;
        }
        .icon-xiangyou1{
            color:  #747D84;
        }
        .info-itemname{
            line-height: 56px;
        }
        .item-name{
            line-height:56px;;
            min-width: 100px;
        }
        .notice{
            align-self: flex-start;
        }        
        .head-image{
            margin: 4px 0px;
        }
        .edit-string{
            flex: 1;
            width: 0px;
            font-size: 16px;
            margin-left: 10px;
            color: #333333;
            ::v-deep{
                .content{
                    justify-content: end;
                }          
                div .iswrap,div .nowrap,input,textarea{
                    font-size: 16px;
                    color: #626569  !important;
                    font-family:inherit;
                }
            }
        }
    }
    .trans-msg{
        padding:10px 16px ;
        .info-item{
            padding: 0;
        }
        .trans-msg-info{
            font-size: 12px;
            color: #626569;
            line-height: 16px;
            margin-top: 4px;
        }
        .trans-setting{
            transition: all 0.5s;
            height: 0px;
            overflow: hidden;
            opacity: 0;
        }
        .show-trans{
            height: 55px;
            opacity: 1;
        }
    }
}
</style>

<template>
  <el-dialog
        :visible.sync="dialogVisible"
        width="400px"
        :close-on-click-modal="true"
        :before-close="handleClose">
      <div class ="bx-open-content">
          <div class="bx-title">Good Luck</div>
          <div class="open_view">
            <div class="send-user"> 
              <img class="snedimg" :src="sendheadImage" loading="lazy"/>
              <span>{{$t('comm.blindsentout',[sendaliasName])}}</span>
            </div>
            <div class="send-text">{{contentObj.title}}</div>

            <template v-if="type==0">
              <div v-if="msgInfo.state=='open_not'" class="open_not">{{$t('comm.giftopennot')}}</div>
              <div v-else-if="msgInfo.state=='open_expire'" class="open_not">{{$t('comm.giftopenexpire')}}</div>
              <template v-if="msgInfo.state&&msgInfo.state=='open_suc'">
                <div class="mylqcount">{{mineitem&&mineitem.amount}}<img class="im-zs" src="@/assets/im-icons/im-zs.svg" loading="lazy"/></div>
                <img  class="touchicon" src="@/assets/im-icons/gift-box.svg"   loading="lazy"/>
              </template>
              <template v-else-if="msgInfo.state&&msgInfo.state!='opening'">
                <img  class="touchicon" src="@/assets/im-icons/gift-box-open.svg" loading="lazy"/>
                <img  class="left-zs3" src="@/assets/im-icons/im-zs3.svg"  loading="lazy"/>
              </template>
              <img v-else class="touchicon" src="@/assets/im-icons/gift-box-all.svg" @click ="robAction"  loading="lazy"/>
              <div @click="viewinfo" class="view-info">{{$t('comm.giftrobinfo')}} ></div>
            </template> 
            <template v-else>
              <div class="lq-info">
                  {{$t('comm.robNuminfo',[boxinfo.totalPacket-boxinfo.remainingPacket,boxinfo.totalPacket,boxinfo.totalAmount-boxinfo.remainingAmount,boxinfo.totalAmount])}}
              </div>
              <el-scrollbar class="lq-info-user"  ref="chatScrollBox">
                  <div class="lq-item" v-for="(item,index) in boxinfo.redPacketRecord" :key="index">
                      <img class="lq-item-img" :src="headImage(item.uid)"/>
                      <div class="lq-item-name">
                        <span>{{getusername(item.uid)}}</span>
                        <span class="lq-time">{{$date.formatDate(item.createTime*1000,'yyyy-MM-dd hh:mm:ss') }}</span>
                      </div>
                      <div class="lq-item-num">
                        <span class="">{{item.amount}}<img class="im-zs" src="@/assets/im-icons/im-zs.svg" loading="lazy"/></span>
                      </div>
                  </div>
              </el-scrollbar>
            </template> 
        </div>

        <span @click="handleClose" style="font-size:50px;color:#0000008a;position:absolute;bottom:-100px;left: 150px;" class="iconfont icon-guanbi1"></span>
      </div>
  </el-dialog>
</template>

<script>
export default {
  name:"chat-bx-dialog",
  data() {
    return {
      dialogVisible: false,
      type:0, //0:抢积分界面  1详情界面
      msgInfo:{},
      boxinfo:{},
      robloading:false
    }
  },
  props: ['groupMembers','friend','chattype'],
  //msgInfo.state状态 为空则未点开红包 opening：打开动作  open_suc：已领取  open_not：已抢完  open_expire：已过期
  methods: {
    //抢
    open(msgInfo){
      if(this.robloading){
        return
      }
      let contentObj = JSON.parse(msgInfo.content)
      if(contentObj.type==1&&contentObj.userIds&&contentObj.userIds.length>0){
        let index = contentObj.userIds.findIndex(uid=>uid==this.mine.id)
        if(index<0){
          this.$message.error(this.$t('comm.norobuser'));
          return
        }
      }
      this.msgInfo =  msgInfo
      if(msgInfo.state=='opening'){
        this.dialogVisible =true
      }else if(msgInfo.state){
        this.viewinfo();
      }else{
        const loading = this.$loading({
          lock: true,
          text: '',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let redPacketId =contentObj.redPacketId
        this.robloading =true
        this.$http({
          url: `/im/propInfo/rob/${redPacketId}`,
          method: 'post',
          noCheck:true,
        }).then((res) => {
          this.updatemsgstate('opening')
          this.$store.commit("saveToStorage")
          this.dialogVisible =true
        }).catch((res)=>{
          if(res.code==-1){//抢完'
            this.updatemsgstate('open_not')
            this.$store.commit("saveToStorage");
            this.dialogVisible =true
          }else if(res.code==-2){//重复调用这个接口返回-2
            this.updatemsgstate('opening')
            this.$store.commit("saveToStorage")
            this.dialogVisible =true
          }else  if(res.code==-4){//过期
            this.updatemsgstate('open_expire')
            this.$store.commit("saveToStorage");
            this.dialogVisible =true
          }else if(res&&res.code){
            this.$message.error(res.msg);
          }
        }).finally(()=>{
          this.robloading = false
          loading.close();
        })
      }
    },
    //更新消息状态
    updatemsgstate(state){
      this.$store.commit("updateMessage", {...this.msgInfo, state: state})
    },
    handleClose(){
      this.type = 0
      this.dialogVisible =false
    },
    //拆红包
    robAction(){
      if(this.robloading){
        return
      }
      let redPacketId = this.contentObj.redPacketId
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.robloading =true
      this.$http({
        url: `/im/propInfo/unpack/${redPacketId}`,
        method: 'post',
        noCheck:true,
      }).then((res) => {
        this.updatemsgstate('open_suc')
        this.$store.commit("saveToStorage");
        this.robloading =false
        this.viewinfo();
      }).catch((res)=>{
        if(res.code==-1){//抢完
          this.updatemsgstate('open_not')
          this.$store.commit("saveToStorage");
        }else if(res.code==-4){//过期
          this.updatemsgstate('open_expire')
          this.$store.commit("saveToStorage");
        }else if(res&&res.code){
          this.$message.error(res.msg);
        }
      }).finally(()=>{
        this.robloading =false
        loading.close();
      })
    },
    //查看红包详情
    viewinfo(istz){
      if(this.robloading){
        return
      }
      this.boxinfo = {}
      this.robloading =true
      let redPacketId = this.contentObj.redPacketId
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http({
        url: `im/propInfo/find/${redPacketId}`,
        method: 'get'
      }).then((res) => {
        let maxitem = res.redPacketRecord[0]
        for (let i = 1; i < res.redPacketRecord.length; i++) {
          const item = res.redPacketRecord[i];
          if(maxitem.amount < item.amount){
            maxitem = item
          }
        }
        if(maxitem){
          maxitem.good = true
        }
        if(istz){
          this.type=1
        }
        this.boxinfo = res
        this.dialogVisible =true
      }).finally(()=>{
        this.robloading =false
        loading.close();
      })
    },
    getusername(uid){
      if(this.chattype=='GROUP'){
        let member = this.groupMembers.find((m) => m.userId == uid);
        return member ? member.aliasName : "";
      }else{
        return (uid== this.mine.id ? this.mine.nickName : (this.friend.friendRemark||this.friend.nickName))||""
      }
    },
    headImage(uid){
      if(this.chattype=='GROUP'){
        let member = this.groupMembers.find((m) => m.userId == uid);
        return member ? member.headImage : "";
      }else{
        return uid == this.mine.id ? this.mine.headImage : this.chat.headImage
      }
    }
  },
  computed:{
    contentObj(){
      return this.msgInfo.content&&JSON.parse(this.msgInfo.content)||{}
    },
    mine() {
      return this.$store.state.userStore.userInfo;
    },
    mineitem(){
      return this.boxinfo.redPacketRecord&&this.boxinfo.redPacketRecord.find((im)=>im.uid == this.mine.id)||null
    },
    sendheadImage(){
        if(this.msgInfo.groupId){
            return (this.groupMembers.find(gm=>gm.userId == this.msgInfo.sendId)||{}).headImage
        }else{
            return this.friend.headImage
        }
    },
    sendaliasName(){
        if(this.msgInfo.groupId){
            return (this.groupMembers.find(gm=>gm.userId == this.msgInfo.sendId)||{}).aliasName
        }else{
            return this.friend.friendRemark || this.friend.nickName
        }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep{
  .el-dialog {
    margin: 0 !important;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: #222;
    box-shadow:none;
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 20px 32px 0px 0px;
      height: 500px;
    }
    background: url(~@/assets/im-icons/gift-bg.svg)  no-repeat;
    background-size: contain;
  }
}
.bx-open-content{
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  .im-zs{
    width: 32px;
    height: 32px;
  }
  .bx-title{
    margin-top: 50px;
    margin-left: 48px;
    font-weight: 600;
    font-size: 30px;
    color: #FD7E14;
    line-height: 42px;
  }
  .open_view{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    align-items: center;
    position: relative;
    .mylqcount{
      font-weight: 500;
      font-size: 56px;
      color: #D76A69;
    }
    .send-user{
      align-self: normal;
      margin-top: 26px;
      margin-left: 70px;
      display:flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      margin-right: 30px;
      .snedimg{
        width: 40px;
        height: 40px;
        border-radius: 12px;
        margin-right:12px; 
      }
    }
    .send-text{
      margin-top: 15px;
      font-size: 14px;
      color: #D76A69;
      line-height: 16px;
      height: 16px;
      max-width: 90%;
      min-height: 35px;
    }
    .open_not{
      margin: 0 20px;
      font-weight: 500;
      font-size: 20px;
      color: #D76A69;
      line-height: 30px;
    }
    .touchicon{
      position: absolute;
      bottom: 70px;
    }
    .left-zs3{
      width: 50px;
      bottom: 60px;
      left: 36px;
      position: absolute;
      opacity: 0.7;
    }
    .view-info{
      cursor: pointer;
      margin-top: auto;
      margin-bottom: 33px;
      font-size: 14px;
      color: #D76A69;
      line-height: 16px;
    }
    .lq-info{
      align-self: start;
      font-size: 12px;
      color: #D76A69;
      line-height: 17px;
      margin-left: 33px;
    }
    .lq-info-user{
      height: 200px;
      width: 100%;
      .lq-item{
        height: 56px;
        display: flex;
        align-items: center;
        .lq-item-img{
          margin-left: 32px;
          margin-right: 8px;
          width: 40px;
          height: 40px;
          border-radius: 16px;
          background-color: #F5F5F588;
        }
        .lq-item-name{
          font-size: 14px;
          color: #333333;
          display: flex;
          flex-direction: column;
          .lq-time{
            margin-top: 4px;
            font-size: 12px;
            color: #626569;
            width: 100%;
          }
        }
        .lq-item-num{
          margin-right: 30px;
          margin-left: auto;
          font-size: 20px;
          color: #626569;
          .im-zs{
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }
}
</style>
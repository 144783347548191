let audio = null
let audio_pause_callback = null

//播放音频
let audio_PlayVoice = function(url,callback){
    if (!audio) {
        audio = new Audio();
        //播放暂停/结束回调
        audio.onpause =  ()=>{
            audio_pause_callback && audio_pause_callback()
            audio_pause_callback = null
        };
        //播放结束回调
        //audio.onended =  ()=> {};
    }
    let playing = !audio.paused && !audio.ended; //是否正在播放
    if (!playing) {
        audio.src = url
        audio_pause_callback = callback
        audio.play();
    } else {
        //url不一致，暂停之前，播放当前音频，并手动触发之前播放回调
        audio.pause();
        audio.currentTime = 0;
        if(audio.src!=url){
            audio_pause_callback && audio_pause_callback()
            audio_pause_callback = null
            audio_PlayVoice(url,callback)
        }
    }
}

export{
    audio_PlayVoice
}
<template>
	<div ref="msglist"  @mousemove="mousemove" class="msg-scrollview">
        <el-scrollbar class="im-chat-box"  ref="msgScrollBox">
            <div class="scroll-view-content">
                <div class="loader-msg"><div v-show="loadtop" class="loader-inner ball-spin-fade-loader"><div></div> <div></div> <div></div><div></div> <div></div><div></div> <div></div><div></div></div></div>
                <div v-for="(item,idx) in showdatalist" v-if="showMinIdx<idx&&item.is_delete!=1"  :id="prefixid+(item.id?item.id:'x'+item.sendTime)" >
                    <slot :item="item" 
                          :nextitem="showdatalist[idx+1]" 
                          :islightrow="item.id==lightmsgid" >
                    </slot>
                </div>
                <div v-show="loadbottom" class="loader-msg"><div  class="loader-inner ball-spin-fade-loader"><div></div> <div></div> <div></div><div></div> <div></div><div></div> <div></div><div></div></div></div>
            </div>    
        </el-scrollbar>
        <div v-show="topNewNum>0" @click="readNewMsg()" class="back_newtop" >
            <span class="unreadnum">{{topNewNum}}条新消息</span>
            <div class="iconfont icon-fangxiang"></div>
        </div>

        <div v-show="isButtomBt&&showbmbt" @click="scrollToBottom()" class="back_bottom" >
            <span v-if="unreadCount>0" class="unreadnum">{{unreadCount}}条新消息</span>
            <div class="iconfont icon-fangxiang"></div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {

            fixedData:[],//滚动到上面固定数据
            showbmbt:false,//是否显示回到底部按钮
            lightmsgid:-1,//亮高行id
            loadtop:false,
            isloadalltop:false,

            loadbottom:false,
            isloadall:false,//fixedData是否加载完底部消息

            isinit:false,//是否初始化中
            toBmTimer:null,//滚动底部任务,可以判处此是否在滚动到底部过程中

            topNewNum:0,//顶部最新消息数量 ,0不显示
            topNewid:0,//最新消息id

            isshowfixedData:false,//是否显示fixedData列表
            showMinIdx:0,
            scroll_no_event:false,
            isdelindex:0
        }
    },
    props: {
        prefixid:{//消息id前缀
            type:String,
            default:"msg-item-"
        },
        changelist: {//底部变化的数据
            type: Array,
            default:[]
        },
        isButtomBt:{//是否需要回到底部按钮
            type: Boolean,
            default:true
        },
        BackBottomY: {//距离底部多少距离（px）出现回到底部按钮
            type: Number,
            default :500
        },
        chat:{
            type: Object,
        },
        ismultiple:{
            type: Boolean,
        },
    },
    watch:{
        "changelist.length":function(val,oldval){
            this.isloadall = false
            if(val==0&&!this.isinit){
                this.showbmbt = false
                this.fixedData = []
                this.isloadalltop = false
                this.topNewNum = 0
                this.topNewid = 0
                this.isshowfixedData = false
            }
            if(!this.showbmbt&&!this.isinit){
                this.isshowfixedData = false
                this.isloadall = false
                if(val>oldval||oldval-val>100){//消息变少100条内不滚动
                    this.scrollToBottom(true,400,false)
                }
            }
        },
        "chat.delmessages":function(delmsgs,oldval){
            if(this.fixedData.length>0&&delmsgs.length>0){
                for (let i = this.isdelindex; i < delmsgs.length; i++) {
                    let index = this.fixedData.findIndex(msg =>msg.id == delmsgs[i].id&&(!msg.day||this.chat.messages[idx].day == msg.day))
                    if(index>=0){
                        this.fixedData.splice(index, 1);
                    }
                }
                this.isdelindex = delmsgs.length -1
            }
        },
        ismultiple:function(val,oldval){
            if(val){
                this.isshowfixedData = true
                if(this.fixedData.length>0){
                    this.fixedData = [...this.changelist]
                }
                this.keepOri_scroll()
            }
        }
    },
    computed:{
        showdatalist(){
            return this.isshowfixedData?this.fixedData:this.changelist
        },
        mine() {
            return this.$store.state.userStore.userInfo;
        },
        unreadCount() {
            return this.chat.unreadCount;
        },
        scrollWrapper() {
			return this.$refs.msgScrollBox.$refs.wrap
		}
    },
    mounted() {
		this.scrollWrapper.addEventListener('scroll', this.onScroll, true)
	},
	beforeDestroy() {
		this.scrollWrapper.removeEventListener('scroll', this.onScroll)
	},
    methods: {
        init(){
            this.showbmbt = false
            this.fixedData = []
            this.isinit = true
            this.isloadalltop = false
            this.topNewNum = 0
            this.topNewid = 0
            this.isshowfixedData = false
            if(this.toBmTimer){
                clearTimeout(this.toBmTimer)
                this.toBmTimer = null
            }
        },
        initsuccess(){
            //完成初始化调用已读接口
            this.isinit = false
            if(this.unreadCount>20&&this.chat.msgNewMinId){//未读消息大于20条
                this.topNewNum = this.unreadCount
                this.topNewid = this.chat.msgNewMinId
            }
            this.showMinIdx =  this.changelist.length -50
            //滚动到底部自动调用已读
            this.scrollToBottom(false)
        },
        //滑动到顶部触发
        onScrollToTop(){
            if(this.loadtop||this.isinit){
                return
            }
            //是否在滚动底部过程中
            if(this.toBmTimer){
                return
            }
            console.log('onScrollToTop')
            let fistrow = null
            for (let i = 0; i < this.fixedData.length; i++) {
                if(this.fixedData[i].id>0){
                    fistrow = this.fixedData[i]
                    break
                }
            }
            if(!fistrow){
                return
            }
            if(this.showMinIdx<=0){
                if(!this.isloadalltop){
                    this.QueryMsgtoMsg(fistrow,0,()=>{
                        this.keepOri_scroll(false)
                    })
                }
            }else{
                this.showMinIdx = this.showMinIdx - 50<0 ? 0 : this.showMinIdx - 50
                this.keepOri_scroll()
            }
        },
        //滑动到底部触发
        onScrollToLower(){
            if(this.loadbottom||this.isloadall||!this.isshowfixedData||this.isinit){
                return
            }
            //是否在滚动底部过程中
            if(this.toBmTimer){
                return
            }
            console.log('onScrollToLower')
            let lastrow = null
            let tgnum = 0
            for (let i = this.fixedData.length-1; i>=0 ; i--) {
                if(this.fixedData[i].id>0){
                    lastrow = this.fixedData[i]
                    tgnum = this.fixedData.length - i - 1
                    break
                }
            }
            if(!lastrow){
                return
            }
            let index = this.changelist.findIndex(row=>row.id == lastrow.id)
            if(index<0){
                //底部变动消息没找到 远程请求
                this.QueryMsgtoMsg(lastrow,1,()=>{
                    this.keepOri_scroll(true)
                })
                return
            }else{
                if(index+tgnum == this.changelist.length-1){
                    this.isloadall = true
                    return
                }
                let syrow =this.changelist.length-index-1
                let loadnum = 30
                if(syrow<60){
                    loadnum = syrow
                    this.isloadall = true
                }
                for (let i = index+1+tgnum; i < index+1+loadnum; i++) {
                    this.fixedData.push(this.changelist[i])
                }
            }
        },
        //滚动事件
        onScroll(e){
            if(this.scroll_no_event){
                return
            }
            let scrollElement = e.target
			let scrollTop = scrollElement.scrollTop
            if(scrollTop<300&&!this.scrllToptimer&&this.showbmbt){
                this.scrllToptimer = setTimeout(() => {
                    this.scrllToptimer = null
                    this.onScrollToTop()
                }, 100);
            }
            let clientHeight =scrollElement.clientHeight
            let scrollHeight = scrollElement.scrollHeight
            if(scrollHeight - clientHeight - scrollTop <600&&!this.scrllLowertimer){
                this.onScrollToLower()
                this.scrllLowertimer = setTimeout(() => {
                    this.scrllLowertimer = null
                }, 300);
            }
            //是否在滚动底部过程中
            if(this.toBmTimer){
                return
            }
            if(this.scrolltimer||this.isinit){
                return
            }
            this.scrolltimer = setTimeout(()=>{
                this.$emit("onScroll",e)
                this.scrolltimer =null
                //是否显示置地按钮
                if(this.isButtomBt&&(this.isloadall||!this.showbmbt)){
                    let showbt = scrollHeight  - scrollTop - clientHeight > this.BackBottomY
                    if(showbt&&this.showbmbt!=showbt&&!this.isshowfixedData&&!this.ismultiple){
                        this.fixedData = this.changelist.slice(0)
                        this.showMinIdx =  this.changelist.length -50
                        this.isloadalltop = false
                        this.isloadall = true
                        this.isshowfixedData = true
                    }
                    if(!showbt&&this.showbmbt!=showbt){
                        this.unreadCount>0&&this.readedMessage();
                    }
                    this.showbmbt = showbt
                }
            },40)
        },
        //滚动到底部
        scrollToBottom(isAnima=true,time=400,isread=true){
            if(this.toBmTimer){
                this.toBmTimerTow = true//等上次运行结束在调用scrollToBottom
                return
            }
            if(this.showbmbt){
                this.showbmbt = false
                if(!this.ismultiple){
                    this.isshowfixedData = false
                    this.showMinIdx =  this.changelist.length -50
                    if(this.isloadall){
                        this.keepOri_scroll(false,()=>{
                            this.scrollToBottom()
                        })
                    }
                }
            }
            console.log('scrollToBottom')
            this.$nextTick(()=>{
                if(isAnima ===false){
                    this.scrollWrapper.scrollTop = this.scrollWrapper.scrollHeight-this.scrollWrapper.clientHeight;
                }else{
                    let temp = this.scrollWrapper.scrollHeight - this.scrollWrapper.scrollTop -this.scrollWrapper.clientHeight
                    if(temp<20){
                        this.scrollWrapper.scrollTop = this.scrollWrapper.scrollTop-500
                    }
                    this.$nextTick(()=>{
                        this.scrolling(this.scrollWrapper,1000, this.scrollWrapper.scrollHeight-this.scrollWrapper.clientHeight)
                    })
                }
                isread&&this.unreadCount>0&&this.readedMessage();
            })
            this.toBmTimer = setTimeout(() => {
                if(this.toBmTimerTow){
                    this.scrollToBottom(isAnima,time);
                }
                this.toBmTimer = null
            }, time);
        },
        scrolling (el, duration, targetOffsetTop) {
            this.scroll_no_event = true
            // 获取当前滚动位置
            let currentScrollTop = el.scrollTop;
            // 计算滚动距离
            let distance = targetOffsetTop - currentScrollTop;
            // 计算每一帧滚动的距离和时间间隔
            let scrollStep = Math.PI / (duration / 15);
            let cosParameter = (targetOffsetTop - currentScrollTop) / 2;

            let scrollCount = 0;
            let scrollMargin;
            let t = this
            // 定义滚动动画函数
            function smoothScroll() {
                // 设置下一帧滚动位置
                let targetScrollTop = currentScrollTop + cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                el.scrollTop = targetScrollTop;
                // 更新滚动计数器和滚动边距
                scrollCount++;
                scrollMargin = distance - cosParameter + cosParameter * Math.cos(scrollCount * scrollStep);
                // 判断是否继续滚动
                if (Math.abs(scrollMargin) > 0.2) {
                    requestAnimationFrame(smoothScroll);
                }else{
                    t.scroll_no_event = false
                }
            }
            // 调用滚动动画函数
            smoothScroll();
        },
        //渲染数据后保持滚动条在原来位置  istop相对顶部/底部距离
        keepOri_scroll(istop,callback){
            let top = this.scrollWrapper.scrollTop
            let boxHeight = this.scrollWrapper.scrollHeight
            this.$nextTick(()=>{
                if(istop){
                    this.scrollWrapper.scrollTop = top == this.scrollWrapper.scrollTop?top-1:top
                }else{
                    top = this.scrollWrapper.scrollHeight- boxHeight + top
                    this.scrollWrapper.scrollTop = top == this.scrollWrapper.scrollTop?top-1:top
                }
                callback&&callback()
            })
        },

        //根据某条消息，查询前后的消息
        QueryMsgtoMsg(msg,gtAndLt,callback){
            if(msg.groupId){
                this.QueryGroupMsg(msg.groupId,msg.id,gtAndLt,callback)
            }else{
                let friendid =  msg.selfSend ? msg.recvId : msg.sendId;
                this.QueryPrivateMsg(friendid,msg.id,gtAndLt,callback)
            }
        },
        QueryPrivateMsg(friendid,id,gtAndLt,callback){
            let param = {id:id,friendId:friendid,gtAndLt:gtAndLt}
            this.QueryMsgssage('PRIVATE',param,null,callback)
        },
        QueryGroupMsg(groupid,id,gtAndLt,callback){
            let param = {id:id,groupId:groupid, size:30, gtAndLt:gtAndLt}
            this.QueryMsgssage("GROUP",param,null,callback)
        },
        QueryMsgssage(type,param,callbackbefore,callbackafter){
            let url = type =="GROUP"?`/im/groupMessage/historyById`:`/im/privateMessage/historyById`
            param.gtAndLt?this.loadbottom = true:this.loadtop = true
            param.size =50
            this.$http({
                url:url,
                method: 'get',
                params: param
            }).then((msgs) => {
                callbackbefore&&callbackbefore(msgs)
                if(!msgs||msgs.length<param.size){
                    if(!param.gtAndLt){
                        this.isloadalltop = true
                    }
                }
                if(msgs){
                    let userid =this.mine.id;
                    msgs.forEach(msg=>{
                        msg.selfSend = msg.sendId == userid
                        let message = this.fixedData.find(fmsg=>fmsg.id==msg.id);
                        let msgleng = this.fixedData.length
                        if(!message){
                            if(!param.gtAndLt){
                                let TimeTip  = msgleng>0&&this.fixedData[0].sendTime || null
                                if (TimeTip && new Date(TimeTip).getDate()==new Date(msg.sendTime).getDate()){
                                    this.fixedData.splice(1, 0, msg);
                                }else{
                                    this.fixedData.unshift(msg);
                                    this.fixedData.unshift({
                                        sendTime: msg.sendTime,
                                        type: this.$enums.MESSAGE_TYPE.TIP_TIME,
                                    });
                                }
                            }else{
                                let TimeTip  = msgleng>0&&this.fixedData[msgleng-1].sendTime || null
                                if (TimeTip && new Date(TimeTip).getDate()==new Date(msg.sendTime).getDate()){
                                    this.fixedData.push(msg);
                                }else{
                                    this.fixedData.push({
                                        sendTime: msg.sendTime,
                                        type: this.$enums.MESSAGE_TYPE.TIP_TIME,
                                    });
                                    this.fixedData.push(msg);
                                }
                            }
                        }
                    })
                }
                param.gtAndLt?this.loadbottom = false:this.loadtop = false
                callbackafter&&callbackafter(msgs)
            }).catch(() => {
                param.gtAndLt?this.loadbottom = false:this.loadtop = false
                callbackafter&&callbackafter()
            })
        },
        //滚动到最新消息
        readNewMsg(){
            this.queryscrollMsg(this.chat.type,this.chat.targetId,this.topNewid)
            this.topNewNum = 0
            this.topNewid = 0
        },
        //查询并滚动到指定消息 并亮高
        queryscrollMsg(type,targetId,msgid){
            let param = {id:msgid, size:30, gtAndLt:1}
            if(type =="GROUP"){
                param.groupId = targetId
            }else{
                param.friendId =targetId
            }
            let Index =this.fixedData.length==0?-1:this.fixedData.findIndex(msg=>msg.id == msgid)
            if(Index<0){
                this.fixedData = [...this.changelist] 
                Index = this.fixedData.findIndex(msg=>msg.id == msgid)
            }
            if(Index>=0){
                this.isloadall = false
                this.showMinIdx = this.showMinIdx >Index-20?Index-20:this.showMinIdx 
                this.$nextTick(()=>{
                    this.showbmbt = this.scrollWrapper.scrollHeight  - this.scrollWrapper.scrollTop - this.scrollWrapper.clientHeight > this.BackBottomY
                    this.isshowfixedData = true
                    let itemli = document.getElementById(this.prefixid+msgid)
                    if(itemli){
                        this.lightmsgid = msgid
                        let toscroll = itemli.offsetTop-this.scrollWrapper.offsetTop -50
                        if(toscroll<0){
                            toscroll = 0
                        }
                        this.scrolling(this.scrollWrapper,600, toscroll)
                        this.templighttimer&&clearTimeout(this.templighttimer)
                        this.templighttimer = setTimeout(()=>{
                            this.lightmsgid =-1
                        },2000)
                    }
                })
            }else{
                let isexist = false
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0)'
                });
                this.QueryMsgssage(type,param,(msgs)=>{
                    if(msgs.findIndex(msg=>msg.id == msgid)>=0){
                        this.fixedData = []
                        this.showbmbt = true
                        this.isshowfixedData = true
                        this.isloadalltop = false
                        this.isloadall =false
                        this.showMinIdx = 0
                        isexist = true
                    }else{
                        this.$message.error(this.$t('comm.yyNoExist'));
                    }
                },()=>{
                    loading.close();
                    if(isexist){
                        this.$nextTick(()=>{
                            let itemli = document.getElementById(this.prefixid+msgid)
                            console.log(itemli,msgid) 
                            if(itemli){
                                let toscroll = itemli.offsetTop-this.scrollWrapper.offsetTop -50
                                if(toscroll<0){
                                    toscroll = 0
                                }
                                this.scrollWrapper.scrollTop = toscroll;
                                this.lightmsgid = msgid
                                this.templighttimer&&clearTimeout(this.templighttimer)
                                setTimeout(()=>{
                                    this.lightmsgid =-1
                                },2000)
                            }
                        })
                    }
                })
            }
        },
        mousemove(){
            if(!this.showbmbt){
                this.readedMessage()
            }
        },
        //消息已读
        readedMessage() {
            //防止短时间很多消息反复调用
            if(this.unreadCount == 0||this.yd_timer){			
                return;
            }
            let type = this.chat.type
            let targetId = this.chat.targetId
            this.yd_timer = setTimeout(()=>{
                this.yd_timer = null
                let url = ""
                if (type == "GROUP") {
                    url = `/im/groupMessage/readed/${targetId}`
                } else {
                    url = `/im/privateMessage/readed?friendId=${targetId}`
                }
                this.$http({
                    url: url,
                    method: 'PUT'
                }).then(() => {
                    this.$store.commit("resetUnreadCount", {type:type,targetId:targetId})
                })
            },1500)
        },
    }
}
</script>
<style lang="scss" scoped>
.im-chat-box,.msg-scrollview{
    height: 100%;
}
//隐藏滚动条
// ::v-deep{
//     .uni-scroll-view::-webkit-scrollbar {
//         display: none;
//     }
// }
.msg-scrollview{
    position: relative;
}
.back_bottom{
    position: absolute;
    bottom: 32px;
    right: 32px;
    padding: 4px;
    height: 36px;
    min-width: 36px;
    border: 1px solid #7678ED;
    background-color: #FFF;
    border-radius: 36px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7678ED;
    .unreadnum{
        font-size: 18px;
        margin-right: 4px;
       
    }
    .icon-fangxiang{
        transform: rotate(90deg);
        font-size: 24px;
    }
}
.back_newtop{
    position: absolute;
    top: 32px;
    right: 32px;
    padding: 4px;
    height: 36px;
    min-width: 36px;
    border: 1px solid #7678ED;
    background-color: #FFF;
    border-radius: 64px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7678ED;
    .unreadnum{
        font-size: 18px;
        margin-right: 4px;
       
    }
    .icon-fangxiang{
        transform: rotate(-90deg);
        font-size: 24px;
    }
}
.loader-msg{
    display:flex;
    height: 42px;
    justify-content: center;
    align-items: center;
}
.scroll-view-content{
    position: relative;
    padding-bottom:10px;
}
@-webkit-keyframes ball-spin-fade-loader {
    50% {
            opacity: 0.3;
            -webkit-transform: scale(0.4);
            transform: scale(0.4);
    }

    100% {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
    }
}
@keyframes ball-spin-fade-loader {
    50% {
            opacity: 0.3;
            -webkit-transform: scale(0.4);
            transform: scale(0.4);
    }

    100% {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
    }
}



.ball-spin-fade-loader>div:nth-child(1) {
        top: 2.5em;
        left: 0;
        -webkit-animation: ball-spin-fade-loader 1s 0s infinite linear;
        animation: ball-spin-fade-loader 1s 0s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(2) {
        top: 1.704545em;
        left: 1.704545em;
        -webkit-animation: ball-spin-fade-loader 1s 0.12s infinite linear;
        animation: ball-spin-fade-loader 1s 0.12s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(3) {
        top: 0;
        left: 2.5em;
        -webkit-animation: ball-spin-fade-loader 1s 0.24s infinite linear;
        animation: ball-spin-fade-loader 1s 0.24s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(4) {
        top: -1.704545em;
        left: 1.704545em;
        -webkit-animation: ball-spin-fade-loader 1s 0.36s infinite linear;
        animation: ball-spin-fade-loader 1s 0.36s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(5) {
        top: -2.5em;
        left: 0;
        -webkit-animation: ball-spin-fade-loader 1s 0.48s infinite linear;
        animation: ball-spin-fade-loader 1s 0.48s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(6) {
        top: -1.704545em;
        left: -1.704545em;
        -webkit-animation: ball-spin-fade-loader 1s 0.6s infinite linear;
        animation: ball-spin-fade-loader 1s 0.6s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(7) {
        top: 0;
        left: -2.5em;
        -webkit-animation: ball-spin-fade-loader 1s 0.72s infinite linear;
        animation: ball-spin-fade-loader 1s 0.72s infinite linear;
}

.ball-spin-fade-loader>div:nth-child(8) {
        top: 1.704545em;
        left: -1.704545em;
        -webkit-animation: ball-spin-fade-loader 1s 0.84s infinite linear;
        animation: ball-spin-fade-loader 1s 0.84s infinite linear;
}
.ball-spin-fade-loader{
    position: relative;
    height: 10rpx;
}
.ball-spin-fade-loader>div {
        background-color: #7678ED;
        width: 10px;
        height: 10px;
        font-size: 6px;
        border-radius: 100%;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        position: absolute;
}
</style>
<template>
  <el-dialog
        :title="$t('comm.sendgiftbox')"
        :visible.sync="dialogVisible"
        width="400px"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <div class ="bx-content" :style="chattype=='GROUP'?'min-height:400px':''">
        <template v-if="chattype=='GROUP'">
          <div class="bx-type">
            <div class="bx-type-item" :class="{'bx-type-active':sendinfo.type ==2}" @click="sendinfo.type =2">
              <span>{{$t('comm.LuckyGiftBox')}}</span>
              <div class="bx-type-line"></div>
            </div>
            <div class="bx-type-item" :class="{'bx-type-active':sendinfo.type ==1}" @click="sendinfo.type =1">
              <span>{{$t('comm.ExclusiveGiftBox')}}</span>
              <div class="bx-type-line"></div>
            </div>
          </div>

          <div v-if="sendinfo.type==2" class ="bx-item">
            <span>{{$t('comm.Numberofgift')}}</span>
            <el-input class="align-right" v-model="sendinfo.num"  @input="handleInput_num" :placeholder="$t('comm.inputnum')"></el-input>
            <span>个</span>
          </div>
          <span v-if="sendinfo.type==2" style="color:#999999;font-size: 12px;margin:4px 0 0 28px;">{{$t('comm.peoplegroup',[groupMembers.length])}}</span>
          <div v-if="sendinfo.type==1" class ="bx-item" style="padding-right: 0;">
            <span>{{$t('comm.ExclusiveUser')}}</span>
            <el-select class="align-right-sel" filterable   v-model="seluserid" :placeholder="$t('comm.SelectUser')">
              <el-option  v-for="item in groupMembers" 
                  v-if="mine.id!= item.userId"
                  :key="item.userId"
                  :label="item.aliasName"
                  :value="item.userId">
                </el-option>
            </el-select>
          </div>
        </template>

        <div class ="bx-item">
          <div>
            <span class="zs-bh-icon">{{$t('comm.mangbox')}}</span>
            <span>{{$t('comm.diamond')}}</span>
          </div>
          <el-input class="align-right" v-model="sendinfo.amount"  @input="handleInput_amount"  :placeholder="$t('comm.inputAmount')"></el-input>
          <span>💎</span>
        </div>

        <template v-if="chattype=='GROUP'&&this.isAdmin">
          <div v-if="sendinfo.type==2" class="users-info">
              <el-button class="set-user-num"  @click="additem" >设置指定用户领取数量</el-button>
              <div class="user-item" v-for="(rowinput,index) in sendinfo.items" :key="index" :class="{'sel-item':tempfocusind==index}" >
                <el-select class ="bx-item" 
                  filterable 
                  @focus="tempfocusind=index"
                  @blur="tempfocusind=-1"
                  v-model="rowinput.uid" 
                  placeholder="请选择用户">
                  <el-option 
                      v-for="(item,index) in groupMembers" 
                      v-if="sendinfo.items.findIndex((a)=>a.uid == item.userId)<0" 
                      :key="item.userId" 
                      :label="item.aliasName" 
                      :value="item.userId">
                  </el-option>
                </el-select> 
                <div class ="bx-item">
                  <span>钻石</span>
                    <el-input 
                      class="align-right" 
                      v-model="rowinput.totalAmount"
                      @focus="tempfocusind=index"
                      @blur="tempfocusind=-1"
                      @input="
                        (val)=>{
                          if(!/^([0-9]+)?\.?([0-9]{1,2})?$/.test(val)){
                            let amount = (parseInt(val)||0);
                            if(amount == 0){
                              rowinput.totalAmount = amount.toString();
                            }else{
                              rowinput.totalAmount =''
                            }
                          }
                        }" 
                       placeholder="填写数量"></el-input>
                  <span>💎</span>
                </div>
                <span  class="iconfont icon-shanchu1" @click="sendinfo.items.splice(index,1);tempfocusind=-1;"></span>
              </div>
          </div>
        </template>
        <div class ="bx-item" style="padding: 0;">
          <el-input v-model="sendinfo.msg" :maxlength="30" :placeholder="$t('comm.blessing')"></el-input>
        </div>
        <div  class="send-bt">
          <el-button  type="danger" @click="sendbx" round>{{$t('comm.send')}}</el-button>
        </div>
    </div>
</el-dialog>
</template>

<script>
export default {
  name:"chat-bx-dialog",
  data() {
    return {
      tempfocusind:-1,
      dialogVisible: false,
      seluserid:"",
      sendinfo:{
        num:null,
        amount:null,
        msg:'',
        items:[],
        type:2//0私发，1群单一指定   2群拼手气 3指定用户金额
      },
    }
  },
  props: {
    chattype:{
      type:String, 
    },
    friend:{
      type:Object, 
    },
    groupMembers:{
      type:Array, 
    }
  },
  watch:{
    'sendinfo.type':function(){
      this.sendinfo.items =[]
    },
  },
  computed:{
    isAdmin(){//是否管理员
      let groupmine =  this.groupMembers.find(m => m.userId  == this.mine.id);
      return  groupmine?.memberType ==1 || groupmine?.memberType ==2
    },
    mine() {
      return this.$store.state.userStore.userInfo;
    },
  },
  methods: {
    additem(){
      this.sendinfo.items.push({
          uid:'',
          totalAmount:''
      })
    },
    open(){
      this.seluserid=''
      if(this.chattype!='GROUP'){
        this.sendinfo.num = 1
        this.sendinfo.type = 0
      }else{
        this.sendinfo.type = 2
      }
      this.dialogVisible =true
    },
    //发送个数 整数
    handleInput_num(value){
      const regex = /^([0-9]+)?$/;
      let num = parseInt(value)||0;
      if (!regex.test(value)){
        this.sendinfo.num = num == 0?"":num.toString()
      }
      if(num>this.groupMembers.length){
        this.sendinfo.num = this.groupMembers.length
      }
    },
    //发送数量  整数
    handleInput_amount(value) {
      const regex = /^([0-9]+)?\.?([0-9]{1,2})?$/;
      if (!regex.test(value)){
        let num = parseInt(value)||0;
        this.sendinfo.amount = num == 0?"":num.toString()
      }
    },
    handleClose() {
      this.sendinfo = {
        num:null,
        amount:null,
        msg:'',
        type:2,
        items:[]
      }
      this.dialogVisible = false
    },
    sendbx(){
      let sendinfo = JSON.parse(JSON.stringify(this.sendinfo))
      let amount = parseInt(sendinfo.amount)||0;
      let num = parseInt(sendinfo.num)||0;
      //私发和指定单用户，数量固定1
      if(sendinfo.type == 0||sendinfo.type == 1){
        sendinfo.num = num = 1
        let uid =this.chattype=='GROUP'?this.seluserid:this.friend.id
        if(!uid){
          this.$message.error(this.$t('comm.notseluser'))
          return
        }
        sendinfo.items=[{
            uid:uid,
            totalAmount:sendinfo.amount
        }]
      }else if(sendinfo.type == 2){
        let minnum = sendinfo.items.length
        let minAmount = 0
        if(minnum>0){
          for (let i = 0; i < minnum; i++) {
            const item = sendinfo.items[i];
            if(!item.totalAmount){
              this.$message.error(this.$t('comm.noinputAmount'))
              return
            }
            if(!item.uid){
              this.$message.error(this.$t('comm.noinputuser'))
              return
            }
            minAmount += parseInt(item.totalAmount)||0
          }
          sendinfo.type = 3//指定了用户,类型为3
        }
        if(minnum>num){
          this.$message.error(this.$t('comm.nonumerror'))
          return
        }
        if(minAmount>amount){
          this.$message.error(this.$t('comm.nonumerror'))
          return
        }
        if(num-minnum + minAmount > amount){
          this.$message.error("单包数量不能低于1")
          return
        }
      }
      if(!num){
        this.$message.error(this.$t('comm.noinputnum'))
        return
      }if(!amount){
        this.$message.error(this.$t('comm.noinputAmount'))
        return
      }else if(amount<num){
        this.$message.error(this.$t('comm.nonumerror'))
        return 
      }
      if(!sendinfo.msg.trim()){
        sendinfo.msg=this.$t('comm.blessing')
      }
      this.$emit('sendRedwrap',sendinfo)
      this.handleClose();
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep{
.el-dialog {
  margin: 0 !important;
  border-radius: 12px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  .el-dialog__header{
    padding: 12px;
    .el-icon-close{
      font-size: 16px;
      color: #4E5969;
      font-weight: 800;
    }
    .el-dialog__title{
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }
    .el-dialog__headerbtn{
      top: 16px;
      right: 16px;
    }
  }
  .el-dialog__body{
    padding: 0;
  }
}
}
.bx-content{
text-align: left;
display: flex;
flex-direction: column;
.bx-type{
  height: 40px;
  display: flex;
  justify-content:center;
  align-items: center;
  font-size: 14px;
  color: #626569;
  .bx-type-item{
    margin:0 16px;
    line-height: 38px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .bx-type-active{
    font-weight: 500;
    font-size: 14px;
    color: #F77B42;
    .bx-type-line{
      height: 1px;
      background: linear-gradient( 115deg, #F15858 0%, #FD9C2E 100%);
    }
  }
}
.bx-item{
  color: #333333;
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 10px;
  padding: 0px 20px;
  margin:16px 16px 0px 16px;
  border: 1px solid #DCDFE6;
  height: 48px;
  ::v-deep{
    .el-input{
      width: 0px;
      flex: 1;
      input{
        background-color:  #FFF;
        border: none;
      }
    }
  }
  .align-right ::v-deep input{
    text-align: right;
  }
  .align-right-sel{
    flex: 1;
    display: flex;
    ::v-deep input{
      text-align: right;
    }
  }
  .zs-bh-icon{
    height: 18px;
    background: #F77A43;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    padding: 2px 4px;
    margin-right: 4px;
  }
}
.el-select{
  padding: 0px 10px 0 5px;
}
.send-bt{
  margin: auto 44px 48px 44px;
  .el-button{
    margin-top: 28px;
    width: 100%;
    height: 40px;
    background: linear-gradient( 115deg, #F15858 0%, #FD9C2E 100%);
    border-radius: 12px;
  }
}
.users-info{
  text-align: left;
  .user-item{
    margin:16px 16px 0 16px;
    display: flex;
    align-items: center;
    .bx-item{
      flex: 1;
      margin: 0px;
    }
    .bx-item:nth-child(1){
      border-radius: 10px 0 0 10px;
      border-right: none;
    }
    .bx-item:nth-child(2){
      border-radius:0 10px 10px 0;
      transform: translateX(-1px);
      border-left: none;
    }
    .icon-shanchu1{
      margin-left: 9px;
      color: #F56C6C;
      display: none;
    }
    &:hover,&.sel-item{
      margin-left: 0px;
      .icon-shanchu1{
        display: block;
      }
    }
  }
  .set-user-num{
    margin:16px 16px 0 16px;
    height: 32px;
    border: 1px solid #7678ED;
    color: #7678ED;
  }
}
}
</style>